import ClientNavbar from "../../shared/clientNavbar";
import IntroSection from '../../shared/introSection';
import Instructions from "./instructionsAPPG";
import 'react-notifications/lib/notifications.css';

import Table from 'react-bootstrap/Table'
import Inicioquest from "../shared/inicioquest";
import Fimquest from "../shared/fimquest";
import { useForm } from "react-hook-form";
import Alert from "../shared/alert";
import React, { useState } from 'react';
import Notification from '../../testes/shared/notification';
import { Navigate } from "react-router-dom";
import { responder } from '../../../services/testeService';

export default function APPG() {

  const { register, handleSubmit, watch, formState: { errors } } = useForm();

  const [error401, setError] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const onSubmit = async data => {
    try {
      const response = await responder(2, data);
      setRedirect(true);
    }
    catch (error) {
      setError(true);
    }
  };

  return (
    <div>

      {error401 && <Notification titulo="Erro ao enviar esse formulario!" />}
      {redirect && <Navigate to="/testes" />}

      <ClientNavbar titulo="Formulário APPG"> </ClientNavbar>

      <IntroSection>
        <Instructions></Instructions>
      </IntroSection>

      <Inicioquest></Inicioquest>

      <form className="formsize" onSubmit={handleSubmit(onSubmit)}>
        <Table striped>

          <thead><tr><th>1</th>
            <th>{errors.q1 && <Alert titulo="Questão 1 não respondida"></Alert>}</th>
            <th></th></tr></thead>

          <tbody>
            <tr><td>A</td>
              <td>Gosto de fazer um trabalho bem feito</td>
              <td><input type="radio" name="q1" value="a" {...register("q1", { required: (true) })} /></td></tr>

            <tr><td>B</td>
              <td>Gosto de movimentar-me</td>
              <td><input type="radio" name="q1" value="b" {...register("q1", { required: (true) })} /></td></tr>
          </tbody>

          <br></br>





          <thead><tr><th>2</th><th>{errors.q2 && <Alert titulo="Questão 2 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td>Penso que sou inflexível</td><td><input type="radio" name="q2" value="a" {...register("q2", { required: (true) })} /></td></tr>
            <tr><td>B</td><td>Gosto de trabalhar em equipe</td><td><input type="radio" name="q2" value="b" {...register("q2", { required: (true) })} /></td></tr></tbody><br></br>
          <thead><tr><th>3</th><th>{errors.q3 && <Alert titulo="Questão 3 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td>As pessoas me dão ouvidos</td><td><input type="radio" name="q3" value="a" {...register("q3", { required: (true) })} /></td></tr>
            <tr><td>B</td><td>Dizem que sou muito cordial (amigável, afetuoso)</td><td><input type="radio" name="q3" value="b" {...register("q3", { required: (true) })} /></td></tr></tbody><br></br>
          <thead><tr><th>4</th><th>{errors.q4 && <Alert titulo="Questão 4 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td>Sempre admiro meus superiores</td><td><input type="radio" name="q4" value="a" {...register("q4", { required: (true) })} /></td></tr>
            <tr><td>B</td><td>Gosto de ser diferente das outras pessoas</td><td><input type="radio" name="q4" value="b" {...register("q4", { required: (true) })} /></td></tr></tbody><br></br>
          <thead><tr><th>5</th><th>{errors.q5 && <Alert titulo="Questão 5 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td>Sou atirado (afoito, audacioso, ousado)</td><td><input type="radio" name="q5" value="a" {...register("q5", { required: (true) })} /></td></tr>
            <tr><td>B</td><td> Não gosto de improvisação</td><td><input type="radio" name="q5" value="b" {...register("q5", { required: (true) })} /></td></tr></tbody><br></br>
          <thead><tr><th>6</th><th>{errors.q6 && <Alert titulo="Questão 6 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td>Quando me tratam com injustiça, discuto muito</td><td><input type="radio" name="q6" value="a" {...register("q6", { required: (true) })} /></td></tr>
            <tr><td>B</td><td>Agrada-me ter subordinados</td><td><input type="radio" name="q6" value="b" {...register("q6", { required: (true) })} /></td></tr></tbody><br></br>
          <thead><tr><th>7</th><th>{errors.q7 && <Alert titulo="Questão 7 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td>Executo meu trabalho com pressa em terminá-lo</td><td><input type="radio" name="q7" value="a" {...register("q7", { required: (true) })} /></td></tr>
            <tr><td>B</td><td>Gosto de lidar com minúcias (detalhes, pormenores)</td><td><input type="radio" name="q7" value="b" {...register("q7", { required: (true) })} /></td></tr></tbody><br></br>
          <thead><tr><th>8</th><th>{errors.q8 && <Alert titulo="Questão 8 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td>Preocupo-me quando não gostam de mim</td><td><input type="radio" name="q8" value="a" {...register("q8", { required: (true) })} /></td></tr>
            <tr><td>B</td><td>Tenho necessidade de terminar o que iniciei</td><td><input type="radio" name="q8" value="b" {...register("q8", { required: (true) })} /></td></tr></tbody><br></br>
          <thead><tr><th>9</th><th>{errors.q9 && <Alert titulo="Questão 9 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td>Gosto de ser alvo da atenção do grupo</td><td><input type="radio" name="q9" value="a" {...register("q9", { required: (true) })} /></td></tr>
            <tr><td>B</td><td> Sinto-me bem executando um trabalho difícil</td><td><input type="radio" name="q9" value="b" {...register("q9", { required: (true) })} /></td></tr></tbody><br></br>
          <thead><tr><th>10</th><th>{errors.q10 && <Alert titulo="Questão 10 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td>Nas horas de folga, faço atividades físicas</td><td><input type="radio" name="q10" value="a" {...register("q10", { required: (true) })} /></td></tr>
            <tr><td>B</td><td>Normalmente sou controlado (moderado, equilibrado, comedido)</td><td><input type="radio" name="q10" value="b" {...register("q10", { required: (true) })} /></td></tr></tbody><br></br>
          <thead><tr><th>11</th><th>{errors.q11 && <Alert titulo="Questão 11 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td>Gosto de fazer amigos</td><td><input type="radio" name="q11" value="a" {...register("q11", { required: (true) })} /></td></tr>
            <tr><td>B</td><td>Não me aborreço com facilidade</td><td><input type="radio" name="q11" value="b" {...register("q11", { required: (true) })} /></td></tr></tbody><br></br>
          <thead><tr><th>12</th><th>{errors.q12 && <Alert titulo="Questão 12 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td>Gosto de fazer um trabalho o mais rápido possível</td><td><input type="radio" name="q12" value="a" {...register("q12", { required: (true) })} /></td></tr>
            <tr><td>B</td><td>Aborreço-me quando não deixam os objetos em seus lugares</td><td><input type="radio" name="q12" value="b" {...register("q12", { required: (true) })} /></td></tr></tbody><br></br>
          <thead><tr><th>13</th><th>{errors.q13 && <Alert titulo="Questão 13 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td>Gosto de inovações</td><td><input type="radio" name="q13" value="a" {...register("q13", { required: (true) })} /></td></tr>
            <tr><td>B</td><td>Procuro acabar o que começo</td><td><input type="radio" name="q13" value="b" {...register("q13", { required: (true) })} /></td></tr></tbody><br></br>
          <thead><tr><th>14</th><th>{errors.q14 && <Alert titulo="Questão 14 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td>Decido com rapidez</td><td><input type="radio" name="q14" value="a" {...register("q14", { required: (true) })} /></td></tr>
            <tr><td>B</td><td>Interesso-me por detalhes</td><td><input type="radio" name="q14" value="b" {...register("q14", { required: (true) })} /></td></tr></tbody><br></br>
          <thead><tr><th>15</th><th>{errors.q15 && <Alert titulo="Questão 15 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td>Sou muito franco</td><td><input type="radio" name="q15" value="a" {...register("q15", { required: (true) })} /></td></tr>
            <tr><td>B</td><td> Sempre tento ser o melhor</td><td><input type="radio" name="q15" value="b" {...register("q15", { required: (true) })} /></td></tr></tbody><br></br>
          <thead><tr><th>16</th><th>{errors.q16 && <Alert titulo="Questão 16 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td> Gosto de trabalhar em posição de comando</td><td><input type="radio" name="q16" value="a" {...register("q16", { required: (true) })} /></td></tr>
            <tr><td>B</td><td>Gosto de pensar e planejar</td><td><input type="radio" name="q16" value="b" {...register("q16", { required: (true) })} /></td></tr></tbody><br></br>
          <thead><tr><th>17</th><th>{errors.q17 && <Alert titulo="Questão 17 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td>Agrada-me se meu superior gosta de mim</td><td><input type="radio" name="q17" value="a" {...register("q17", { required: (true) })} /></td></tr>
            <tr><td>B</td><td>Gosto de mandar</td><td><input type="radio" name="q17" value="b" {...register("q17", { required: (true) })} /></td></tr></tbody><br></br>
          <thead><tr><th>18</th><th>{errors.q18 && <Alert titulo="Questão 18 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td>Dedico-me ao trabalho com obstinação (perseverança, persistência)</td><td><input type="radio" name="q18" value="a" {...register("q18", { required: (true) })} /></td></tr>
            <tr><td>B</td><td>Faço amizades com facilidade</td><td><input type="radio" name="q18" value="b" {...register("q18", { required: (true) })} /></td></tr></tbody><br></br>
          <thead><tr><th>19</th><th>{errors.q19 && <Alert titulo="Questão 19 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td>Gosto de instruções objetivas</td><td><input type="radio" name="q19" value="a" {...register("q19", { required: (true) })} /></td></tr>
            <tr><td>B</td><td>Aborreço-me se em grupo, não me dão atenção</td><td><input type="radio" name="q19" value="b" {...register("q19", { required: (true) })} /></td></tr></tbody><br></br>
          <thead><tr><th>20</th><th>{errors.q20 && <Alert titulo="Questão 20 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td>Às vezes conto algumas vantagens sobre meu trabalho</td><td><input type="radio" name="q20" value="a" {...register("q20", { required: (true) })} /></td></tr>
            <tr><td>B</td><td>As pessoas me entendem</td><td><input type="radio" name="q20" value="b" {...register("q20", { required: (true) })} /></td></tr></tbody><br></br>
          <thead><tr><th>21</th><th>{errors.q21 && <Alert titulo="Questão 21 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td>Em qualquer atividade sou esforçado</td><td><input type="radio" name="q21" value="a" {...register("q21", { required: (true) })} /></td></tr>
            <tr><td>B</td><td>Prefiro prazos curtos para entregar os resultados</td><td><input type="radio" name="q21" value="b" {...register("q21", { required: (true) })} /></td></tr></tbody><br></br>
          <thead><tr><th>22</th><th>{errors.q22 && <Alert titulo="Questão 22 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td>Discuto pouco com meu superior</td><td><input type="radio" name="q22" value="a" {...register("q22", { required: (true) })} /></td></tr>
            <tr><td>B</td><td>Faço concessões  para participar da equipe</td><td><input type="radio" name="q22" value="b" {...register("q22", { required: (true) })} /></td></tr></tbody><br></br>
          <thead><tr><th>23</th><th>{errors.q23 && <Alert titulo="Questão 23 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td>Dizem que sou bom líder</td><td><input type="radio" name="q23" value="a" {...register("q23", { required: (true) })} /></td></tr>
            <tr><td>B</td><td>Tenho habilidade manual</td><td><input type="radio" name="q23" value="b" {...register("q23", { required: (true) })} /></td></tr></tbody><br></br>
          <thead><tr><th>24</th><th>{errors.q24 && <Alert titulo="Questão 24 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td>Sempre defendo meu ponto de vista</td><td><input type="radio" name="q24" value="a" {...register("q24", { required: (true) })} /></td></tr>
            <tr><td>B</td><td>Gosto de ser notado pelo grupo</td><td><input type="radio" name="q24" value="b" {...register("q24", { required: (true) })} /></td></tr></tbody><br></br>
          <thead><tr><th>25</th><th>{errors.q25 && <Alert titulo="Questão 25 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td>Tenho dificuldade em fazer amigos</td><td><input type="radio" name="q25" value="a" {...register("q25", { required: (true) })} /></td></tr>
            <tr><td>B</td><td>Tenho dificuldade em tomar decisões</td><td><input type="radio" name="q25" value="b" {...register("q25", { required: (true) })} /></td></tr></tbody><br></br>
          <thead><tr><th>26</th><th>{errors.q26 && <Alert titulo="Questão 26 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td>Gosto de aprender coisas novas</td><td><input type="radio" name="q26" value="a" {...register("q26", { required: (true) })} /></td></tr>
            <tr><td>B</td><td>Na maioria das vezes tomo decisões por outros</td><td><input type="radio" name="q26" value="b" {...register("q26", { required: (true) })} /></td></tr></tbody><br></br>
          <thead><tr><th>27</th><th>{errors.q27 && <Alert titulo="Questão 27 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td>Gosto de fazer um trabalho o mais rápido possível</td><td><input type="radio" name="q27" value="a" {...register("q27", { required: (true) })} /></td></tr>
            <tr><td>B</td><td>Gosto de planejar com antecedência</td><td><input type="radio" name="q27" value="b" {...register("q27", { required: (true) })} /></td></tr></tbody><br></br>
          <thead><tr><th>28</th><th>{errors.q28 && <Alert titulo="Questão 28 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td>Gosto de agradar meus amigos</td><td><input type="radio" name="q28" value="a" {...register("q28", { required: (true) })} /></td></tr>
            <tr><td>B</td><td>Procuro sempre me esforçar o máximo</td><td><input type="radio" name="q28" value="b" {...register("q28", { required: (true) })} /></td></tr></tbody><br></br>
          <thead><tr><th>29</th><th>{errors.q29 && <Alert titulo="Questão 29 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td>Gosto de movimentar-me</td><td><input type="radio" name="q29" value="a" {...register("q29", { required: (true) })} /></td></tr>
            <tr><td>B</td><td>Executo cada passo com muita cautela</td><td><input type="radio" name="q29" value="b" {...register("q29", { required: (true) })} /></td></tr></tbody><br></br>
          <thead><tr><th>30</th><th>{errors.q30 && <Alert titulo="Questão 30 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td>Gosto que me convidem</td><td><input type="radio" name="q30" value="a" {...register("q30", { required: (true) })} /></td></tr>
            <tr><td>B</td><td>Gosto de fazer uma coisa de cada vez</td><td><input type="radio" name="q30" value="b" {...register("q30", { required: (true) })} /></td></tr></tbody><br></br>
          <thead><tr><th>31</th><th>{errors.q31 && <Alert titulo="Questão 31 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td>Gosto de gente</td><td><input type="radio" name="q31" value="a" {...register("q31", { required: (true) })} /></td></tr>
            <tr><td>B</td><td> Agrada-me que cada coisa fique em seu lugar</td><td><input type="radio" name="q31" value="b" {...register("q31", { required: (true) })} /></td></tr></tbody><br></br>
          <thead><tr><th>32</th><th>{errors.q32 && <Alert titulo="Questão 32 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td>Sou rápido na execução de um trabalho</td><td><input type="radio" name="q32" value="a" {...register("q32", { required: (true) })} /></td></tr>
            <tr><td>B</td><td>Admiram minha paciência</td><td><input type="radio" name="q32" value="b" {...register("q32", { required: (true) })} /></td></tr></tbody><br></br>
          <thead><tr><th>33</th><th>{errors.q33 && <Alert titulo="Questão 33 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td>Normalmente gosto de trabalho árduo (difícil/trabalhoso)</td><td><input type="radio" name="q33" value="a" {...register("q33", { required: (true) })} /></td></tr>
            <tr><td>B</td><td>Consigo bons resultados com e através dos outros</td><td><input type="radio" name="q33" value="b" {...register("q33", { required: (true) })} /></td></tr></tbody><br></br>
          <thead><tr><th>34</th><th>{errors.q34 && <Alert titulo="Questão 34 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td>Gosto de pensar muito</td><td><input type="radio" name="q34" value="a" {...register("q34", { required: (true) })} /></td></tr>
            <tr><td>B</td><td>Não sou temperamental</td><td><input type="radio" name="q34" value="b" {...register("q34", { required: (true) })} /></td></tr></tbody><br></br>
          <thead><tr><th>35</th><th>{errors.q35 && <Alert titulo="Questão 35 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td>Sou minucioso no cumprimento das instruções</td><td><input type="radio" name="q35" value="a" {...register("q35", { required: (true) })} /></td></tr>
            <tr><td>B</td><td>Sempre controlo o trabalho dos outros</td><td><input type="radio" name="q35" value="b" {...register("q35", { required: (true) })} /></td></tr></tbody><br></br>
          <thead><tr><th>36</th><th>{errors.q36 && <Alert titulo="Questão 36 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td>Sempre respeito meu superior</td><td><input type="radio" name="q36" value="a" {...register("q36", { required: (true) })} /></td></tr>
            <tr><td>B</td><td>Procuro fazer as coisas melhor que os outros</td><td><input type="radio" name="q36" value="b" {...register("q36", { required: (true) })} /></td></tr></tbody><br></br>
          <thead><tr><th>37</th><th>{errors.q37 && <Alert titulo="Questão 37 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td>Agrada-me estudar/planejar o que fazer</td><td><input type="radio" name="q37" value="a" {...register("q37", { required: (true) })} /></td></tr>
            <tr><td>B</td><td>Sou muito organizado</td><td><input type="radio" name="q37" value="b" {...register("q37", { required: (true) })} /></td></tr></tbody><br></br>
          <thead><tr><th>38</th><th>{errors.q38 && <Alert titulo="Questão 38 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td>Gosto de trabalho que não exija movimentação física</td><td><input type="radio" name="q38" value="a" {...register("q38", { required: (true) })} /></td></tr>
            <tr><td>B</td><td>Penso demais para tomar decisões</td><td><input type="radio" name="q38" value="b" {...register("q38", { required: (true) })} /></td></tr></tbody><br></br>
          <thead><tr><th>39</th><th>{errors.q39 && <Alert titulo="Questão 39 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td>Agrada-me ter amigos</td><td><input type="radio" name="q39" value="a" {...register("q39", { required: (true) })} /></td></tr>
            <tr><td>B</td><td>Prefiro trabalhar em equipe</td><td><input type="radio" name="q39" value="b" {...register("q39", { required: (true) })} /></td></tr></tbody><br></br>
          <thead><tr><th>40</th><th>{errors.q40 && <Alert titulo="Questão 40 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td>Normalmente estou bem disposto fisicamente</td><td><input type="radio" name="q40" value="a" {...register("q40", { required: (true) })} /></td></tr>
            <tr><td>B</td><td>Gosto de planejar o que faço</td><td><input type="radio" name="q40" value="b" {...register("q40", { required: (true) })} /></td></tr></tbody><br></br>
          <thead><tr><th>41</th><th>{errors.q41 && <Alert titulo="Questão 41 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td>Tenho muitos amigos</td><td><input type="radio" name="q41" value="a" {...register("q41", { required: (true) })} /></td></tr>
            <tr><td>B</td><td>Penso e planejo com antecedência as minhas tarefas</td><td><input type="radio" name="q41" value="b" {...register("q41", { required: (true) })} /></td></tr></tbody><br></br>
          <thead><tr><th>42</th><th>{errors.q42 && <Alert titulo="Questão 42 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td>Quando me ferem, eu retruco</td><td><input type="radio" name="q42" value="a" {...register("q42", { required: (true) })} /></td></tr>
            <tr><td>B</td><td>Não fico satisfeito até resolver um trabalho</td><td><input type="radio" name="q42" value="b" {...register("q42", { required: (true) })} /></td></tr></tbody><br></br>
          <thead><tr><th>43</th><th>{errors.q43 && <Alert titulo="Questão 43 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td>Agrada-me fazer pequenas coisas</td><td><input type="radio" name="q43" value="a" {...register("q43", { required: (true) })} /></td></tr>
            <tr><td>B</td><td>Gosto de organizar meu trabalho</td><td><input type="radio" name="q43" value="b" {...register("q43", { required: (true) })} /></td></tr></tbody><br></br>
          <thead><tr><th>44</th><th>{errors.q44 && <Alert titulo="Questão 44 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td>Dizem que me dou bem com as pessoas</td><td><input type="radio" name="q44" value="a" {...register("q44", { required: (true) })} /></td></tr>
            <tr><td>B</td><td>Dizem que sou detalhista</td><td><input type="radio" name="q44" value="b" {...register("q44", { required: (true) })} /></td></tr></tbody><br></br>
          <thead><tr><th>45</th><th>{errors.q45 && <Alert titulo="Questão 45 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td>Executo meu trabalho com atenção minuciosa</td><td><input type="radio" name="q45" value="a" {...register("q45", { required: (true) })} /></td></tr>
            <tr><td>B</td><td>Sou muito controlado/comedido/moderado</td><td><input type="radio" name="q45" value="b" {...register("q45", { required: (true) })} /></td></tr></tbody><br></br>
          <thead><tr><th>46</th><th>{errors.q46 && <Alert titulo="Questão 46 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td>Sou esforçado</td><td><input type="radio" name="q46" value="a" {...register("q46", { required: (true) })} /></td></tr>
            <tr><td>B</td><td>Arrisco-me com facilidade</td><td><input type="radio" name="q46" value="b" {...register("q46", { required: (true) })} /></td></tr></tbody><br></br>
          <thead><tr><th>47</th><th>{errors.q47 && <Alert titulo="Questão 47 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td>Consigo que as pessoas trabalhem intensamente</td><td><input type="radio" name="q47" value="a" {...register("q47", { required: (true) })} /></td></tr>
            <tr><td>B</td><td>Sinto-me bem no trabalho</td><td><input type="radio" name="q47" value="b" {...register("q47", { required: (true) })} /></td></tr></tbody><br></br>
          <thead><tr><th>48</th><th>{errors.q48 && <Alert titulo="Questão 48 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td>As pessoas sempre me seguem</td><td><input type="radio" name="q48" value="a" {...register("q48", { required: (true) })} /></td></tr>
            <tr><td>B</td><td>Geralmente falo rápido</td><td><input type="radio" name="q48" value="b" {...register("q48", { required: (true) })} /></td></tr></tbody><br></br>
          <thead><tr><th>49</th><th>{errors.q49 && <Alert titulo="Questão 49 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td>Sou rígido no cumprimento das normas</td><td><input type="radio" name="q49" value="a" {...register("q49", { required: (true) })} /></td></tr>
            <tr><td>B</td><td>Sempre tento ser o melhor</td><td><input type="radio" name="q49" value="b" {...register("q49", { required: (true) })} /></td></tr></tbody><br></br>
          <thead><tr><th>50</th><th>{errors.q50 && <Alert titulo="Questão 50 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td>Ajo com rapidez</td><td><input type="radio" name="q50" value="a" {...register("q50", { required: (true) })} /></td></tr>
            <tr><td>B</td><td>Sou cordial e atencioso</td><td><input type="radio" name="q50" value="b" {...register("q50", { required: (true) })} /></td></tr></tbody><br></br>
          <thead><tr><th>51</th><th>{errors.q51 && <Alert titulo="Questão 51 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td>Sou obediente</td><td><input type="radio" name="q51" value="a" {...register("q51", { required: (true) })} /></td></tr>
            <tr><td>B</td><td>Tudo que inicio, eu termino</td><td><input type="radio" name="q51" value="b" {...register("q51", { required: (true) })} /></td></tr></tbody><br></br>
          <thead><tr><th>52</th><th>{errors.q52 && <Alert titulo="Questão 52 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td>Gosto de pensar muito antes de agir</td><td><input type="radio" name="q52" value="a" {...register("q52", { required: (true) })} /></td></tr>
            <tr><td>B</td><td>Gosto de trabalhar com detalhes</td><td><input type="radio" name="q52" value="b" {...register("q52", { required: (true) })} /></td></tr></tbody><br></br>
          <thead><tr><th>53</th><th>{errors.q53 && <Alert titulo="Questão 53 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td>Decido rápido</td><td><input type="radio" name="q53" value="a" {...register("q53", { required: (true) })} /></td></tr>
            <tr><td>B</td><td>Gosto de executar as coisas rapidamente</td><td><input type="radio" name="q53" value="b" {...register("q53", { required: (true) })} /></td></tr></tbody><br></br>
          <thead><tr><th>54</th><th>{errors.q54 && <Alert titulo="Questão 54 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td>Tenho dificuldades em fazer amigos</td><td><input type="radio" name="q54" value="a" {...register("q54", { required: (true) })} /></td></tr>
            <tr><td>B</td><td>Não gosto de trabalho que exijam movimentação</td><td><input type="radio" name="q54" value="b" {...register("q54", { required: (true) })} /></td></tr></tbody><br></br>
          <thead><tr><th>55</th><th>{errors.q55 && <Alert titulo="Questão 55 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td>Dizem que sou criativo</td><td><input type="radio" name="q55" value="a" {...register("q55", { required: (true) })} /></td></tr>
            <tr><td>B</td><td>Agrada-me a execução de trabalhos que outros não conseguiram fazer</td><td><input type="radio" name="q55" value="b" {...register("q55", { required: (true) })} /></td></tr></tbody><br></br>
          <thead><tr><th>56</th><th>{errors.q56 && <Alert titulo="Questão 56 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td>Sou muito esforçado</td><td><input type="radio" name="q56" value="a" {...register("q56", { required: (true) })} /></td></tr>
            <tr><td>B</td><td>Tenho muita paciência</td><td><input type="radio" name="q56" value="b" {...register("q56", { required: (true) })} /></td></tr></tbody><br></br>
          <thead><tr><th>57</th><th>{errors.q57 && <Alert titulo="Questão 57 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td>Gosto de conversar com meu superior</td><td><input type="radio" name="q57" value="a" {...register("q57", { required: (true) })} /></td></tr>
            <tr><td>B</td><td>Dou muita importância às normas</td><td><input type="radio" name="q57" value="b" {...register("q57", { required: (true) })} /></td></tr></tbody><br></br>
          <thead><tr><th>58</th><th>{errors.q58 && <Alert titulo="Questão 58 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td>Agrada-me ter as coisas limpas e em ordem</td><td><input type="radio" name="q58" value="a" {...register("q58", { required: (true) })} /></td></tr>
            <tr><td>B</td><td>Dizem que tenho muita paciência</td><td><input type="radio" name="q58" value="b" {...register("q58", { required: (true) })} /></td></tr></tbody><br></br>
          <thead><tr><th>59</th><th>{errors.q59 && <Alert titulo="Questão 59 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td>Quando posso sou criativo</td><td><input type="radio" name="q59" value="a" {...register("q59", { required: (true) })} /></td></tr>
            <tr><td>B</td><td>Gosto de trabalhar em equipe</td><td><input type="radio" name="q59" value="b" {...register("q59", { required: (true) })} /></td></tr></tbody><br></br>
          <thead><tr><th>60</th><th>{errors.q60 && <Alert titulo="Questão 60 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td>Sou atencioso com as pessoas da minha equipe</td><td><input type="radio" name="q60" value="a" {...register("q60", { required: (true) })} /></td></tr>
            <tr><td>B</td><td>Desejo que as pessoas me levem em consideração</td><td><input type="radio" name="q60" value="b" {...register("q60", { required: (true) })} /></td></tr></tbody><br></br>
          <thead><tr><th>61</th><th>{errors.q61 && <Alert titulo="Questão 61 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td>Agrada-me trabalhos que outros não conseguiram fazer</td><td><input type="radio" name="q61" value="a" {...register("q61", { required: (true) })} /></td></tr>
            <tr><td>B</td><td>Gosto de acabar o que comecei</td><td><input type="radio" name="q61" value="b" {...register("q61", { required: (true) })} /></td></tr></tbody><br></br>
          <thead><tr><th>62</th><th>{errors.q62 && <Alert titulo="Questão 62 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td>Tenho muita paciência</td><td><input type="radio" name="q62" value="a" {...register("q62", { required: (true) })} /></td></tr>
            <tr><td>B</td><td>Tenho dificuldade de expressão quando estou com raiva</td><td><input type="radio" name="q62" value="b" {...register("q62", { required: (true) })} /></td></tr></tbody><br></br>
          <thead><tr><th>63</th><th>{errors.q63 && <Alert titulo="Questão 63 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td>As pesoas me obedecem</td><td><input type="radio" name="q63" value="a" {...register("q63", { required: (true) })} /></td></tr>
            <tr><td>B</td><td>Sou minucioso (detalhista)</td><td><input type="radio" name="q63" value="b" {...register("q63", { required: (true) })} /></td></tr></tbody><br></br>
          <thead><tr><th>64</th><th>{errors.q64 && <Alert titulo="Questão 64 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td>Sempre sigo as ordens que me dão</td><td><input type="radio" name="q64" value="a" {...register("q64", { required: (true) })} /></td></tr>
            <tr><td>B</td><td>Não escondo minha irritação</td><td><input type="radio" name="q64" value="b" {...register("q64", { required: (true) })} /></td></tr></tbody><br></br>
          <thead><tr><th>65</th><th>{errors.q65 && <Alert titulo="Questão 65 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td>Sou muito obediente</td><td><input type="radio" name="q65" value="a" {...register("q65", { required: (true) })} /></td></tr>
            <tr><td>B</td><td>Sou muito franco</td><td><input type="radio" name="q65" value="b" {...register("q65", { required: (true) })} /></td></tr></tbody><br></br>
          <thead><tr><th>66</th><th>{errors.q66 && <Alert titulo="Questão 66 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td>Gosto de fazer um trabalho bem feito</td><td><input type="radio" name="q66" value="a" {...register("q66", { required: (true) })} /></td></tr>
            <tr><td>B</td><td>Gosto de movimentar-me</td><td><input type="radio" name="q66" value="b" {...register("q66", { required: (true) })} /></td></tr></tbody><br></br>
          <thead><tr><th>67</th><th>{errors.q67 && <Alert titulo="Questão 67 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td>Sinto-me realizado no trabalho</td><td><input type="radio" name="q67" value="a" {...register("q67", { required: (true) })} /></td></tr>
            <tr><td>B</td><td>Penso que sei liderar</td><td><input type="radio" name="q67" value="b" {...register("q67", { required: (true) })} /></td></tr></tbody><br></br>
          <thead><tr><th>68</th><th>{errors.q68 && <Alert titulo="Questão 68 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td>Agrada-me variar na execução do meu trabalho</td><td><input type="radio" name="q68" value="a" {...register("q68", { required: (true) })} /></td></tr>
            <tr><td>B</td><td>Sinto prazer em falar dos meus sucessos</td><td><input type="radio" name="q68" value="b" {...register("q68", { required: (true) })} /></td></tr></tbody><br></br>
          <thead><tr><th>69</th><th>{errors.q69 && <Alert titulo="Questão 69 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td>Sou rápido para executar um trabalho</td><td><input type="radio" name="q69" value="a" {...register("q69", { required: (true) })} /></td></tr>
            <tr><td>B</td><td>Meus colegas dizem que sou incansável</td><td><input type="radio" name="q69" value="b" {...register("q69", { required: (true) })} /></td></tr></tbody><br></br>
          <thead><tr><th>70</th><th>{errors.q70 && <Alert titulo="Questão 70 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td>Tenho facilidade de entrosamento</td><td><input type="radio" name="q70" value="a" {...register("q70", { required: (true) })} /></td></tr>
            <tr><td>B</td><td>Sou muito afetivo</td><td><input type="radio" name="q70" value="b" {...register("q70", { required: (true) })} /></td></tr></tbody><br></br>
          <thead><tr><th>71</th><th>{errors.q71 && <Alert titulo="Questão 71 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td>Prefiro mandar fazer</td><td><input type="radio" name="q71" value="a" {...register("q71", { required: (true) })} /></td></tr>
            <tr><td>B</td><td>Gosto de terminar o trabalho que comecei</td><td><input type="radio" name="q71" value="b" {...register("q71", { required: (true) })} /></td></tr></tbody><br></br>
          <thead><tr><th>72</th><th>{errors.q72 && <Alert titulo="Questão 72 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td>Gosto de despertar  a atenção</td><td><input type="radio" name="q72" value="a" {...register("q72", { required: (true) })} /></td></tr>
            <tr><td>B</td><td>Agrada-me quando me pedem sugestões</td><td><input type="radio" name="q72" value="b" {...register("q72", { required: (true) })} /></td></tr></tbody><br></br>
          <thead><tr><th>73</th><th>{errors.q73 && <Alert titulo="Questão 73 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td>Sempre sigo as normas</td><td><input type="radio" name="q73" value="a" {...register("q73", { required: (true) })} /></td></tr>
            <tr><td>B</td><td>Tenho que concluir o que iniciei</td><td><input type="radio" name="q73" value="b" {...register("q73", { required: (true) })} /></td></tr></tbody><br></br>
          <thead><tr><th>74</th><th>{errors.q74 && <Alert titulo="Questão 74 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td>Sempre sei onde está o que guardei</td><td><input type="radio" name="q74" value="a" {...register("q74", { required: (true) })} /></td></tr>
            <tr><td>B</td><td>Não gosto de pessoas rotineiras</td><td><input type="radio" name="q74" value="b" {...register("q74", { required: (true) })} /></td></tr></tbody><br></br>
          <thead><tr><th>75</th><th>{errors.q75 && <Alert titulo="Questão 75 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td>Gosto que me expliquem o que fazer</td><td><input type="radio" name="q75" value="a" {...register("q75", { required: (true) })} /></td></tr>
            <tr><td>B</td><td>Discuto muito com meu superior</td><td><input type="radio" name="q75" value="b" {...register("q75", { required: (true) })} /></td></tr></tbody><br></br>
          <thead><tr><th>76</th><th>{errors.q76 && <Alert titulo="Questão 76 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td>Gosto de liderar</td><td><input type="radio" name="q76" value="a" {...register("q76", { required: (true) })} /></td></tr>
            <tr><td>B</td><td>Gosto de organizar meu trabalho</td><td><input type="radio" name="q76" value="b" {...register("q76", { required: (true) })} /></td></tr></tbody><br></br>
          <thead><tr><th>77</th><th>{errors.q77 && <Alert titulo="Questão 77 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td>Não sou de ficar calado quando me ferem</td><td><input type="radio" name="q77" value="a" {...register("q77", { required: (true) })} /></td></tr>
            <tr><td>B</td><td>Sinto-me bem fazendo parte da equipe</td><td><input type="radio" name="q77" value="b" {...register("q77", { required: (true) })} /></td></tr></tbody><br></br>
          <thead><tr><th>78</th><th>{errors.q78 && <Alert titulo="Questão 78 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td>Sempre acompanho o trabalho dos outros</td><td><input type="radio" name="q78" value="a" {...register("q78", { required: (true) })} /></td></tr>
            <tr><td>B</td><td>Tenho condições de decidir com rapidez</td><td><input type="radio" name="q78" value="b" {...register("q78", { required: (true) })} /></td></tr></tbody><br></br>
          <thead><tr><th>79</th><th>{errors.q79 && <Alert titulo="Questão 79 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td>Gosto quando são amistosos e íntimos comigos</td><td><input type="radio" name="q79" value="a" {...register("q79", { required: (true) })} /></td></tr>
            <tr><td>B</td><td>Agrada-me ser notado no grupo</td><td><input type="radio" name="q79" value="b" {...register("q79", { required: (true) })} /></td></tr></tbody><br></br>
          <thead><tr><th>80</th><th>{errors.q80 && <Alert titulo="Questão 80 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td>Sempre verifico o que os outros fazem</td><td><input type="radio" name="q80" value="a" {...register("q80", { required: (true) })} /></td></tr>
            <tr><td>B</td><td>Gosto de executar bem um trabalho difícil</td><td><input type="radio" name="q80" value="b" {...register("q80", { required: (true) })} /></td></tr></tbody><br></br>
          <thead><tr><th>81</th><th>{errors.q81 && <Alert titulo="Questão 81 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td>Movimento-me com facilidade</td><td><input type="radio" name="q81" value="a" {...register("q81", { required: (true) })} /></td></tr>
            <tr><td>B</td><td>Sempre verifico meus arquivos</td><td><input type="radio" name="q81" value="b" {...register("q81", { required: (true) })} /></td></tr></tbody><br></br>
          <thead><tr><th>82</th><th>{errors.q82 && <Alert titulo="Questão 82 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td>Arrisco-me com facilidade</td><td><input type="radio" name="q82" value="a" {...register("q82", { required: (true) })} /></td></tr>
            <tr><td>B</td><td>Gosto que cada coisa fique em seu lugar</td><td><input type="radio" name="q82" value="b" {...register("q82", { required: (true) })} /></td></tr></tbody><br></br>
          <thead><tr><th>83</th><th>{errors.q83 && <Alert titulo="Questão 83 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td>Nas situações difíceis, penso melhor</td><td><input type="radio" name="q83" value="a" {...register("q83", { required: (true) })} /></td></tr>
            <tr><td>B</td><td>Prefiro trabalhos que exijam pouca movimentação</td><td><input type="radio" name="q83" value="b" {...register("q83", { required: (true) })} /></td></tr></tbody><br></br>
          <thead><tr><th>84</th><th>{errors.q84 && <Alert titulo="Questão 84 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td>Tenho dificuldade em terminar o que começo</td><td><input type="radio" name="q84" value="a" {...register("q84", { required: (true) })} /></td></tr>
            <tr><td>B</td><td>Em grupo, sinto-me retraído</td><td><input type="radio" name="q84" value="b" {...register("q84", { required: (true) })} /></td></tr></tbody><br></br>
          <thead><tr><th>85</th><th>{errors.q85 && <Alert titulo="Questão 85 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td>Defendo sempre meu bom nome</td><td><input type="radio" name="q85" value="a" {...register("q85", { required: (true) })} /></td></tr>
            <tr><td>B</td><td>Fico satisfeito quando faço algo diferente</td><td><input type="radio" name="q85" value="b" {...register("q85", { required: (true) })} /></td></tr></tbody><br></br>
          <thead><tr><th>86</th><th>{errors.q86 && <Alert titulo="Questão 86 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td>Sou minucioso no cumprimento das normas</td><td><input type="radio" name="q86" value="a" {...register("q86", { required: (true) })} /></td></tr>
            <tr><td>B</td><td>Conto meus problemas íntimos aos amigos</td><td><input type="radio" name="q86" value="b" {...register("q86", { required: (true) })} /></td></tr></tbody><br></br>
          <thead><tr><th>87</th><th>{errors.q87 && <Alert titulo="Questão 87 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td>Gosto de instruções detalhadas sobre meu trabalho</td><td><input type="radio" name="q87" value="a" {...register("q87", { required: (true) })} /></td></tr>
            <tr><td>B</td><td>Não gosto de instruções detalhadas sobre meu trabalho</td><td><input type="radio" name="q87" value="b" {...register("q87", { required: (true) })} /></td></tr></tbody><br></br>
          <thead><tr><th>88</th><th>{errors.q88 && <Alert titulo="Questão 88 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td>Quando os outros estão errados, sempre lhes digo</td><td><input type="radio" name="q88" value="a" {...register("q88", { required: (true) })} /></td></tr>
            <tr><td>B</td><td>Aborreço-me quando não gostam de mim</td><td><input type="radio" name="q88" value="b" {...register("q88", { required: (true) })} /></td></tr></tbody><br></br>
          <thead><tr><th>89</th><th>{errors.q89 && <Alert titulo="Questão 89 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td>Consigo bons resultados com e através dos outros</td><td><input type="radio" name="q89" value="a" {...register("q89", { required: (true) })} /></td></tr>
            <tr><td>B</td><td>Penso que tenho bom controle emocional</td><td><input type="radio" name="q89" value="b" {...register("q89", { required: (true) })} /></td></tr></tbody><br></br>
          <thead><tr><th>90</th><th>{errors.q90 && <Alert titulo="Questão 90 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td>Gosto de verificar tudo com muito cuidado</td><td><input type="radio" name="q90" value="a" {...register("q90", { required: (true) })} /></td></tr>
            <tr><td>B</td><td>Não gosto de planejar muito</td><td><input type="radio" name="q90" value="b" {...register("q90", { required: (true) })} /></td></tr></tbody><br></br>
          <thead><tr><th>91</th><th>{errors.q91 && <Alert titulo="Questão 91 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td>Gosto de estar com a equipe</td><td><input type="radio" name="q91" value="a" {...register("q91", { required: (true) })} /></td></tr>
            <tr><td>B</td><td>Assumo a responsabilidade por outros</td><td><input type="radio" name="q91" value="b" {...register("q91", { required: (true) })} /></td></tr></tbody><br></br>
          <thead><tr><th>92</th><th>{errors.q92 && <Alert titulo="Questão 92 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td>Sou muito atencioso com minha equipe</td><td><input type="radio" name="q92" value="a" {...register("q92", { required: (true) })} /></td></tr>
            <tr><td>B</td><td>Agrada-me ser bem sucedido</td><td><input type="radio" name="q92" value="b" {...register("q92", { required: (true) })} /></td></tr></tbody><br></br>
          <thead><tr><th>93</th><th>{errors.q93 && <Alert titulo="Questão 93 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td>Gosto de variedades no meu trabalho</td><td><input type="radio" name="q93" value="a" {...register("q93", { required: (true) })} /></td></tr>
            <tr><td>B</td><td>Gosto de atender aos meus amigos</td><td><input type="radio" name="q93" value="b" {...register("q93", { required: (true) })} /></td></tr></tbody><br></br>
          <thead><tr><th>94</th><th>{errors.q94 && <Alert titulo="Questão 94 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td>Aborreço-me com facilidade</td><td><input type="radio" name="q94" value="a" {...register("q94", { required: (true) })} /></td></tr>
            <tr><td>B</td><td>Sinto-me inseguro quando tomo certas decisões</td><td><input type="radio" name="q94" value="b" {...register("q94", { required: (true) })} /></td></tr></tbody><br></br>
          <thead><tr><th>95</th><th>{errors.q95 && <Alert titulo="Questão 95 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td>Sempre façam o que me mandam</td><td><input type="radio" name="q95" value="a" {...register("q95", { required: (true) })} /></td></tr>
            <tr><td>B</td><td>Sempre atendo aos meus amigos</td><td><input type="radio" name="q95" value="b" {...register("q95", { required: (true) })} /></td></tr></tbody><br></br>
          <thead><tr><th>96</th><th>{errors.q96 && <Alert titulo="Questão 96 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td>Sinto muita vontade de acabar o que começo</td><td><input type="radio" name="q96" value="a" {...register("q96", { required: (true) })} /></td></tr>
            <tr><td>B</td><td>Não gosto de ficar à toa no trabalho</td><td><input type="radio" name="q96" value="b" {...register("q96", { required: (true) })} /></td></tr></tbody><br></br>
          <thead><tr><th>97</th><th>{errors.q97 && <Alert titulo="Questão 97 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td>Acato as sugestões das pessoas que gosto</td><td><input type="radio" name="q97" value="a" {...register("q97", { required: (true) })} /></td></tr>
            <tr><td>B</td><td>Desagrada-me o trabalho rotineiro</td><td><input type="radio" name="q97" value="b" {...register("q97", { required: (true) })} /></td></tr></tbody><br></br>
          <thead><tr><th>98</th><th>{errors.q98 && <Alert titulo="Questão 98 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td>Agrada-me ter amigos mais chegados</td><td><input type="radio" name="q98" value="a" {...register("q98", { required: (true) })} /></td></tr>
            <tr><td>B</td><td>Sinto-me bem dando sugestões aos outros</td><td><input type="radio" name="q98" value="b" {...register("q98", { required: (true) })} /></td></tr></tbody><br></br>
          <thead><tr><th>99</th><th>{errors.q99 && <Alert titulo="Questão 99 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td>Sempre faço as coisas com muita dedicação</td><td><input type="radio" name="q99" value="a" {...register("q99", { required: (true) })} /></td></tr>
            <tr><td>B</td><td>Agrada-me os trabalhos que exijam detalhes</td><td><input type="radio" name="q99" value="b" {...register("q99", { required: (true) })} /></td></tr></tbody><br></br>
          <thead><tr><th>100</th><th>{errors.q100 && <Alert titulo="Questão 100 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td>Geralmente trabalho com intensidade</td><td><input type="radio" name="q100" value="a" {...register("q100", { required: (true) })} /></td></tr>
            <tr><td>B</td><td>Gosto de pensar e planejar antes de agir</td><td><input type="radio" name="q100" value="b" {...register("q100", { required: (true) })} /></td></tr></tbody><br></br>

        </Table>
        <button className="btn-login" type="submit">Enviar</button>

      </form>
      <Fimquest></Fimquest>
    </div>

  );
}