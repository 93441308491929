import "../../assets/css/card.css";

import { MdInfo } from "react-icons/md";
import React, { useEffect, useState } from "react";
import { MdCancel } from "react-icons/md";
import ModalVerCurso from "./ModalVerCurso";

export default (props) => {
  const [auth, setAuth] = useState(true);
  const [ModalShow, setModalShow] = useState(false);


  let handleClick = () => {
    setAuth(!auth);
  };

  return (
    <>
      <ModalVerCurso
        show={ModalShow}
        nome={props.nome}
        descricao={props.modal}
        onHide={() => setModalShow(false)}
      />

      <div onDoubleClick={handleClick}>
        <li id="sf-innovation" className="col-md-3 col-sm-6 col-xs-12">
          <div className="col-lg-3 col-md-3 col-sm-3 col-xs-6 carddContainer">
            <div className="cardd">
              {!auth && (
                <div className="back">
                  <div className="content">
                    <h3 className="carddTitle">{props.nome}</h3>
                    <MdCancel className="infoChartCancel"></MdCancel>
                  </div>
                </div>
              )}
              {auth && (
                <div className="front">
                  <h3 className="carddTitle">{props.nome}</h3>
                  <h6 className="desccardd">{props.descricao}</h6>
                </div>
              )}
            </div>
          </div>
          {!props.bool &&
            <a href={props.link} target="_blank" className="aLink" rel="noreferrer">
              <MdInfo className="infoChart"></MdInfo>
            </a>
          }
          {props.bool &&
            <a className="aLink" onClick={() => setModalShow(true)}
            >
              <MdInfo className="infoChart"></MdInfo>
            </a>
          }
        </li>
      </div>
    </>
  );
};
