import Modal from "react-bootstrap/Modal";
import React from "react";

export default (props) => {

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.nome}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <textarea readOnly className="textModalArea">{props.descricao}</textarea>
      </Modal.Body>
    </Modal>
  );
};
