import ClientNavbar from "../../shared/clientNavbar";
import IntroSection from '../../shared/introSection';
import Instructions from "./instructionsSDS";
import 'react-notifications/lib/notifications.css';

import Alert from "../shared/alert";
import Table from 'react-bootstrap/Table'
import Inicioquest from "../shared/inicioquest";
import Fimquest from "../shared/fimquest";
import { useForm } from "react-hook-form";

import React, { useState } from 'react';
import Notification from '../../testes/shared/notification';
import { Navigate } from "react-router-dom";
import { responder } from '../../../services/testeService';

export default function SDS() {

  const { register, handleSubmit, watch, formState: { errors } } = useForm();

  const [error401, setError] = useState(false);
  const [redirect, setRedirect] = useState(false);

  const onSubmit = async data => {
    try {
      const response = await responder(7, data);
      setRedirect(true);
    }
    catch (error) {
      setError(true);
    }
  };


  return (

    <div>

      {error401 && <Notification titulo="Erro ao enviar esse formulario!" />}
      {redirect && <Navigate to="/testes" />}

      <ClientNavbar titulo="SDS"> </ClientNavbar>

      <IntroSection>
        <Instructions></Instructions>
      </IntroSection>

      <Inicioquest></Inicioquest>

      <form className="formsize" onSubmit={handleSubmit(onSubmit)}>
        <p></p>
        <Table striped className="exTableSDS">
          <thead>
            <tr><th>Responda SIM para as ATIVIDADES que você GOSTA ou GOSTARIA de fazer.</th><th>Sim</th></tr>
          </thead>
          <tbody>
            <tr><td> Consertar aparelhos elétricos. </td><td><input type="checkbox" name="R" value="a" {...register("R1.0")} /></td></tr>
            <tr><td> Fazer manutenção em carros, motos, etc. </td><td><input type="checkbox" name="R" value="b" {...register("R1.0")} /></td></tr>
            <tr><td> Consertar máquinas em geral. </td><td><input type="checkbox" name="R" value="c" {...register("R1.0")} /></td></tr>
            <tr><td> Usar ferramentas mecânicas ou fazer trabalhos com metais. </td><td><input type="checkbox" name="R" value="d" {...register("R1.0")} /></td></tr>
            <tr><td> Trabalhar com um Engenheiro Mecânico ou Técnico competente. </td><td><input type="checkbox" name="R" value="e" {...register("R1.0")} /></td></tr>
            <tr><td> Instalar ou consertar aparelhos telefônicos. </td><td><input type="checkbox" name="R" value="f" {...register("R1.0")} /></td></tr>
            <tr><td> Construir objetos de madeira </td><td><input type="checkbox" name="R" value="g" {...register("R1.0")} /></td></tr>
            <tr><td> Fazer um curso relacionado à tecnologia. </td><td><input type="checkbox" name="R" value="h" {...register("R1.0")} /></td></tr>
            <tr><td> Trabalhar ao ar livre. </td><td><input type="checkbox" name="R" value="i" {...register("R1.0")} /></td></tr>
            <tr><td> Trabalhar com equipamentos eletrônicos. </td><td><input type="checkbox" name="R" value="j" {...register("R1.0")} /></td></tr>
            <tr><td> Visitar uma feira de informática. </td><td><input type="checkbox" name="R" value="k" {...register("R1.0")} /></td></tr>
            <tr><td> Escrever um relatório científico. </td><td><input type="checkbox" name="I" value="a" {...register("I1.0")} /></td></tr>
            <tr><td> Aprender física. </td><td><input type="checkbox" name="I" value="b" {...register("I1.0")} /></td></tr>
            <tr><td> Estudar química. </td><td><input type="checkbox" name="I" value="c" {...register("I1.0")} /></td></tr>
            <tr><td> Assistir um curso de biologia. </td><td><input type="checkbox" name="I" value="d" {...register("I1.0")} /></td></tr>
            <tr><td> Ler revistas e livros científicos. </td><td><input type="checkbox" name="I" value="e" {...register("I1.0")} /></td></tr>
            <tr><td> Trabalhar em um projeto de pesquisa. </td><td><input type="checkbox" name="I" value="f" {...register("I1.0")} /></td></tr>
            <tr><td> Estudar uma teoria científica. </td><td><input type="checkbox" name="I" value="g" {...register("I1.0")} /></td></tr>
            <tr><td> Trabalhar com análise de informações. </td><td><input type="checkbox" name="I" value="h" {...register("I1.0")} /></td></tr>
            <tr><td> Estudar astronomia. </td><td><input type="checkbox" name="I" value="i" {...register("I1.0")} /></td></tr>
            <tr><td> Visitar um museu de ciências. </td><td><input type="checkbox" name="I" value="j" {...register("I1.0")} /></td></tr>
            <tr><td> Estudar o cérebro. </td><td><input type="checkbox" name="I" value="k" {...register("I1.0")} /></td></tr>
            <tr><td> Trabalhar com projetos, desenhos ou pinturas. </td><td><input type="checkbox" name="A" value="a" {...register("A1.0")} /></td></tr>
            <tr><td> Tirar fotos. </td><td><input type="checkbox" name="A" value="b" {...register("A1.0")} /></td></tr>
            <tr><td> Escrever para revistas ou jornais. </td><td><input type="checkbox" name="A" value="c" {...register("A1.0")} /></td></tr>
            <tr><td> Pintar quadros. </td><td><input type="checkbox" name="A" value="d" {...register("A1.0")} /></td></tr>
            <tr><td> Ler ou escrever poesias. </td><td><input type="checkbox" name="A" value="e" {...register("A1.0")} /></td></tr>
            <tr><td> Fazer um curso de artes. </td><td><input type="checkbox" name="A" value="f" {...register("A1.0")} /></td></tr>
            <tr><td> Tocar um instrumento musical. </td><td><input type="checkbox" name="A" value="g" {...register("A1.0")} /></td></tr>
            <tr><td> Estudar com artistas, músicos ou escritores talentosos. </td><td><input type="checkbox" name="A" value="h" {...register("A1.0")} /></td></tr>
            <tr><td> Tocar em uma banda, grupo ou orquestra. </td><td><input type="checkbox" name="A" value="i" {...register("A1.0")} /></td></tr>
            <tr><td> Escrever novelas ou criar jogos. </td><td><input type="checkbox" name="A" value="j" {...register("A1.0")} /></td></tr>
            <tr><td> Ler sobre arte, literatura ou música.  </td><td><input type="checkbox" name="A" value="k" {...register("A1.0")} /></td></tr>
            <tr><td> Ensinar em uma escola. </td><td><input type="checkbox" name="S" value="a" {...register("S1.0")} /></td></tr>
            <tr><td> Ajudar crianças com dificuldades. </td><td><input type="checkbox" name="S" value="b" {...register("S1.0")} /></td></tr>
            <tr><td> Conhecer importantes educadores ou terapeutas. </td><td><input type="checkbox" name="S" value="c" {...register("S1.0")} /></td></tr>
            <tr><td> Escrever correspondências para amigos. </td><td><input type="checkbox" name="S" value="d" {...register("S1.0")} /></td></tr>
            <tr><td> Ler livros ou artigos de psicologia. </td><td><input type="checkbox" name="S" value="e" {...register("S1.0")} /></td></tr>
            <tr><td> Fazer um curso sobre relações humanas. </td><td><input type="checkbox" name="S" value="f" {...register("S1.0")} /></td></tr>
            <tr><td> Ajudar a resolver discussões entre pessoas. </td><td><input type="checkbox" name="S" value="g" {...register("S1.0")} /></td></tr>
            <tr><td> Trabalhar com suicidas ou em centro de valorização da vida. </td><td><input type="checkbox" name="S" value="h" {...register("S1.0")} /></td></tr>
            <tr><td> Ter aulas sobre autoajuda. </td><td><input type="checkbox" name="S" value="i" {...register("S1.0")} /></td></tr>
            <tr><td> Ajudar as pessoas a resolverem seus problemas. </td><td><input type="checkbox" name="S" value="j" {...register("S1.0")} /></td></tr>
            <tr><td> Ajudar as pessoas quando estiverem doentes. </td><td><input type="checkbox" name="S" value="k" {...register("S1.0")} /></td></tr>
            <tr><td> Ser o líder de um projeto. </td><td><input type="checkbox" name="E" value="a" {...register("E1.0")} /></td></tr>
            <tr><td> Ser nomeado diretor ou presidente de um grupo. </td><td><input type="checkbox" name="E" value="b" {...register("E1.0")} /></td></tr>
            <tr><td> Aprender como ter sucesso nos negócios. </td><td><input type="checkbox" name="E" value="c" {...register("E1.0")} /></td></tr>
            <tr><td> Fazer um curso sobre liderança. </td><td><input type="checkbox" name="E" value="d" {...register("E1.0")} /></td></tr>
            <tr><td> Supervisionar o trabalho de outras pessoas. </td><td><input type="checkbox" name="E" value="e" {...register("E1.0")} /></td></tr>
            <tr><td> Liderar um grupo para que ele atinja seus objetivos. </td><td><input type="checkbox" name="E" value="f" {...register("E1.0")} /></td></tr>
            <tr><td> Conhecer líderes e executivos importantes. </td><td><input type="checkbox" name="E" value="g" {...register("E1.0")} /></td></tr>
            <tr><td> Participar de campanhas políticas. </td><td><input type="checkbox" name="E" value="h" {...register("E1.0")} /></td></tr>
            <tr><td> Dirigir o trabalho de outras pessoas. </td><td><input type="checkbox" name="E" value="i" {...register("E1.0")} /></td></tr>
            <tr><td> Trabalhar em seu próprio negócio ou serviço. </td><td><input type="checkbox" name="E" value="j" {...register("E1.0")} /></td></tr>
            <tr><td> Vender anúncios em revistas ou jornais. </td><td><input type="checkbox" name="E" value="k" {...register("E1.0")} /></td></tr>
            <tr><td> Trabalhar com números em negócios ou livros de contabilidade. </td><td><input type="checkbox" name="C" value="a" {...register("C1.0")} /></td></tr>
            <tr><td> Controlar e manter registros de despesas. </td><td><input type="checkbox" name="C" value="b" {...register("C1.0")} /></td></tr>
            <tr><td> Fazer um curso de matemática financeira. </td><td><input type="checkbox" name="C" value="c" {...register("C1.0")} /></td></tr>
            <tr><td> Inspecionar documentos, papéis ou produtos. </td><td><input type="checkbox" name="C" value="d" {...register("C1.0")} /></td></tr>
            <tr><td> Controlar e conferir extratos bancários e talões de cheque. </td><td><input type="checkbox" name="C" value="e" {...register("C1.0")} /></td></tr>
            <tr><td> Operar equipamentos de escritório (fax, computadores, xerox). </td><td><input type="checkbox" name="C" value="f" {...register("C1.0")} /></td></tr>
            <tr><td> Fazer um curso de contabilidade. </td><td><input type="checkbox" name="C" value="g" {...register("C1.0")} /></td></tr>
            <tr><td> Fazer um inventário de suprimentos e produtos. </td><td><input type="checkbox" name="C" value="h" {...register("C1.0")} /></td></tr>
            <tr><td> Implantar um sistema de registros contábeis. </td><td><input type="checkbox" name="C" value="i" {...register("C1.0")} /></td></tr>
            <tr><td> Organizar e controlar informações financeiras e contábeis. </td><td><input type="checkbox" name="C" value="j" {...register("C1.0")} /></td></tr>
            <tr><td> Fazer auditoria em registros financeiros. </td><td><input type="checkbox" name="C" value="k" {...register("C1.0")} /></td></tr>
          </tbody>
        </Table>
        <p></p>
        <Table striped className="exTableSDS2">
          <thead>
            <tr><th>Responda SIM para aquelas COMPETÊNCIAS que você SABE ou GOSTARIA de APRENDER.</th><th>Sim</th></tr>
          </thead>
          <tbody>
            <tr><td> Usar equipamentos tais como furadeira, máquina de costura, etc. </td><td><input type="checkbox" name="R" value="a" {...register("R2.0")} /></td></tr>
            <tr><td> Trocar pneus. </td><td><input type="checkbox" name="R" value="b" {...register("R2.0")} /></td></tr>
            <tr><td> Fazer reparos elétricos. </td><td><input type="checkbox" name="R" value="c" {...register("R2.0")} /></td></tr>
            <tr><td> Consertar móveis. </td><td><input type="checkbox" name="R" value="d" {...register("R2.0")} /></td></tr>
            <tr><td> Usar ferramentas diversas. </td><td><input type="checkbox" name="R" value="e" {...register("R2.0")} /></td></tr>
            <tr><td> Praticar caça e pesca. </td><td><input type="checkbox" name="R" value="f" {...register("R2.0")} /></td></tr>
            <tr><td> Fazer desenhos técnicos ou mecânicos. </td><td><input type="checkbox" name="R" value="g" {...register("R2.0")} /></td></tr>
            <tr><td> Construir objetos de madeira. </td><td><input type="checkbox" name="R" value="h" {...register("R2.0")} /></td></tr>
            <tr><td> Consertar vazamentos hidráulicos </td><td><input type="checkbox" name="R" value="i" {...register("R2.0")} /></td></tr>
            <tr><td> Ler plantas técnicas. </td><td><input type="checkbox" name="R" value="j" {...register("R2.0")} /></td></tr>
            <tr><td> Usar equipamentos de solda. </td><td><input type="checkbox" name="R" value="k" {...register("R2.0")} /></td></tr>
            <tr><td> Descrever a função dos glóbulos brancos. </td><td><input type="checkbox" name="I" value="a" {...register("I2.0")} /></td></tr>
            <tr><td> Escrever sobre elementos radioativos. </td><td><input type="checkbox" name="I" value="b" {...register("I2.0")} /></td></tr>
            <tr><td> Interpretar fórmulas químicas. </td><td><input type="checkbox" name="I" value="c" {...register("I2.0")} /></td></tr>
            <tr><td> Utilizar computador para analisar dados. </td><td><input type="checkbox" name="I" value="d" {...register("I2.0")} /></td></tr>
            <tr><td> Realizar um experimento científico. </td><td><input type="checkbox" name="I" value="e" {...register("I2.0")} /></td></tr>
            <tr><td> Usar um microscópio. </td><td><input type="checkbox" name="I" value="f" {...register("I2.0")} /></td></tr>
            <tr><td> Interpretar a tabela periódica de elementos químicos. </td><td><input type="checkbox" name="I" value="g" {...register("I2.0")} /></td></tr>
            <tr><td> Escrever um trabalho ou relatório científico. </td><td><input type="checkbox" name="I" value="h" {...register("I2.0")} /></td></tr>
            <tr><td> Explicar porque os satélites não caem na terra. </td><td><input type="checkbox" name="I" value="i" {...register("I2.0")} /></td></tr>
            <tr><td> Explicar porque algumas bolhas flutuam e outras afundam. </td><td><input type="checkbox" name="I" value="j" {...register("I2.0")} /></td></tr>
            <tr><td> Explicar o funcionamento de um computador. </td><td><input type="checkbox" name="I" value="k" {...register("I2.0")} /></td></tr>
            <tr><td> Tocar um instrumento musical. </td><td><input type="checkbox" name="A" value="a" {...register("A2.0")} /></td></tr>
            <tr><td> Cantar em um coral. </td><td><input type="checkbox" name="A" value="b" {...register("A2.0")} /></td></tr>
            <tr><td> Pintar quadros ou fazer esculturas. </td><td><input type="checkbox" name="A" value="c" {...register("A2.0")} /></td></tr>
            <tr><td> Compor ou fazer o arranjo de uma música. </td><td><input type="checkbox" name="A" value="d" {...register("A2.0")} /></td></tr>
            <tr><td> Fazer desenhos de roupas, móveis ou cartazes. </td><td><input type="checkbox" name="A" value="e" {...register("A2.0")} /></td></tr>
            <tr><td> Criar uma representação artística de um conceito ou ideia. </td><td><input type="checkbox" name="A" value="f" {...register("A2.0")} /></td></tr>
            <tr><td> Escrever poesias ou histórias. </td><td><input type="checkbox" name="A" value="g" {...register("A2.0")} /></td></tr>
            <tr><td> Executar um solo musical. </td><td><input type="checkbox" name="A" value="h" {...register("A2.0")} /></td></tr>
            <tr><td> Realizar uma apresentação de entretenimento engraçada, interessante. </td><td><input type="checkbox" name="A" value="i" {...register("A2.0")} /></td></tr>
            <tr><td> Publicar poemas, artigos ou histórias em jornais e revistas. </td><td><input type="checkbox" name="A" value="j" {...register("A2.0")} /></td></tr>
            <tr><td> Tocar em uma banda, conjunto ou orquestra. </td><td><input type="checkbox" name="A" value="k" {...register("A2.0")} /></td></tr>
            <tr><td> Ajudar pessoas que estão preocupadas, descontroladas ou perturbadas. </td><td><input type="checkbox" name="S" value="a" {...register("S2.0")} /></td></tr>
            <tr><td> Ensinar às crianças com facilidade de aprendizagem. </td><td><input type="checkbox" name="S" value="b" {...register("S2.0")} /></td></tr>
            <tr><td> Trabalhar cooperativamente com outras pessoas. </td><td><input type="checkbox" name="S" value="c" {...register("S2.0")} /></td></tr>
            <tr><td> Reconhecer as forças e as fraquezas das pessoas. </td><td><input type="checkbox" name="S" value="d" {...register("S2.0")} /></td></tr>
            <tr><td> Ouvir os outros. </td><td><input type="checkbox" name="S" value="e" {...register("S2.0")} /></td></tr>
            <tr><td> Acalmar pessoas nervosas ou descontroladas. </td><td><input type="checkbox" name="S" value="f" {...register("S2.0")} /></td></tr>
            <tr><td> Trabalhar em equipe. </td><td><input type="checkbox" name="S" value="g" {...register("S2.0")} /></td></tr>
            <tr><td> Fazer as pessoas se sentirem à vontade. </td><td><input type="checkbox" name="S" value="h" {...register("S2.0")} /></td></tr>
            <tr><td> Ensinar coisas aos outros. </td><td><input type="checkbox" name="S" value="i" {...register("S2.0")} /></td></tr>
            <tr><td> Entender as relações sociais. </td><td><input type="checkbox" name="S" value="j" {...register("S2.0")} /></td></tr>
            <tr><td> Sou procurado pelas pessoas que desejam falar de seus problemas. </td><td><input type="checkbox" name="S" value="k" {...register("S2.0")} /></td></tr>
            <tr><td> Planejar uma estratégia para atingir uma meta. </td><td><input type="checkbox" name="E" value="a" {...register("E2.0")} /></td></tr>
            <tr><td> Ser um bom vendedor. </td><td><input type="checkbox" name="E" value="b" {...register("E2.0")} /></td></tr>
            <tr><td> Organizar o trabalho de outras pessoas. </td><td><input type="checkbox" name="E" value="c" {...register("E2.0")} /></td></tr>
            <tr><td> Ser um representante de classe. </td><td><input type="checkbox" name="E" value="d" {...register("E2.0")} /></td></tr>
            <tr><td> Gerenciar uma campanha de vendas. </td><td><input type="checkbox" name="E" value="e" {...register("E2.0")} /></td></tr>
            <tr><td> Ser um líder bem sucedido. </td><td><input type="checkbox" name="E" value="f" {...register("E2.0")} /></td></tr>
            <tr><td> Ter o meu próprio negócio ou serviço. </td><td><input type="checkbox" name="E" value="g" {...register("E2.0")} /></td></tr>
            <tr><td> Ser um bom argumentador. </td><td><input type="checkbox" name="E" value="h" {...register("E2.0")} /></td></tr>
            <tr><td> Supervisionar o trabalho de outras pessoas. </td><td><input type="checkbox" name="E" value="i" {...register("E2.0")} /></td></tr>
            <tr><td> Ser um relações públicas bem sucedido. </td><td><input type="checkbox" name="E" value="j" {...register("E2.0")} /></td></tr>
            <tr><td> Ser uma pessoa ambiciosa. </td><td><input type="checkbox" name="E" value="k" {...register("E2.0")} /></td></tr>
            <tr><td> Manter registros precisos de pagamentos e vendas. </td><td><input type="checkbox" name="C" value="a" {...register("C2.0")} /></td></tr>
            <tr><td> Usar o computador para analisar dados. </td><td><input type="checkbox" name="C" value="b" {...register("C2.0")} /></td></tr>
            <tr><td> Descobrir erros no trabalho de outras pessoas. </td><td><input type="checkbox" name="C" value="c" {...register("C2.0")} /></td></tr>
            <tr><td> Operar máquina de cópias Xerox. </td><td><input type="checkbox" name="C" value="d" {...register("C2.0")} /></td></tr>
            <tr><td> Realizar trabalhos de escritório. </td><td><input type="checkbox" name="C" value="e" {...register("C2.0")} /></td></tr>
            <tr><td> Trabalhar com um processador de textos. </td><td><input type="checkbox" name="C" value="f" {...register("C2.0")} /></td></tr>
            <tr><td> Obter e passar informações por telefone. </td><td><input type="checkbox" name="C" value="g" {...register("C2.0")} /></td></tr>
            <tr><td> Organizar ou catalogar documentos. </td><td><input type="checkbox" name="C" value="h" {...register("C2.0")} /></td></tr>
            <tr><td> Realizar vários trabalhos burocráticos rapidamente. </td><td><input type="checkbox" name="C" value="i" {...register("C2.0")} /></td></tr>
            <tr><td> Digitar a partir de um ditado. </td><td><input type="checkbox" name="C" value="j" {...register("C2.0")} /></td></tr>
            <tr><td> Fazer correções rapidamente. </td><td><input type="checkbox" name="C" value="k" {...register("C2.0")} /></td></tr>
          </tbody>
        </Table>
        <p></p>
        <Table striped className="exTableSDS3">
          <thead>
            <tr><th>Responda SIM para as CARREIRAS que GOSTARIA de seguir.</th><th>Sim</th></tr>
          </thead>
          <tbody>
            <tr><td> Engenheiro Mecânico (projeto, fabricação e manutenção de máquinas e equipamentos). </td><td><input type="checkbox" name="R" value="a" {...register("R3.0")} /></td></tr>
            <tr><td> Bombeiro (resgate, prevenção e combate a incêndios). </td><td><input type="checkbox" name="R" value="b" {...register("R3.0")} /></td></tr>
            <tr><td> Fiscal de construção civil (inspecionar novas construções). </td><td><input type="checkbox" name="R" value="c" {...register("R3.0")} /></td></tr>
            <tr><td> Agrônomo ou Fazendeiro (criar animais e cultivar plantações). </td><td><input type="checkbox" name="R" value="d" {...register("R3.0")} /></td></tr>
            <tr><td> Engenheiro Eletricista/Eletrônico  (projeto, instalação e manutenção em  equipamentos). </td><td><input type="checkbox" name="R" value="e" {...register("R3.0")} /></td></tr>
            <tr><td> Mecânico de aeronaves (consertar aviões e helicópteros). </td><td><input type="checkbox" name="R" value="f" {...register("R3.0")} /></td></tr>
            <tr><td> Engenheiro Civil/Arquiteto (projeto e construção  de residências, estradas, pontes). </td><td><input type="checkbox" name="R" value="g" {...register("R3.0")} /></td></tr>
            <tr><td> Operador de rádio (transmitir e receber informações pelo rádio). </td><td><input type="checkbox" name="R" value="h" {...register("R3.0")} /></td></tr>
            <tr><td> Astrônomo (observação e estudo dos astros). </td><td><input type="checkbox" name="R" value="i" {...register("R3.0")} /></td></tr>
            <tr><td> Engenheiro de Minas /Geólogo (pesquisa, análise, projeto, extração de minérios). </td><td><input type="checkbox" name="R" value="j" {...register("R3.0")} /></td></tr>
            <tr><td> Engenheiro Químico/Materiais (pesquisa, desenvolvimento,  processo de produtos químicos). </td><td><input type="checkbox" name="R" value="k" {...register("R3.0")} /></td></tr>
            <tr><td> Marceneiro (constrói móveis, armários, etc). </td><td><input type="checkbox" name="R" value="l" {...register("R3.0")} /></td></tr>
            <tr><td> Biólogo (estuda plantas e animais). </td><td><input type="checkbox" name="I" value="a" {...register("I3.0")} /></td></tr>
            <tr><td> Antropólogo (estuda diferentes culturas). </td><td><input type="checkbox" name="I" value="b" {...register("I3.0")} /></td></tr>
            <tr><td> Biomédico (realiza exames laboratoriais e pesquisas). </td><td><input type="checkbox" name="I" value="c" {...register("I3.0")} /></td></tr>
            <tr><td> Químico (estuda e desenvolve produtos químicos). </td><td><input type="checkbox" name="I" value="d" {...register("I3.0")} /></td></tr>
            <tr><td> Médico (faz diagnósticos, tratamentos e cirurgias). </td><td><input type="checkbox" name="I" value="e" {...register("I3.0")} /></td></tr>
            <tr><td> Cientista Social (estuda as relações e problemas sociais). </td><td><input type="checkbox" name="I" value="f" {...register("I3.0")} /></td></tr>
            <tr><td> Físico (estuda as leis da natureza). </td><td><input type="checkbox" name="I" value="g" {...register("I3.0")} /></td></tr>
            <tr><td> Geólogo (estuda a origem, estrutura e composição da terra). </td><td><input type="checkbox" name="I" value="h" {...register("I3.0")} /></td></tr>
            <tr><td> Astrônomo (estuda o sistema solar). </td><td><input type="checkbox" name="I" value="i" {...register("I3.0")} /></td></tr>
            <tr><td> Zoólogo (estuda o desenvolvimento das espécies animais). </td><td><input type="checkbox" name="I" value="j" {...register("I3.0")} /></td></tr>
            <tr><td> Meteorologista (estuda os fenômenos climáticos). </td><td><input type="checkbox" name="I" value="k" {...register("I3.0")} /></td></tr>
            <tr><td> Pesquisador Científico (encontrar soluções para as questões científicas). </td><td><input type="checkbox" name="I" value="l" {...register("I3.0")} /></td></tr>
            <tr><td> Poeta (escrever poemas). </td><td><input type="checkbox" name="A" value="a" {...register("A3.0")} /></td></tr>
            <tr><td> Artista Plástico (criar pinturas, desenhos, esculturas). </td><td><input type="checkbox" name="A" value="b" {...register("A3.0")} /></td></tr>
            <tr><td> Dramaturgo (escrever peças para teatro). </td><td><input type="checkbox" name="A" value="c" {...register("A3.0")} /></td></tr>
            <tr><td> Músico (tocar instrumentos musicais). </td><td><input type="checkbox" name="A" value="d" {...register("A3.0")} /></td></tr>
            <tr><td> Ator (interpretar papéis em filmes, teatros e televisão). </td><td><input type="checkbox" name="A" value="e" {...register("A3.0")} /></td></tr>
            <tr><td> Cantor (cantar para o público). </td><td><input type="checkbox" name="A" value="f" {...register("A3.0")} /></td></tr>
            <tr><td> Fotógrafo (tirar fotos diversas). </td><td><input type="checkbox" name="A" value="g" {...register("A3.0")} /></td></tr>
            <tr><td> Compositor (escrever músicas). </td><td><input type="checkbox" name="A" value="h" {...register("A3.0")} /></td></tr>
            <tr><td> Diretor de Teatro (dirigir atores nas interpretações dos papéis). </td><td><input type="checkbox" name="A" value="i" {...register("A3.0")} /></td></tr>
            <tr><td> Artista de Entretenimento (contar piadas, cantar ou dançar). </td><td><input type="checkbox" name="A" value="j" {...register("A3.0")} /></td></tr>
            <tr><td> Coreógrafo (criar a coreografia para uma apresentação). </td><td><input type="checkbox" name="A" value="k" {...register("A3.0")} /></td></tr>
            <tr><td> Cineasta (criar uma obra cinematográfica). </td><td><input type="checkbox" name="A" value="l" {...register("A3.0")} /></td></tr>
            <tr><td> Advogado (defender os seus clientes em processos jurídicos). </td><td><input type="checkbox" name="S" value="a" {...register("S3.0")} /></td></tr>
            <tr><td> Assistente Social (analisa e propõe ações para a melhoria da qualidade de vida das pessoas).  </td><td><input type="checkbox" name="S" value="b" {...register("S3.0")} /></td></tr>
            <tr><td> Terapeuta Ocupacional (utiliza atividades diversas para promover a recuperação das pessoas). </td><td><input type="checkbox" name="S" value="c" {...register("S3.0")} /></td></tr>
            <tr><td> Orientador Educacional (ajuda no desenvolvimento pessoal do aluno). </td><td><input type="checkbox" name="S" value="d" {...register("S3.0")} /></td></tr>
            <tr><td> Psicólogo (estuda os fenômenos psíquicos e comportamentais do ser humano). </td><td><input type="checkbox" name="S" value="e" {...register("S3.0")} /></td></tr>
            <tr><td> Professor (leciona diversas matérias em escolas). </td><td><input type="checkbox" name="S" value="f" {...register("S3.0")} /></td></tr>
            <tr><td> Pedagogo (estuda os métodos de ensino e administra escolas). </td><td><input type="checkbox" name="S" value="g" {...register("S3.0")} /></td></tr>
            <tr><td> Teólogo (estuda as doutrinas, os dogmas)).  </td><td><input type="checkbox" name="S" value="h" {...register("S3.0")} /></td></tr>
            <tr><td> Psicopedagogo (estuda as dificuldades de aprendizagem das pessoas). </td><td><input type="checkbox" name="S" value="i" {...register("S3.0")} /></td></tr>
            <tr><td> Enfermeiro (cuida da recuperação da saúde das pessoas).  </td><td><input type="checkbox" name="S" value="j" {...register("S3.0")} /></td></tr>
            <tr><td> Sociólogo (estuda a origem, desenvolvimento e organização das sociedades humanas). </td><td><input type="checkbox" name="S" value="k" {...register("S3.0")} /></td></tr>
            <tr><td> Gerontólogo (cuidar de pessoas idosas). </td><td><input type="checkbox" name="S" value="l" {...register("S3.0")} /></td></tr>
            <tr><td> Gerente de Vendas (supervisiona uma equipe de vendedores). </td><td><input type="checkbox" name="E" value="a" {...register("E3.0")} /></td></tr>
            <tr><td> Gerente de Marketing (planeja e define as estratégias de marketing). </td><td><input type="checkbox" name="E" value="b" {...register("E3.0")} /></td></tr>
            <tr><td> Corretor de imóveis (negociação de imóveis). </td><td><input type="checkbox" name="E" value="c" {...register("E3.0")} /></td></tr>
            <tr><td> Funcionário público (trabalha em órgãos públicos). </td><td><input type="checkbox" name="E" value="d" {...register("E3.0")} /></td></tr>
            <tr><td> Gerente de compras (decide sobre quais produtos a empresa deve comprar). </td><td><input type="checkbox" name="E" value="e" {...register("E3.0")} /></td></tr>
            <tr><td> Gerente de emissora de TV (define a programação da TV). </td><td><input type="checkbox" name="E" value="f" {...register("E3.0")} /></td></tr>
            <tr><td> Corretor de títulos (negocia ações e títulos). </td><td><input type="checkbox" name="E" value="g" {...register("E3.0")} /></td></tr>
            <tr><td> Representante comercial (negocia produtos ou serviços). </td><td><input type="checkbox" name="E" value="h" {...register("E3.0")} /></td></tr>
            <tr><td> Proprietário de um empreendimento (ter o seu próprio negócio). </td><td><input type="checkbox" name="E" value="i" {...register("E3.0")} /></td></tr>
            <tr><td> Investidor (faz investimentos econômicos com previsões incertas). </td><td><input type="checkbox" name="E" value="j" {...register("E3.0")} /></td></tr>
            <tr><td> Executivo (supervisionar várias pessoas em um negócio). </td><td><input type="checkbox" name="E" value="k" {...register("E3.0")} /></td></tr>
            <tr><td> Vendedor (vender bens e serviços). </td><td><input type="checkbox" name="E" value="l" {...register("E3.0")} /></td></tr>
            <tr><td> Contador (faz a contabilidade de uma empresa). </td><td><input type="checkbox" name="C" value="a" {...register("C3.0")} /></td></tr>
            <tr><td> Gerente de Suprimentos (controla o estoque de produtos da empresa). </td><td><input type="checkbox" name="C" value="b" {...register("C3.0")} /></td></tr>
            <tr><td> Analista Financeiro (analisa investimentos econômicos de uma empresa). </td><td><input type="checkbox" name="C" value="c" {...register("C3.0")} /></td></tr>
            <tr><td> Bancário (atende clientes em um banco). </td><td><input type="checkbox" name="C" value="d" {...register("C3.0")} /></td></tr>
            <tr><td> Auditor (faz auditoria em documentos e processos identificando erros ou falhas). </td><td><input type="checkbox" name="C" value="e" {...register("C3.0")} /></td></tr>
            <tr><td> Tributarista (faz cálculos de impostos). </td><td><input type="checkbox" name="C" value="f" {...register("C3.0")} /></td></tr>
            <tr><td> Arquivista (organizar os arquivos de documentos de uma empresa) </td><td><input type="checkbox" name="C" value="g" {...register("C3.0")} /></td></tr>
            <tr><td> Gerente de Folhas de Pagamento (controlar o pagamento dos empregados de uma empresa). </td><td><input type="checkbox" name="C" value="h" {...register("C3.0")} /></td></tr>
            <tr><td> Programador de Computador (desenvolve programas de computador). </td><td><input type="checkbox" name="C" value="i" {...register("C3.0")} /></td></tr>
            <tr><td> Gerente de Orçamento (realiza orçamentos e planeja gastos da empresa). </td><td><input type="checkbox" name="C" value="j" {...register("C3.0")} /></td></tr>
            <tr><td> Gerente Administrativo (supervisiona os serviços administrativos da empresa). </td><td><input type="checkbox" name="C" value="k" {...register("C3.0")} /></td></tr>
            <tr><td> Secretário (assessorar o superior nos trabalhos de escritório). </td><td><input type="checkbox" name="C" value="l" {...register("C3.0")} /></td></tr>
          </tbody>

        </Table>

        <p>{errors && <Alert titulo="Responder todas autoavaliações!"></Alert>}</p>

        <Table striped className="exTableSDS4">
          <thead>
            <tr><th>Faça uma Autoavaliação e ESCOLHA uma NOTA (de 1 a 7), para as seguintes HABILIDADES:</th><th>Nota</th></tr>
          </thead>

          <tbody>

            <tr><td><strong>HABILIDADE MECÂNICA:</strong> Consertar coisas, usar ferramentas e máquinas.</td><td>
              <select name="select" {...register("MECANICA", { required: ((watch("MECANICA") !== "-")) })}>
                <option value="1">1</option><option value="2">2</option><option value="3">3</option><option value="4">4</option><option value="5">5</option><option value="6">6</option><option value="7">7</option><option value="" selected>-</option></select>
            </td></tr>

            <tr><td><strong>HABILIDADE CIENTÍFICA:</strong> Fazer pesquisas e experimentos em ciências.</td><td>
              <select name="select" {...register("CIENTIFICA", { required: ((watch("CIENTIFICA") !== "-")) })}>
                <option value="1">1</option><option value="2">2</option><option value="3">3</option><option value="4">4</option><option value="5">5</option><option value="6">6</option><option value="7">7</option><option value="" selected>-</option></select>
            </td></tr>

            <tr><td><strong>HABILIDADE ARTÍSTICA:</strong> Pintar, desenhar, fazer esculturas, escrever poesias.</td><td>
              <select name="select" {...register("ARTISTICA", { required: ((watch("ARTISTICA") !== "-")) })}>
                <option value="1">1</option><option value="2">2</option><option value="3">3</option><option value="4">4</option><option value="5">5</option><option value="6">6</option><option value="7">7</option><option value="" selected>-</option></select>
            </td></tr>

            <tr><td><strong>HABILIDADE DE ENSINO:</strong> Ajudar as pessoas a aprender.</td><td>
              <select name="select" {...register("ENSINO", { required: ((watch("ENSINO") !== "-")) })}>
                <option value="1">1</option><option value="2">2</option><option value="3">3</option><option value="4">4</option><option value="5">5</option><option value="6">6</option><option value="7">7</option><option value="" selected>-</option></select>
            </td></tr>

            <tr><td><strong>HABILIDADE DE VENDAS:</strong> Vender ou supervisionar vendedores.</td><td>
              <select name="select" {...register("VENDAS", { required: ((watch("VENDAS") !== "-")) })}>
                <option value="1">1</option><option value="2">2</option><option value="3">3</option><option value="4">4</option><option value="5">5</option><option value="6">6</option><option value="7">7</option><option value="" selected>-</option></select>
            </td></tr>

            <tr><td><strong>HABILIDADADE ADMINISTRATIVA:</strong> Organizar documentos, controlar números.</td><td>
              <select name="select" {...register("ADMINISTRATIVA", { required: ((watch("ADMINISTRATIVA") !== "-")) })}>
                <option value="1">1</option><option value="2">2</option><option value="3">3</option><option value="4">4</option><option value="5">5</option><option value="6">6</option><option value="7">7</option><option value="" selected>-</option></select>
            </td></tr>

            <tr><td><strong>HABILIDADE MANUAL:</strong> Construir coisas, trabalhar com as mãos.</td><td>
              <select name="select" {...register("MANUAL", { required: ((watch("MANUAL") !== "-")) })}>
                <option value="1">1</option><option value="2">2</option><option value="3">3</option><option value="4">4</option><option value="5">5</option><option value="6">6</option><option value="7">7</option><option value="" selected>-</option></select>
            </td></tr>

            <tr><td><strong>HABILIDADE NUMÉRICA:</strong> Fazer cálculos e fórmulas matemáticas.</td><td>
              <select name="select" {...register("NUMERICA", { required: ((watch("NUMERICA") !== "-")) })}>
                <option value="1">1</option><option value="2">2</option><option value="3">3</option><option value="4">4</option><option value="5">5</option><option value="6">6</option><option value="7">7</option><option value="" selected>-</option></select>
            </td></tr>

            <tr><td><strong>HABILIDADE MUSICAL:</strong> Cantar, compor, tocar um instrumento.</td><td>
              <select name="select" {...register("MUSICAL", { required: ((watch("MUSICAL") !== "-")) })}>
                <option value="1">1</option><option value="2">2</option><option value="3">3</option><option value="4">4</option><option value="5">5</option><option value="6">6</option><option value="7">7</option><option value="" selected>-</option></select>
            </td></tr>

            <tr><td><strong>HABILIDADE INTERPESSOAL:</strong> Trabalhar com pessoas, ajudando-as.</td><td>
              <select name="select" {...register("INTERPESSOAL", { required: ((watch("INTERPESSOAL") !== "-")) })}>
                <option value="1">1</option><option value="2">2</option><option value="3">3</option><option value="4">4</option><option value="5">5</option><option value="6">6</option><option value="7">7</option><option value="" selected>-</option></select>
            </td></tr>

            <tr><td><strong>HABILIDADE GERENCIAL:</strong> Supervisionar o trabalho de outras pessoas.</td><td>
              <select name="select" {...register("GERENCIAL", { required: ((watch("GERENCIAL") !== "-")) })}>
                <option value="1">1</option><option value="2">2</option><option value="3">3</option><option value="4">4</option><option value="5">5</option><option value="6">6</option><option value="7">7</option><option value="" selected>-</option></select>
            </td></tr>

            <tr><td><strong>HABILIDADE TECNOLÓGICA:</strong> Trabalhar com equipamentos de tecnologia.</td><td>
              <select name="select" {...register("TECNOLOGICA", { required: ((watch("TECNOLOGICA") !== "-")) })}>
                <option value="1">1</option><option value="2">2</option><option value="3">3</option><option value="4">4</option><option value="5">5</option><option value="6">6</option><option value="7">7</option><option value="" selected>-</option></select>
            </td></tr>

          </tbody>
        </Table>

        <button className="btn-login" type="submit">Enviar</button>

      </form>
      <Fimquest></Fimquest>
    </div>

  );
}









