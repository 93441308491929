//TROCAR EM TESTES .CSS
export const azul1hex = "#56f0e8";
export const azul2hex = "#0b9bb7";
export const azul3hex = "#0000fe";
export const azul4hex = "#020576";

export const vermelho1hex = "#ff8989";
export const vermelho2hex = "#cc0001";
export const vermelho3hex = "#9c0001";
export const vermelho4hex = "#6c0000";

export const verde1hex = "#cef42f";
export const verde2hex = "#00f325";
export const verde3hex = "#008001";
export const verde4hex = "#003700";

export const violeta1hex = "#bdb4e1";
export const violeta2hex = "#ac3984";
export const violeta3hex = "#652e65";

export const laranja1hex = "#ff772a";
export const laranja2hex = "#ff4627";

export const amarelo1hex = "#ffff07";
export const amarelo2hex = "#f0ba00";

export const marrom1hex = "#b16d4a";
export const marrom2hex = "#754831";

export const pretohex = "#1e201d";
export const brancohex = "#ffffff";
export const cinzahex = "#7a7a7a";





