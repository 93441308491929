import logo from "../../assets/img/logo.png";
import aira from "../../assets/img/aira.svg";

export default (props) => {
  return (
    <div className="footer-margin">
      <footer id="footer">
        <div className="footer-top">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-6 footer-contact">
                <h4>Contato</h4>
                <p>
                  <strong>Endereço:</strong> <br />
                  <i className="ion-ios-location"></i> Rua Evereste, 57 <br />{" "}
                  Alto das Quintas
                  <br /> Nova Lima
                  <br />
                  <br />
                  <strong>Telefone e WhatsApp:</strong> <br />
                  <i className="fab fa-whatsapp"></i> 31 99744-8914
                  <br />
                  <br />
                  <strong>Email:</strong>
                  <br />
                  <i></i> imop.vocacional@gmail.com
                  <br />
                  <i></i> josecruz.ov@gmail.com
                  <br />
                  <p>
                    <strong>Avalie nosso Trabalho:</strong> <br />
                    <i className="ion-ios-star"></i>
                    <a
                      className="social-linkss"
                      href="https://www.google.com/search?q=imop+vocacional&oq=imop+voca&aqs=chrome.0.69i59j69i57j69i61.1839j0j4&sourceid=chrome&ie=UTF-8#lrd=0xa69fab64dcb8eb:0xfc5e62590ca0fd94,1,,,"
                      target="_blank"
                    >
                      Google
                    </a>
                  </p>
                </p>
              </div>

              <div className="col-lg-2 col-md-6 footer-links">
                <h4>Nossos Links</h4>
                <ul>
                  <div className="social-links"></div>
                  <li>
                    <a
                      href="https://www.facebook.com/imop.vocacional.14"
                      target="_blank"
                    >
                      Facebook
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.linkedin.com/in/imop-orienta%C3%A7%C3%A3o-profissional-12859741/"
                      target="_blank"
                    >
                      Linkedin
                    </a>
                  </li>
                  <li>
                    <a
                      href="http://queroorientacaovocacional.blogspot.com/"
                      target="_blank"
                    >
                      Blog
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/imop.vocacional/"
                      target="_blank"
                    >
                      Instagram
                    </a>
                  </li>
                </ul>

                <br />

                <div className="social-links"></div>
              </div>

              <div className="col-lg-3 col-md-6 footer-newsletter"></div>

              <div className="col-lg-3 col-md-6 footer-newsletter">
                <div>
                  <img src={logo} alt="" className="img-fluid" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="copyright">
          <p>
            Sistema Desenvolvido por{" "}
            <img src={aira} alt="logo aira" width="20"></img>
            <a href="https://www.aira.blue/" target="_blank">
              {" "}
              Aira Blue
            </a>
          </p>
        </div>
      </footer>
    </div>
  );
};
