import ClientNavbar from "../../shared/clientNavbar";
import IntroSection from '../../shared/introSection';
import Instructions from "./instructionsG36";
import 'react-notifications/lib/notifications.css';

import Inicioquest from "../shared/inicioquest";
import Fimquest from "../shared/fimquest";
import { useForm } from "react-hook-form";
import Alert from "../shared/alert";
import React, { useState } from 'react';
import Notification from '../../testes/shared/notification';
import { Navigate } from "react-router-dom";
import { responder } from '../../../services/testeService';

import imagem0 from "./G36-data/0.png"
import imagem1 from "./G36-data/1.png"
import imagem2 from "./G36-data/2.png"
import imagem3 from "./G36-data/3.png"
import imagem4 from "./G36-data/4.png"
import imagem5 from "./G36-data/5.png"
import imagem6 from "./G36-data/6.png"
import imagem7 from "./G36-data/7.png"
import imagem8 from "./G36-data/8.png"
import imagem9 from "./G36-data/9.png"
import imagem10 from "./G36-data/10.png"
import imagem11 from "./G36-data/11.png"
import imagem12 from "./G36-data/12.png"
import imagem13 from "./G36-data/13.png"
import imagem14 from "./G36-data/14.png"
import imagem15 from "./G36-data/15.png"
import imagem16 from "./G36-data/16.png"
import imagem17 from "./G36-data/17.png"
import imagem18 from "./G36-data/18.png"
import imagem19 from "./G36-data/19.png"
import imagem20 from "./G36-data/20.png"
import imagem21 from "./G36-data/21.png"
import imagem22 from "./G36-data/22.png"
import imagem23 from "./G36-data/23.png"
import imagem24 from "./G36-data/24.png"
import imagem25 from "./G36-data/25.png"
import imagem26 from "./G36-data/26.png"
import imagem27 from "./G36-data/27.png"
import imagem28 from "./G36-data/28.png"
import imagem29 from "./G36-data/29.png"
import imagem30 from "./G36-data/30.png"
import imagem31 from "./G36-data/31.png"
import imagem32 from "./G36-data/32.png"
import imagem33 from "./G36-data/33.png"
import imagem34 from "./G36-data/34.png"
import imagem35 from "./G36-data/35.png"
import imagem36 from "./G36-data/36.png"


export default function G36() {

  const { register, handleSubmit, watch, formState: { errors } } = useForm();
  const [ error401, setError ] = useState(false);
  const [ redirect, setRedirect ] = useState(false);
  const onSubmit = async data => {
    try {
      const response = await responder(4, data);
      setRedirect(true);
    }
    catch (error) {
      setError(true);
    }
  };
      
    return (
        <div>

        {error401 && <Notification titulo="Erro ao enviar esse formulario!" />}
        {redirect && <Navigate to="/testes" />}

        <ClientNavbar titulo="G-36"> </ClientNavbar>

        <IntroSection>
            <Instructions></Instructions>
        </IntroSection> 

        <Inicioquest></Inicioquest>

      <form className="formsize" onSubmit={handleSubmit(onSubmit)}>
      <div className="divisoria"><p></p></div>
        <div className="divG36">
        <h2>1</h2>
          <img className="imagemG36" alt="" src={imagem1}></img>
            <p>{errors.q1 && <Alert titulo="Questão não respondida!"></Alert>}</p>
            <div className="div2G36">
              <span>Resposta:</span>
                <select className="selectG36" {...register("q1", { required: ((watch("q1")!=="-"))})}>
                  <option value="1">1</option><option value="2">2</option><option value="3">3</option><option value="4">4</option><option value="5">5</option><option value="6">6</option><option value="" selected>-</option>
                </select>
            </div>
          </div>
      <div className="divisoria"><p></p></div>
        <div className="divG36">
        <h2>2</h2>
          <img className="imagemG36" alt="" src={imagem2}></img>
            <p>{errors.q2 && <Alert titulo="Questão não respondida!"></Alert>}</p>
            <div className="div2G36">
              <span>Resposta:</span>
                <select className="selectG36" {...register("q2", { required: ((watch("q2")!=="-"))})}>
                  <option value="1">1</option><option value="2">2</option><option value="3">3</option><option value="4">4</option><option value="5">5</option><option value="6">6</option><option value="" selected>-</option>
                </select>
            </div>
          </div>
      <div className="divisoria"><p></p></div>
        <div className="divG36">
        <h2>3</h2>
          <img className="imagemG36" alt=""  src={imagem3}></img>
            <p>{errors.q3 && <Alert titulo="Questão não respondida!"></Alert>}</p>
            <div className="div2G36">
              <span>Resposta:</span>
                <select className="selectG36" {...register("q3", { required: ((watch("q3")!=="-"))})}>
                  <option value="1">1</option><option value="2">2</option><option value="3">3</option><option value="4">4</option><option value="5">5</option><option value="6">6</option><option value="" selected>-</option>
                </select>
            </div>
          </div>
      <div className="divisoria"><p></p></div>
        <div className="divG36">
        <h2>4</h2>
          <img className="imagemG36" alt=""  src={imagem4}></img>
            <p>{errors.q4 && <Alert titulo="Questão não respondida!"></Alert>}</p>
            <div className="div2G36">
              <span>Resposta:</span>
                <select className="selectG36" {...register("q4", { required: ((watch("q4")!=="-"))})}>
                  <option value="1">1</option><option value="2">2</option><option value="3">3</option><option value="4">4</option><option value="5">5</option><option value="6">6</option><option value="" selected>-</option>
                </select>
            </div>
          </div>
      <div className="divisoria"><p></p></div>
        <div className="divG36">
        <h2>5</h2>
          <img className="imagemG36" alt=""  src={imagem5}></img>
            <p>{errors.q5 && <Alert titulo="Questão não respondida!"></Alert>}</p>
            <div className="div2G36">
              <span>Resposta:</span>
                <select className="selectG36" {...register("q5", { required: ((watch("q5")!=="-"))})}>
                  <option value="1">1</option><option value="2">2</option><option value="3">3</option><option value="4">4</option><option value="5">5</option><option value="6">6</option><option value="" selected>-</option>
                </select>
            </div>
          </div>
      <div className="divisoria"><p></p></div>
        <div className="divG36">
        <h2>6</h2>
          <img className="imagemG36" alt=""  src={imagem6}></img>
            <p>{errors.q6 && <Alert titulo="Questão não respondida!"></Alert>}</p>
            <div className="div2G36">
              <span>Resposta:</span>
                <select className="selectG36" {...register("q6", { required: ((watch("q6")!=="-"))})}>
                  <option value="1">1</option><option value="2">2</option><option value="3">3</option><option value="4">4</option><option value="5">5</option><option value="6">6</option><option value="" selected>-</option>
                </select>
            </div>
          </div>
      <div className="divisoria"><p></p></div>
        <div className="divG36">
        <h2>7</h2>
          <img className="imagemG36" alt=""  src={imagem7}></img>
            <p>{errors.q7 && <Alert titulo="Questão não respondida!"></Alert>}</p>
            <div className="div2G36">
              <span>Resposta:</span>
                <select className="selectG36" {...register("q7", { required: ((watch("q7")!=="-"))})}>
                  <option value="1">1</option><option value="2">2</option><option value="3">3</option><option value="4">4</option><option value="5">5</option><option value="6">6</option><option value="" selected>-</option>
                </select>
            </div>
          </div>
      <div className="divisoria"><p></p></div>
        <div className="divG36">
        <h2>8</h2>
          <img className="imagemG36" alt=""  src={imagem8}></img>
            <p>{errors.q8 && <Alert titulo="Questão não respondida!"></Alert>}</p>
            <div className="div2G36">
              <span>Resposta:</span>
                <select className="selectG36" {...register("q8", { required: ((watch("q8")!=="-"))})}>
                  <option value="1">1</option><option value="2">2</option><option value="3">3</option><option value="4">4</option><option value="5">5</option><option value="6">6</option><option value="" selected>-</option>
                </select>
            </div>
          </div>
      <div className="divisoria"><p></p></div>
        <div className="divG36">
        <h2>9</h2>
          <img className="imagemG36" alt=""  src={imagem9}></img>
            <p>{errors.q9 && <Alert titulo="Questão não respondida!"></Alert>}</p>
            <div className="div2G36">
              <span>Resposta:</span>
                <select className="selectG36" {...register("q9", { required: ((watch("q9")!=="-"))})}>
                  <option value="1">1</option><option value="2">2</option><option value="3">3</option><option value="4">4</option><option value="5">5</option><option value="6">6</option><option value="" selected>-</option>
                </select>
            </div>
          </div>
      <div className="divisoria"><p></p></div>
        <div className="divG36">
        <h2>10</h2>
          <img className="imagemG36" alt=""  src={imagem10}></img>
            <p>{errors.q10 && <Alert titulo="Questão não respondida!"></Alert>}</p>
            <div className="div2G36">
              <span>Resposta:</span>
                <select className="selectG36" {...register("q10", { required: ((watch("q10")!=="-"))})}>
                  <option value="1">1</option><option value="2">2</option><option value="3">3</option><option value="4">4</option><option value="5">5</option><option value="6">6</option><option value="" selected>-</option>
                </select>
            </div>
          </div>
      <div className="divisoria"><p></p></div>
        <div className="divG36">
        <h2>11</h2>
          <img className="imagemG36" alt=""  src={imagem11}></img>
            <p>{errors.q11 && <Alert titulo="Questão não respondida!"></Alert>}</p>
            <div className="div2G36">
              <span>Resposta:</span>
                <select className="selectG36" {...register("q11", { required: ((watch("q11")!=="-"))})}>
                  <option value="1">1</option><option value="2">2</option><option value="3">3</option><option value="4">4</option><option value="5">5</option><option value="6">6</option><option value="" selected>-</option>
                </select>
            </div>
          </div>
      <div className="divisoria"><p></p></div>
        <div className="divG36">
        <h2>12</h2>
          <img className="imagemG36" alt=""  src={imagem12}></img>
            <p>{errors.q12 && <Alert titulo="Questão não respondida!"></Alert>}</p>
            <div className="div2G36">
              <span>Resposta:</span>
                <select className="selectG36" {...register("q12", { required: ((watch("q12")!=="-"))})}>
                  <option value="1">1</option><option value="2">2</option><option value="3">3</option><option value="4">4</option><option value="5">5</option><option value="6">6</option><option value="" selected>-</option>
                </select>
            </div>
          </div>
      <div className="divisoria"><p></p></div>
        <div className="divG36">
        <h2>13</h2>
          <img className="imagemG36" alt=""  src={imagem13}></img>
            <p>{errors.q13 && <Alert titulo="Questão não respondida!"></Alert>}</p>
            <div className="div2G36">
              <span>Resposta:</span>
                <select className="selectG36" {...register("q13", { required: ((watch("q13")!=="-"))})}>
                  <option value="1">1</option><option value="2">2</option><option value="3">3</option><option value="4">4</option><option value="5">5</option><option value="6">6</option><option value="" selected>-</option>
                </select>
            </div>
          </div>
      <div className="divisoria"><p></p></div>
        <div className="divG36">
        <h2>14</h2>
          <img className="imagemG36" alt=""  src={imagem14}></img>
            <p>{errors.q14 && <Alert titulo="Questão não respondida!"></Alert>}</p>
            <div className="div2G36">
              <span>Resposta:</span>
                <select className="selectG36" {...register("q14", { required: ((watch("q14")!=="-"))})}>
                  <option value="1">1</option><option value="2">2</option><option value="3">3</option><option value="4">4</option><option value="5">5</option><option value="6">6</option><option value="" selected>-</option>
                </select>
            </div>
          </div>
      <div className="divisoria"><p></p></div>
        <div className="divG36">
        <h2>15</h2>
          <img className="imagemG36" alt=""  src={imagem15}></img>
            <p>{errors.q15 && <Alert titulo="Questão não respondida!"></Alert>}</p>
            <div className="div2G36">
              <span>Resposta:</span>
                <select className="selectG36" {...register("q15", { required: ((watch("q15")!=="-"))})}>
                  <option value="1">1</option><option value="2">2</option><option value="3">3</option><option value="4">4</option><option value="5">5</option><option value="6">6</option><option value="" selected>-</option>
                </select>
            </div>
          </div>
      <div className="divisoria"><p></p></div>
        <div className="divG36">
        <h2>16</h2>
          <img className="imagemG36" alt=""  src={imagem16}></img>
            <p>{errors.q16 && <Alert titulo="Questão não respondida!"></Alert>}</p>
            <div className="div2G36">
              <span>Resposta:</span>
                <select className="selectG36" {...register("q16", { required: ((watch("q16")!=="-"))})}>
                  <option value="1">1</option><option value="2">2</option><option value="3">3</option><option value="4">4</option><option value="5">5</option><option value="6">6</option><option value="" selected>-</option>
                </select>
            </div>
          </div>
      <div className="divisoria"><p></p></div>
        <div className="divG36">
        <h2>17</h2>
          <img className="imagemG36" alt=""  src={imagem17}></img>
            <p>{errors.q17 && <Alert titulo="Questão não respondida!"></Alert>}</p>
            <div className="div2G36">
              <span>Resposta:</span>
                <select className="selectG36" {...register("q17", { required: ((watch("q17")!=="-"))})}>
                  <option value="1">1</option><option value="2">2</option><option value="3">3</option><option value="4">4</option><option value="5">5</option><option value="6">6</option><option value="" selected>-</option>
                </select>
            </div>
          </div>
      <div className="divisoria"><p></p></div>
        <div className="divG36">
        <h2>18</h2>
          <img className="imagemG36" alt=""  src={imagem18}></img>
            <p>{errors.q18 && <Alert titulo="Questão não respondida!"></Alert>}</p>
            <div className="div2G36">
              <span>Resposta:</span>
                <select className="selectG36" {...register("q18", { required: ((watch("q18")!=="-"))})}>
                  <option value="1">1</option><option value="2">2</option><option value="3">3</option><option value="4">4</option><option value="5">5</option><option value="6">6</option><option value="" selected>-</option>
                </select>
            </div>
          </div>
      <div className="divisoria"><p></p></div>
        <div className="divG36">
        <h2>19</h2>
          <img className="imagemG36" alt=""  src={imagem19}></img>
            <p>{errors.q19 && <Alert titulo="Questão não respondida!"></Alert>}</p>
            <div className="div2G36">
              <span>Resposta:</span>
                <select className="selectG36" {...register("q19", { required: ((watch("q19")!=="-"))})}>
                  <option value="1">1</option><option value="2">2</option><option value="3">3</option><option value="4">4</option><option value="5">5</option><option value="6">6</option><option value="" selected>-</option>
                </select>
            </div>
          </div>
      <div className="divisoria"><p></p></div>
        <div className="divG36">
        <h2>20</h2>
          <img className="imagemG36" alt=""  src={imagem20}></img>
            <p>{errors.q20 && <Alert titulo="Questão não respondida!"></Alert>}</p>
            <div className="div2G36">
              <span>Resposta:</span>
                <select className="selectG36" {...register("q20", { required: ((watch("q20")!=="-"))})}>
                  <option value="1">1</option><option value="2">2</option><option value="3">3</option><option value="4">4</option><option value="5">5</option><option value="6">6</option><option value="" selected>-</option>
                </select>
            </div>
          </div>
      <div className="divisoria"><p></p></div>
        <div className="divG36">
        <h2>21</h2>
          <img className="imagemG36" alt=""  src={imagem21}></img>
            <p>{errors.q21 && <Alert titulo="Questão não respondida!"></Alert>}</p>
            <div className="div2G36">
              <span>Resposta:</span>
                <select className="selectG36" {...register("q21", { required: ((watch("q21")!=="-"))})}>
                  <option value="1">1</option><option value="2">2</option><option value="3">3</option><option value="4">4</option><option value="5">5</option><option value="6">6</option><option value="" selected>-</option>
                </select>
            </div>
          </div>
      <div className="divisoria"><p></p></div>
        <div className="divG36">
        <h2>22</h2>
          <img className="imagemG36" alt=""  src={imagem22}></img>
            <p>{errors.q22 && <Alert titulo="Questão não respondida!"></Alert>}</p>
            <div className="div2G36">
              <span>Resposta:</span>
                <select className="selectG36" {...register("q22", { required: ((watch("q22")!=="-"))})}>
                  <option value="1">1</option><option value="2">2</option><option value="3">3</option><option value="4">4</option><option value="5">5</option><option value="6">6</option><option value="" selected>-</option>
                </select>
            </div>
          </div>
      <div className="divisoria"><p></p></div>
        <div className="divG36">
        <h2>23</h2>
          <img className="imagemG36" alt=""  src={imagem23}></img>
            <p>{errors.q23 && <Alert titulo="Questão não respondida!"></Alert>}</p>
            <div className="div2G36">
              <span>Resposta:</span>
                <select className="selectG36" {...register("q23", { required: ((watch("q23")!=="-"))})}>
                  <option value="1">1</option><option value="2">2</option><option value="3">3</option><option value="4">4</option><option value="5">5</option><option value="6">6</option><option value="" selected>-</option>
                </select>
            </div>
          </div>
      <div className="divisoria"><p></p></div>
        <div className="divG36">
        <h2>24</h2>
          <img className="imagemG36" alt=""  src={imagem24}></img>
            <p>{errors.q24 && <Alert titulo="Questão não respondida!"></Alert>}</p>
            <div className="div2G36">
              <span>Resposta:</span>
                <select className="selectG36" {...register("q24", { required: ((watch("q24")!=="-"))})}>
                  <option value="1">1</option><option value="2">2</option><option value="3">3</option><option value="4">4</option><option value="5">5</option><option value="6">6</option><option value="" selected>-</option>
                </select>
            </div>
          </div>
      <div className="divisoria"><p></p></div>
        <div className="divG36">
        <h2>25</h2>
          <img className="imagemG36" alt=""  src={imagem25}></img>
            <p>{errors.q25 && <Alert titulo="Questão não respondida!"></Alert>}</p>
            <div className="div2G36">
              <span>Resposta:</span>
                <select className="selectG36" {...register("q25", { required: ((watch("q25")!=="-"))})}>
                  <option value="1">1</option><option value="2">2</option><option value="3">3</option><option value="4">4</option><option value="5">5</option><option value="6">6</option><option value="" selected>-</option>
                </select>
            </div>
          </div>
      <div className="divisoria"><p></p></div>
        <div className="divG36">
        <h2>26</h2>
          <img className="imagemG36" alt=""  src={imagem26}></img>
            <p>{errors.q26 && <Alert titulo="Questão não respondida!"></Alert>}</p>
            <div className="div2G36">
              <span>Resposta:</span>
                <select className="selectG36" {...register("q26", { required: ((watch("q26")!=="-"))})}>
                  <option value="1">1</option><option value="2">2</option><option value="3">3</option><option value="4">4</option><option value="5">5</option><option value="6">6</option><option value="" selected>-</option>
                </select>
            </div>
          </div>
      <div className="divisoria"><p></p></div>
        <div className="divG36">
        <h2>27</h2>
          <img className="imagemG36" alt=""  src={imagem27}></img>
            <p>{errors.q27 && <Alert titulo="Questão não respondida!"></Alert>}</p>
            <div className="div2G36">
              <span>Resposta:</span>
                <select className="selectG36" {...register("q27", { required: ((watch("q27")!=="-"))})}>
                  <option value="1">1</option><option value="2">2</option><option value="3">3</option><option value="4">4</option><option value="5">5</option><option value="6">6</option><option value="" selected>-</option>
                </select>
            </div>
          </div>
      <div className="divisoria"><p></p></div>
        <div className="divG36">
        <h2>28</h2>
          <img className="imagemG36" alt=""  src={imagem28}></img>
            <p>{errors.q28 && <Alert titulo="Questão não respondida!"></Alert>}</p>
            <div className="div2G36">
              <span>Resposta:</span>
                <select className="selectG36" {...register("q28", { required: ((watch("q28")!=="-"))})}>
                  <option value="1">1</option><option value="2">2</option><option value="3">3</option><option value="4">4</option><option value="5">5</option><option value="6">6</option><option value="" selected>-</option>
                </select>
            </div>
          </div>
      <div className="divisoria"><p></p></div>
        <div className="divG36">
        <h2>29</h2>
          <img className="imagemG36" alt=""  src={imagem29}></img>
            <p>{errors.q29 && <Alert titulo="Questão não respondida!"></Alert>}</p>
            <div className="div2G36">
              <span>Resposta:</span>
                <select className="selectG36" {...register("q29", { required: ((watch("q29")!=="-"))})}>
                  <option value="1">1</option><option value="2">2</option><option value="3">3</option><option value="4">4</option><option value="5">5</option><option value="6">6</option><option value="" selected>-</option>
                </select>
            </div>
          </div>
      <div className="divisoria"><p></p></div>
        <div className="divG36">
        <h2>30</h2>
          <img className="imagemG36" alt=""  src={imagem30}></img>
            <p>{errors.q30 && <Alert titulo="Questão não respondida!"></Alert>}</p>
            <div className="div2G36">
              <span>Resposta:</span>
                <select className="selectG36" {...register("q30", { required: ((watch("q30")!=="-"))})}>
                  <option value="1">1</option><option value="2">2</option><option value="3">3</option><option value="4">4</option><option value="5">5</option><option value="6">6</option><option value="" selected>-</option>
                </select>
            </div>
          </div>
      <div className="divisoria"><p></p></div>
        <div className="divG36">
        <h2>31</h2>
          <img className="imagemG36" alt=""  src={imagem31}></img>
            <p>{errors.q31 && <Alert titulo="Questão não respondida!"></Alert>}</p>
            <div className="div2G36">
              <span>Resposta:</span>
                <select className="selectG36" {...register("q31", { required: ((watch("q31")!=="-"))})}>
                  <option value="1">1</option><option value="2">2</option><option value="3">3</option><option value="4">4</option><option value="5">5</option><option value="6">6</option><option value="" selected>-</option>
                </select>
            </div>
          </div>
      <div className="divisoria"><p></p></div>
        <div className="divG36">
        <h2>32</h2>
          <img className="imagemG36" alt=""  src={imagem32}></img>
            <p>{errors.q32 && <Alert titulo="Questão não respondida!"></Alert>}</p>
            <div className="div2G36">
              <span>Resposta:</span>
                <select className="selectG36" {...register("q32", { required: ((watch("q32")!=="-"))})}>
                  <option value="1">1</option><option value="2">2</option><option value="3">3</option><option value="4">4</option><option value="5">5</option><option value="6">6</option><option value="" selected>-</option>
                </select>
            </div>
          </div>
      <div className="divisoria"><p></p></div>
        <div className="divG36">
        <h2>33</h2>
          <img className="imagemG36" alt=""  src={imagem33}></img>
            <p>{errors.q33 && <Alert titulo="Questão não respondida!"></Alert>}</p>
            <div className="div2G36">
              <span>Resposta:</span>
                <select className="selectG36" {...register("q33", { required: ((watch("q33")!=="-"))})}>
                  <option value="1">1</option><option value="2">2</option><option value="3">3</option><option value="4">4</option><option value="5">5</option><option value="6">6</option><option value="" selected>-</option>
                </select>
            </div>
          </div>
      <div className="divisoria"><p></p></div>
        <div className="divG36">
        <h2>34</h2>
          <img className="imagemG36" alt=""  src={imagem34}></img>
            <p>{errors.q34 && <Alert titulo="Questão não respondida!"></Alert>}</p>
            <div className="div2G36">
              <span>Resposta:</span>
                <select className="selectG36" {...register("q34", { required: ((watch("q34")!=="-"))})}>
                  <option value="1">1</option><option value="2">2</option><option value="3">3</option><option value="4">4</option><option value="5">5</option><option value="6">6</option><option value="" selected>-</option>
                </select>
            </div>
          </div>
      <div className="divisoria"><p></p></div>
        <div className="divG36">
        <h2>35</h2>
          <img className="imagemG36" alt=""  src={imagem35}></img>
            <p>{errors.q35 && <Alert titulo="Questão não respondida!"></Alert>}</p>
            <div className="div2G36">
              <span>Resposta:</span>
                <select className="selectG36" {...register("q35", { required: ((watch("q35")!=="-"))})}>
                  <option value="1">1</option><option value="2">2</option><option value="3">3</option><option value="4">4</option><option value="5">5</option><option value="6">6</option><option value="" selected>-</option>
                </select>
            </div>
          </div>
      <div className="divisoria"><p></p></div>
        <div className="divG36">
        <h2>36</h2>
          <img className="imagemG36" alt=""  src={imagem36}></img>
            <p>{errors.q36 && <Alert titulo="Questão não respondida!"></Alert>}</p>
            <div className="div2G36">
              <span>Resposta:</span>
                <select className="selectG36" {...register("q36", { required: ((watch("q36")!=="-"))})}>
                  <option value="1">1</option><option value="2">2</option><option value="3">3</option><option value="4">4</option><option value="5">5</option><option value="6">6</option><option value="" selected>-</option>
                </select>
            </div>
          </div>
        <button className="btn-login" type="submit">Enviar</button>
            
    </form>
            <Fimquest></Fimquest>
      </div>
      
    );
  }