const aip = [
  { id: 1, frase: "Participar de projetos para a preservação das baleias." },
  { id: 2, frase: "Conhecer um programa de computador para controlar despesas de uma empresa." },
  { id: 3, frase: "Ajudar no planejamento de núcleos de informática para as escolas." },
  { id: 4, frase: "Conhecer o funcionamento dos poderes Executivo, Legislativo e Judiciário." },
  { id: 5, frase: "Fazer a estatística de uma pesquisa sobre nível intelectual da juventude." },
  { id: 6, frase: "Tentar convencer um amigo a deixar um vício." },
  { id: 7, frase: "Aprimorar o uso do raio laser para cirurgias." },
  { id: 8, frase: "Pesquisar o funcionamento dos sistemas de comunicação por celular." },
  { id: 9, frase: "Orientar pais na adaptação de crianças pequenas à escola." },
  { id: 10, frase: "Coordenar o armazenamento e a distribuição de mercadorias." },
  { id: 11, frase: "Pesquisar as origens das palavras da lingua portuguesa." },
  { id: 12, frase: "Calcular o tempo de retorno do investimento financeiro de um negócio." },
  { id: 13, frase: "Desenhar um moderno ginásio de esportes." },
  { id: 14, frase: "Conhecer um instituto de estudos ecológicos." },
  { id: 15, frase: "Conhecer a fórmula química dos refrigerantes." },
  { id: 16, frase: "Ler sobre a vida de empresários bem sucedidos." },
  { id: 17, frase: "Participar da definição de políticas de inclusão social." },
  { id: 18, frase: "Buscar patrocinadores para um show da banda da escola." },
  { id: 19, frase: "Calcular a velocidade de um cometa." },
  { id: 20, frase: "Acompanhar as pesquisas sobre células-tronco." },
  { id: 21, frase: "Participar de um debate sobre a influência da propaganda no consumismo." },
  { id: 22, frase: "Ser o negociador para a libertação de uma vítima de sequestro." },
  { id: 23, frase: "Acompanhar o desempenho de ações na Bolsa de Valores." },
  { id: 24, frase: "Estudar os riscos de contaminação em embalagens de alimentos." },
  { id: 25, frase: "Participar de uma miniempresa organizada na escola." },
  { id: 26, frase: "Estudar lesões musculares em atletas." },
  { id: 27, frase: "Pesquisar o grau de stress de vigilantes noturnos" },
  { id: 28, frase: "Dar apoio emocional a uma pessoa doente." },
  { id: 29, frase: "Compor a letra de uma música." },
  { id: 30, frase: "Realizar estágio em um laboratório de cosméticos." },
  { id: 31, frase: "Escolher quadros para decorar uma sala." },
  { id: 32, frase: "Adaptar eletrodomésticos ao uso de controle remoto." },
  { id: 33, frase: "Acompanhar um processo trabalhista contra uma empresa." },
  { id: 34, frase: "Estudar sobre a desigualdade social." },
  { id: 35, frase: "Desenhar fantasias para o carnaval." },
  { id: 36, frase: "Criar palavras-chave para um dicionário de sinônimos." },
  { id: 37, frase: "Pesquisar tratamento para células cancerígenas." },
  { id: 38, frase: "Pesquisar tratamento para asma" },
  { id: 39, frase: "Montar um castelo com cartas de baralho." },
  { id: 40, frase: "Estudar sobre o tratamento de esgoto." },
  { id: 41, frase: "Escrever sobre a linguagem utilizada pelos jovens na internet." },
  { id: 42, frase: "Ler sobre a vida e obra de pessoas famosas da História." },
  { id: 43, frase: "Realizar estágio em um escritório de contabilidade." },
  { id: 44, frase: "Assistir uma palestra sobre economia mundial." },
  { id: 45, frase: "Pesquisar o padrão de vida dos caminhoneiros." },
  { id: 46, frase: "Inventar um novo tipo de forno de micro-ondas." },
  { id: 47, frase: "Instruir funcionários de uma loja sobre o Código de Defesa do Consumidor." },
  { id: 48, frase: "Frequentar uma exposição de Arte Moderna." },
  { id: 49, frase: "Visitar um hospital psiquiátrico." },
  { id: 50, frase: "Ler estudos sobre a AIDS." },
  { id: 51, frase: "Calcular os juros do financiamento de um carro." },
  { id: 52, frase: "Planejar os custos de uma viagem ao exterior." },
  { id: 53, frase: "Buscar um acordo entre as partes num processo de separação." },
  { id: 54, frase: "Transformar uma obra literária em roteiro de um filme." },
  { id: 55, frase: "Assistir a um vídeo sobre parto cesariano." },
  { id: 56, frase: "Ser comentarista esportivo." },
  { id: 57, frase: "Reprogramar os semáforos para melhorar o trânsito." },
  { id: 58, frase: "Criar personagens para uma história infantil." },
  { id: 59, frase: "Estabelecer a rotina de atendimento aos hóspedes de um hotel." },
  { id: 60, frase: "Coordenar a distribuição e venda de produtos de uma empresa." },
  { id: 61, frase: "Estudar os aspectos emocionais que interferem na alfabetização." },
  { id: 62, frase: "Visitar laboratório de pesquisas de novos solventes." },
  { id: 63, frase: "Traduzir a letra de uma música." },
  { id: 64, frase: "Ler sobre os acordos de paz no cenário internacional." },
  { id: 65, frase: "Fazer estágio em um escritório de advocacia." },
  { id: 66, frase: "Projetar um novo tipo de impressora." },
  { id: 67, frase: "Produzir panfletos para divulgar uma festa." },
  { id: 68, frase: "Aprender a utilizar ferramentas de desenho no computador." },
  { id: 69, frase: "Estudar o teor calórico dos alimentos." },
  { id: 70, frase: "Ajudar na instalação de equipamentos para uso de cartões eletrônicos." },
  { id: 71, frase: "Aprender a ler e escrever notas musicais." },
  { id: 72, frase: "Defender sua posição em um debate sobre pena de morte." },
  { id: 73, frase: "Organizar cronograma de aulas da escola." },
  { id: 74, frase: "Elaborar argumentos para defender uma pessoa acusada de um crime." },
  { id: 75, frase: "Ser tesoureiro de uma entidade estudantil." },
  { id: 76, frase: "Pesquisar sobre a melhor maneira de alfabetizar crianças." },
  { id: 77, frase: "Fazer pós-graduação em Física no exterior." },
  { id: 78, frase: "Assistir a um congresso sobre grandes negócios." },
  { id: 79, frase: "Ler sobre os animais e suas formas de reprodução." },
  { id: 80, frase: "Esculpir peças artísticas em madeira." },
  { id: 81, frase: "Entrevistar políticos para um trabalho escolar." },
  { id: 82, frase: "Conhecer o funcionamento eletroquímico de pilhas e baterias." },
  { id: 83, frase: "Ler sobre o Direito Romano." },
  { id: 84, frase: "Estudar sobre as diversas vitaminas e suas funções." },
  { id: 85, frase: "Escrever um artigo sobre o hábito de leitura no Brasil." },
  { id: 86, frase: "Fazer um curso sobre técnica de pintura texturizada." },
  { id: 87, frase: "Estudar os princípios aerodinâmicas da asa-delta." },
  { id: 88, frase: "Conhecer documentos necessários para abrir uma empresa." },
  { id: 89, frase: "Acompanhar a administração de sua cidade." },
  { id: 90, frase: "Calcular os custos de um curso superior." },
  { id: 91, frase: "Atuar no controle de substâncias químicas prejudiciais à camada de ozônio." },
  { id: 92, frase: "Descobrir a fórmula de um novo produto para emagrecer." },
  { id: 93, frase: "Convencer os colegas sobre a melhor estratégia para sua equipe esportiva." },
  { id: 94, frase: "Utilizar aparelhos para medir a pressão atmosférica." },
  { id: 95, frase: "Construir uma página na internet." },
  { id: 96, frase: "Calcular custo e benefício de investir dinheiro em marketing." },
  { id: 97, frase: "Realizar exames psicológicos em criminosos." },
  { id: 98, frase: "Coletar provar que documentem um crime." },
  { id: 99, frase: "Transcrever uma mensagem em Código Morse." },
  { id: 100, frase: "Estudar formas de combater doenças infecto-contagiosas." },
  { id: 101, frase: "Conhecer o princípio mecânico da robótica industrial." },
  { id: 102, frase: "Pesquisar métodos químicos para provocar chuvas." },
  { id: 103, frase: "Acompanhar programas sociais em prol da diminuição da violência." },
  { id: 104, frase: "Fazer estágio em um escritório de investimentos financeiros." },
  { id: 105, frase: "Projetar um espaço ideal para um camping." },
  { id: 106, frase: "Divulgar novos produtos em anúncios de rádio." },
  { id: 107, frase: "Ser o orador de sua turma de formatura." },
  { id: 108, frase: "Conhecer bem as medicações homeopáticas." },
  { id: 109, frase: "Pesquisar dados para calcular o valor da cesta básica." },
  { id: 110, frase: "Catalogar livros por assunto." },
  { id: 111, frase: "Pesquisar tintas especiais para prevenir o ferrugem." },
  { id: 112, frase: "Pesquisar o grau de agressividade dos alunos de uma escola." },
  { id: 113, frase: "Conhecer os melhores softwares para controle de estoque." },
  { id: 114, frase: "Resumir um livro para um texto de duas páginas." },
  { id: 115, frase: "Fazer um curso sobre primeiros socorros." },
  { id: 116, frase: "Ajudar a projetar membros eletrônicos para pessoas amputadas." },
  { id: 117, frase: "Entrevistar um candidato a governador." },
  { id: 118, frase: "Conhecer programas para controle de folha de pagamento." },
  { id: 119, frase: "Cuidar dos interesses jurídicos de uma empresa." },
  { id: 120, frase: "Aprender a montar uma empresa com pouco investimento." },
  { id: 121, frase: "Participar do projeto de expansão de uma cidade." },
  { id: 122, frase: "Cuidar do tratamento químico da água." },
  { id: 123, frase: "Conhecer o funcionamento de uma máquina fotográfica digital." },
  { id: 124, frase: "Desenhar modelos de poltronas para uma sala de cinema." },
  { id: 125, frase: "Participar de uma campanha de vacinação contra a paralisia infantil." },
  { id: 126, frase: "Planejar a melhor distribuição de produtos em prateleiras de supermercados." },
  { id: 127, frase: "Orientar jovens na escolha da profissão." },
  { id: 128, frase: "Ser vocalista de uma banda." },
  { id: 129, frase: "Conhecer as leis para importação de produtos." },
  { id: 130, frase: "Visitar uma usina de energia nuclear." },
  { id: 131, frase: "Estudar o crescente índice de obesidade infantil." },
  { id: 132, frase: "Assistir ao debate para aprovar uma nova lei de trânsito." },
  { id: 133, frase: "Ler sobre a influência dos imigrantes na formação da sociedade." },
  { id: 134, frase: "Ajudar a montar o cenário de uma peça teatral." },
  { id: 135, frase: "Pesquisar sobre os efeitos do uso de anabolizantes em atletas." },
  { id: 136, frase: "Aprender linguas estrangeiras." },
  { id: 137, frase: "Organizar e distribuir tarefas entre grupos de escoteiros." },
  { id: 138, frase: "Entender o funcionamento do aparelho de ressonância magnética." },
  { id: 139, frase: "Calcular o custo de revenda de carros usados." },
  { id: 140, frase: "Fazer uma maquete para cenário de um filme." },
  { id: 141, frase: "Observar o tratamento físico-químico de águas de piscinas." },
  { id: 142, frase: "Fazer um curso sobre técnicas de vendas." },
  { id: 143, frase: "Ler artigos sobre os problemas de desemprego." },
  { id: 144, frase: "Coordenar um debate político." },
  { id: 145, frase: "Pesquisar a tradução dos hieróglifos escritos pelos egípcios." },
  { id: 146, frase: "Ler sobre instalações hidrossanitárias em projetos arquiteônicos." },
  { id: 147, frase: "Estampar camisetas com técnicas modernas." },
  { id: 148, frase: "Acompanhar sobre os projetos de leis sobre questões raciais." },
  { id: 149, frase: "Pesquisar sobre como reduzir o teor de gordura no sorvete." },
  { id: 150, frase: "Ler sobre os efeitos dos agrotóxicos na saúde do ser humano." },
  { id: 151, frase: "Avaliar o nível de tensão de policiais." },
  { id: 152, frase: "Ouvir comentaristas sobre crítica de cinema." },
  { id: 153, frase: "Propor melhorias na administração penitenciária." },
  { id: 154, frase: "Ler sobre o tratamento da depressão." },
  { id: 155, frase: "Acompanhar uma perícia social em denúncia de maus-tratos." },
  { id: 156, frase: "Estudar o processo de transformação do lixo em combustível." },
  { id: 157, frase: "Trabalhar na restauração de livros antigos." },
  { id: 158, frase: "Aprender um método de arquivo de documentos." },
  { id: 159, frase: "Acompanhar as descobertas sobre as cadeias de DNA." },
  { id: 160, frase: "Ajudar no tratamento de uma pessoa com fobia de elevador." },
  { id: 161, frase: "Planejar a divulgação de uma marca de roupas." },
  { id: 162, frase: "Solucionar a logística de distribuição de mercadorias." },
  { id: 163, frase: "Conhecer os riscos de alguns produtos na fabricação de perfumes." },
  { id: 164, frase: "Desenhar novos modelos de sapatos." },
  { id: 165, frase: "Remarcar preços levando em conta a inflação." },
  { id: 166, frase: "Estabelecer normas éticas para as eleições." },
  { id: 167, frase: "Conhecer os sistemas de robótica em uma indústria automotiva." },
  { id: 168, frase: "Orientar alunos com dificuldades de aprendizagem." },
  { id: 169, frase: "Fazer experiências em animais para testar medicamentos." },
  { id: 170, frase: "Pesquisar a fórmula de um novo filtro solar." },
  { id: 171, frase: "Calcular os custos para a reforma de uma casa." },
  { id: 172, frase: "Pesquisar os tipos de alergias provocadas por animais." },
  { id: 173, frase: "Defender suas idéias religiosas." },
  { id: 174, frase: "Escrever uma obra sobre curiosidades." },
  { id: 175, frase: "Orientar famílias no relacionamento com os filhos." },
  { id: 176, frase: "Confeccionar brinquedos em tecido e madeira." },
  { id: 177, frase: "Acompanhar a adaptação de crianças adotadas." },
  { id: 178, frase: "Calcular os custos de entrega de compras pela internet." },
  { id: 179, frase: "Conhecer a linguagem de programação de computadores." },
  { id: 180, frase: "Organizar o roteiro, transporte e hospedagem de uma excursão." },
  { id: 181, frase: "Aprender a tocar um instrumento musical." },
  { id: 182, frase: "Aplicar testes para avaliar níveis de memória em idosos." },
  { id: 183, frase: "Convidar pessoas importantes para inauguração de um hotel." },
  { id: 184, frase: "Levantar dados populacionais consultando pesquisas do IBGE." },
  { id: 185, frase: "Ler sobre os efeitos da radiotividade." },
  { id: 186, frase: "Aprender técnicas para convencer pessoas a comprar produtos." },
  { id: 187, frase: "Conhecer substâncias químicas que causam dependência." },
  { id: 188, frase: "Participar de um concurso de histórias de terror." },
  { id: 189, frase: "Auxiliar na elaboração de normas para o recolhimento de menores infratores." },
  { id: 190, frase: "Fazer um curso sobre adestramento de cães." },
  { id: 191, frase: "Assistir a uma palestra sobre publicidade e propaganda." },
  { id: 192, frase: "Desenhar novos modelos de automóveis." },
  { id: 193, frase: "Fazer um curso de redação." },
  { id: 194, frase: "Ler sobre a interpretação dos sonhos." },
  { id: 195, frase: "Pesquisar combustíveis para diminuir a poluição." },
  { id: 196, frase: "Calcular o custo de vida em diferentes cidades." },
  { id: 197, frase: "Fazer um vídeo para promover a venda de apartamentos." },
  { id: 198, frase: "Orientar adolescentes na prevenção de gravidez." },
  { id: 199, frase: "Conhecer o processo de fabricação da cerveja." },
  { id: 200, frase: "Participar da elaboração das normas de um condomínio." },
]

export default aip;