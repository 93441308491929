
import "../../../assets/css/testes.css"

export default (props) => {
    return (

            <div>
                <div className="fimquest">
                    <p>FIM</p>
                </div>
            </div>
      );
    }