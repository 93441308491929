
import "../../../assets/css/testes.css"

export default (props) => {
    return (

            <div>
                <div className="inicioquest">
                    <p>INICIO</p>
                </div>
            </div>
      );
    }