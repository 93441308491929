import ClientNavbar from "../../shared/clientNavbar";
import IntroSection from '../../shared/introSection';
import Instructions from "./instructionsII";
import 'react-notifications/lib/notifications.css';

import Table from 'react-bootstrap/Table'
import Inicioquest from "../shared/inicioquest";
import Fimquest from "../shared/fimquest";
import { useForm } from "react-hook-form";
import Alert from "../shared/alert";
import React, { useState } from 'react';
import Notification from '../../testes/shared/notification';
import { Navigate } from "react-router-dom";
import { responder } from '../../../services/testeService';

export default function II() {
  const { register, handleSubmit, watch, formState: { errors } } = useForm();

  const [error401, setError] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const onSubmit = async data => {
    try {
      const response = await responder(5, data);
      setRedirect(true);
    }
    catch (error) {
      setError(true);
    }
  };

  return (
    <div>

      {error401 && <Notification titulo="Erro ao enviar esse formulario!" />}
      {redirect && <Navigate to="/testes" />}

      <ClientNavbar titulo="INVINT"> </ClientNavbar>

      <IntroSection>
        <Instructions></Instructions>
      </IntroSection>

      <Inicioquest></Inicioquest>

      <form className="formsize" onSubmit={handleSubmit(onSubmit)}>

        <Table striped>

          <thead><tr><th>1</th><th>{errors.q1a && errors.q1b && errors.q1c && <Alert titulo="Questão 1 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td> Estudar sobre invenções no campo da eletricidade.</td><td><input type="checkbox" name="q1a" {...register("q1a", { disabled: (watch("q1c") === true), required: (((watch("q1b") === false) || (watch("q1c") === false))) })} /></td></tr>
            <tr><td>B</td><td> Aprender sobre física nuclear.</td><td><input type="checkbox" name="q1b" {...register("q1b", { disabled: (watch("q1c") === true), required: (((watch("q1a") === false) || (watch("q1c") === false))) })} /></td></tr>
            <tr><td>C</td><td> Nenhuma das opções.</td><td><input type="checkbox" name="q1c" {...register("q1c", { disabled: ((watch("q1a") === true) || (watch("q1b") === true)), required: (((watch("q1a") === false) || (watch("q1b") === false))) })} /></td></tr></tbody> <br></br>
          <thead><tr><th>2</th><th>{errors.q2a && errors.q2b && errors.q2c && <Alert titulo="Questão 2 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td> Observar os planetas com um telescópio.</td><td><input type="checkbox" name="q2a" {...register("q2a", { disabled: (watch("q2c") === true), required: (((watch("q2b") === false) || (watch("q2c") === false))) })} /></td></tr>
            <tr><td>B</td><td> Observar as células com um microscópio.</td><td><input type="checkbox" name="q2b" {...register("q2b", { disabled: (watch("q2c") === true), required: (((watch("q2a") === false) || (watch("q2c") === false))) })} /></td></tr>
            <tr><td>C</td><td> Nenhuma das opções.</td><td><input type="checkbox" name="q2c" {...register("q2c", { disabled: ((watch("q2a") === true) || (watch("q2b") === true)), required: (((watch("q2a") === false) || (watch("q2b") === false))) })} /></td></tr></tbody> <br></br>
          <thead><tr><th>3</th><th>{errors.q3a && errors.q3b && errors.q3c && <Alert titulo="Questão 3 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td> Conhecer o processo de fabricação de tintas e vernizes.</td><td><input type="checkbox" name="q3a" {...register("q3a", { disabled: (watch("q3c") === true), required: (((watch("q3b") === false) || (watch("q3c") === false))) })} /></td></tr>
            <tr><td>B</td><td> Resolver problemas de cálculos matemáticos.</td><td><input type="checkbox" name="q3b" {...register("q3b", { disabled: (watch("q3c") === true), required: (((watch("q3a") === false) || (watch("q3c") === false))) })} /></td></tr>
            <tr><td>C</td><td> Nenhuma das opções.</td><td><input type="checkbox" name="q3c" {...register("q3c", { disabled: ((watch("q3a") === true) || (watch("q3b") === true)), required: (((watch("q3a") === false) || (watch("q3b") === false))) })} /></td></tr></tbody> <br></br>
          <thead><tr><th>4</th><th>{errors.q4a && errors.q4b && errors.q4c && <Alert titulo="Questão 4 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td> Visitar uma feira de aparelhos científicos.</td><td><input type="checkbox" name="q4a" {...register("q4a", { disabled: (watch("q4c") === true), required: (((watch("q4b") === false) || (watch("q4c") === false))) })} /></td></tr>
            <tr><td>B</td><td> Visitar uma feira de negócios.</td><td><input type="checkbox" name="q4b" {...register("q4b", { disabled: (watch("q4c") === true), required: (((watch("q4a") === false) || (watch("q4c") === false))) })} /></td></tr>
            <tr><td>C</td><td> Nenhuma das opções.</td><td><input type="checkbox" name="q4c" {...register("q4c", { disabled: ((watch("q4a") === true) || (watch("q4b") === true)), required: (((watch("q4a") === false) || (watch("q4b") === false))) })} /></td></tr></tbody> <br></br>
          <thead><tr><th>5</th><th>{errors.q5a && errors.q5b && errors.q5c && <Alert titulo="Questão 5 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td> Fazer experiências de química.</td><td><input type="checkbox" name="q5a" {...register("q5a", { disabled: (watch("q5c") === true), required: (((watch("q5b") === false) || (watch("q5c") === false))) })} /></td></tr>
            <tr><td>B</td><td> Organizar uma biblioteca.</td><td><input type="checkbox" name="q5b" {...register("q5b", { disabled: (watch("q5c") === true), required: (((watch("q5a") === false) || (watch("q5c") === false))) })} /></td></tr>
            <tr><td>C</td><td> Nenhuma das opções.</td><td><input type="checkbox" name="q5c" {...register("q5c", { disabled: ((watch("q5a") === true) || (watch("q5b") === true)), required: (((watch("q5a") === false) || (watch("q5b") === false))) })} /></td></tr></tbody> <br></br>
          <thead><tr><th>6</th><th>{errors.q6a && errors.q6b && errors.q6c && <Alert titulo="Questão 6 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td> Obter uma bolsa para estudar química ou física.</td><td><input type="checkbox" name="q6a" {...register("q6a", { disabled: (watch("q6c") === true), required: (((watch("q6b") === false) || (watch("q6c") === false))) })} /></td></tr>
            <tr><td>B</td><td> Obter uma bolsa para estudar métodos educacionais (de ensino).</td><td><input type="checkbox" name="q6b" {...register("q6b", { disabled: (watch("q6c") === true), required: (((watch("q6a") === false) || (watch("q6c") === false))) })} /></td></tr>
            <tr><td>C</td><td> Nenhuma das opções.</td><td><input type="checkbox" name="q6c" {...register("q6c", { disabled: ((watch("q6a") === true) || (watch("q6b") === true)), required: (((watch("q6a") === false) || (watch("q6b") === false))) })} /></td></tr></tbody> <br></br>
          <thead><tr><th>7</th><th>{errors.q7a && errors.q7b && errors.q7c && <Alert titulo="Questão 7 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td> Fazer um projeto de uma nova cidade.</td><td><input type="checkbox" name="q7a" {...register("q7a", { disabled: (watch("q7c") === true), required: (((watch("q7b") === false) || (watch("q7c") === false))) })} /></td></tr>
            <tr><td>B</td><td> Traduzir um livro.</td><td><input type="checkbox" name="q7b" {...register("q7b", { disabled: (watch("q7c") === true), required: (((watch("q7a") === false) || (watch("q7c") === false))) })} /></td></tr>
            <tr><td>C</td><td> Nenhuma das opções.</td><td><input type="checkbox" name="q7c" {...register("q7c", { disabled: ((watch("q7a") === true) || (watch("q7b") === true)), required: (((watch("q7a") === false) || (watch("q7b") === false))) })} /></td></tr></tbody> <br></br>
          <thead><tr><th>8</th><th>{errors.q8a && errors.q8b && errors.q8c && <Alert titulo="Questão 8 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td> Fazer experiências em um laboratório de física.</td><td><input type="checkbox" name="q8a" {...register("q8a", { disabled: (watch("q8c") === true), required: (((watch("q8b") === false) || (watch("q8c") === false))) })} /></td></tr>
            <tr><td>B</td><td> Fazer modelagem com argila</td><td><input type="checkbox" name="q8b" {...register("q8b", { disabled: (watch("q8c") === true), required: (((watch("q8a") === false) || (watch("q8c") === false))) })} /></td></tr>
            <tr><td>C</td><td> Nenhuma das opções.</td><td><input type="checkbox" name="q8c" {...register("q8c", { disabled: ((watch("q8a") === true) || (watch("q8b") === true)), required: (((watch("q8a") === false) || (watch("q8b") === false))) })} /></td></tr></tbody> <br></br>
          <thead><tr><th>9</th><th>{errors.q9a && errors.q9b && errors.q9c && <Alert titulo="Questão 9 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td> Idealizar um novo sistema de refrigeração.</td><td><input type="checkbox" name="q9a" {...register("q9a", { disabled: (watch("q9c") === true), required: (((watch("q9b") === false) || (watch("q9c") === false))) })} /></td></tr>
            <tr><td>B</td><td> Compor uma música.</td><td><input type="checkbox" name="q9b" {...register("q9b", { disabled: (watch("q9c") === true), required: (((watch("q9a") === false) || (watch("q9c") === false))) })} /></td></tr>
            <tr><td>C</td><td> Nenhuma das opções.</td><td><input type="checkbox" name="q9c" {...register("q9c", { disabled: ((watch("q9a") === true) || (watch("q9b") === true)), required: (((watch("q9a") === false) || (watch("q9b") === false))) })} /></td></tr></tbody> <br></br>
          <thead><tr><th>10</th><th>{errors.q10a && errors.q10b && errors.q10c && <Alert titulo="Questão 10 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td> Estudar os ossos e músculos do corpo humano.</td><td><input type="checkbox" name="q10a" {...register("q10a", { disabled: (watch("q10c") === true), required: (((watch("q10b") === false) || (watch("q10c") === false))) })} /></td></tr>
            <tr><td>B</td><td> Conhecer o funcionamento de uma máquina.</td><td><input type="checkbox" name="q10b" {...register("q10b", { disabled: (watch("q10c") === true), required: (((watch("q10a") === false) || (watch("q10c") === false))) })} /></td></tr>
            <tr><td>C</td><td> Nenhuma das opções.</td><td><input type="checkbox" name="q10c" {...register("q10c", { disabled: ((watch("q10a") === true) || (watch("q10b") === true)), required: (((watch("q10a") === false) || (watch("q10b") === false))) })} /></td></tr></tbody> <br></br>
          <thead><tr><th>11</th><th>{errors.q11a && errors.q11b && errors.q11c && <Alert titulo="Questão 11 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td> Visitar um laboratório de microbiologia.</td><td><input type="checkbox" name="q11a" {...register("q11a", { disabled: (watch("q11c") === true), required: (((watch("q11b") === false) || (watch("q11c") === false))) })} /></td></tr>
            <tr><td>B</td><td> Visitar um laboratório farmacêutico.</td><td><input type="checkbox" name="q11b" {...register("q11b", { disabled: (watch("q11c") === true), required: (((watch("q11a") === false) || (watch("q11c") === false))) })} /></td></tr>
            <tr><td>C</td><td> Nenhuma das opções.</td><td><input type="checkbox" name="q11c" {...register("q11c", { disabled: ((watch("q11a") === true) || (watch("q11b") === true)), required: (((watch("q11a") === false) || (watch("q11b") === false))) })} /></td></tr></tbody> <br></br>
          <thead><tr><th>12</th><th>{errors.q12a && errors.q12b && errors.q12c && <Alert titulo="Questão 12 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td> Estudar a respiração dos peixes.</td><td><input type="checkbox" name="q12a" {...register("q12a", { disabled: (watch("q12c") === true), required: (((watch("q12b") === false) || (watch("q12c") === false))) })} /></td></tr>
            <tr><td>B</td><td> Aprender a trabalhar com uma calculadora.</td><td><input type="checkbox" name="q12b" {...register("q12b", { disabled: (watch("q12c") === true), required: (((watch("q12a") === false) || (watch("q12c") === false))) })} /></td></tr>
            <tr><td>C</td><td> Nenhuma das opções.</td><td><input type="checkbox" name="q12c" {...register("q12c", { disabled: ((watch("q12a") === true) || (watch("q12b") === true)), required: (((watch("q12a") === false) || (watch("q12b") === false))) })} /></td></tr></tbody> <br></br>
          <thead><tr><th>13</th><th>{errors.q13a && errors.q13b && errors.q13c && <Alert titulo="Questão 13 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td> Estudar sobre as doenças tropicais.</td><td><input type="checkbox" name="q13a" {...register("q13a", { disabled: (watch("q13c") === true), required: (((watch("q13b") === false) || (watch("q13c") === false))) })} /></td></tr>
            <tr><td>B</td><td> Ler sobre como persuadir (convencer) as pessoas.</td><td><input type="checkbox" name="q13b" {...register("q13b", { disabled: (watch("q13c") === true), required: (((watch("q13a") === false) || (watch("q13c") === false))) })} /></td></tr>
            <tr><td>C</td><td> Nenhuma das opções.</td><td><input type="checkbox" name="q13c" {...register("q13c", { disabled: ((watch("q13a") === true) || (watch("q13b") === true)), required: (((watch("q13a") === false) || (watch("q13b") === false))) })} /></td></tr></tbody> <br></br>
          <thead><tr><th>14</th><th>{errors.q14a && errors.q14b && errors.q14c && <Alert titulo="Questão 14 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td> Fazer a classificação de animais e plantas.</td><td><input type="checkbox" name="q14a" {...register("q14a", { disabled: (watch("q14c") === true), required: (((watch("q14b") === false) || (watch("q14c") === false))) })} /></td></tr>
            <tr><td>B</td><td> Organizar um arquivo de documentos.</td><td><input type="checkbox" name="q14b" {...register("q14b", { disabled: (watch("q14c") === true), required: (((watch("q14a") === false) || (watch("q14c") === false))) })} /></td></tr>
            <tr><td>C</td><td> Nenhuma das opções.</td><td><input type="checkbox" name="q14c" {...register("q14c", { disabled: ((watch("q14a") === true) || (watch("q14b") === true)), required: (((watch("q14a") === false) || (watch("q14b") === false))) })} /></td></tr></tbody> <br></br>
          <thead><tr><th>15</th><th>{errors.q15a && errors.q15b && errors.q15c && <Alert titulo="Questão 15 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td> Estudar as propriedades alimentícias das frutas.</td><td><input type="checkbox" name="q15a" {...register("q15a", { disabled: (watch("q15c") === true), required: (((watch("q15b") === false) || (watch("q15c") === false))) })} /></td></tr>
            <tr><td>B</td><td> Estudar sobre os problemas sociais.</td><td><input type="checkbox" name="q15b" {...register("q15b", { disabled: (watch("q15c") === true), required: (((watch("q15a") === false) || (watch("q15c") === false))) })} /></td></tr>
            <tr><td>C</td><td> Nenhuma das opções.</td><td><input type="checkbox" name="q15c" {...register("q15c", { disabled: ((watch("q15a") === true) || (watch("q15b") === true)), required: (((watch("q15a") === false) || (watch("q15b") === false))) })} /></td></tr></tbody> <br></br>
          <thead><tr><th>16</th><th>{errors.q16a && errors.q16b && errors.q16c && <Alert titulo="Questão 16 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td> Ler sobre o comportamanto dos pássaros.</td><td><input type="checkbox" name="q16a" {...register("q16a", { disabled: (watch("q16c") === true), required: (((watch("q16b") === false) || (watch("q16c") === false))) })} /></td></tr>
            <tr><td>B</td><td> Ler sobre história e literatura.</td><td><input type="checkbox" name="q16b" {...register("q16b", { disabled: (watch("q16c") === true), required: (((watch("q16a") === false) || (watch("q16c") === false))) })} /></td></tr>
            <tr><td>C</td><td> Nenhuma das opções.</td><td><input type="checkbox" name="q16c" {...register("q16c", { disabled: ((watch("q16a") === true) || (watch("q16b") === true)), required: (((watch("q16a") === false) || (watch("q16b") === false))) })} /></td></tr></tbody> <br></br>
          <thead><tr><th>17</th><th>{errors.q17a && errors.q17b && errors.q17c && <Alert titulo="Questão 17 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td> Estudar sobre o crescimento das plantas.</td><td><input type="checkbox" name="q17a" {...register("q17a", { disabled: (watch("q17c") === true), required: (((watch("q17b") === false) || (watch("q17c") === false))) })} /></td></tr>
            <tr><td>B</td><td> Fazer um projeto de paisagismo para uma casa.</td><td><input type="checkbox" name="q17b" {...register("q17b", { disabled: (watch("q17c") === true), required: (((watch("q17a") === false) || (watch("q17c") === false))) })} /></td></tr>
            <tr><td>C</td><td> Nenhuma das opções.</td><td><input type="checkbox" name="q17c" {...register("q17c", { disabled: ((watch("q17a") === true) || (watch("q17b") === true)), required: (((watch("q17a") === false) || (watch("q17b") === false))) })} /></td></tr></tbody> <br></br>
          <thead><tr><th>18</th><th>{errors.q18a && errors.q18b && errors.q18c && <Alert titulo="Questão 18 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td> Estudar a vida e obra Pasteur (químico e fundador da microbiologia).</td><td><input type="checkbox" name="q18a" {...register("q18a", { disabled: (watch("q18c") === true), required: (((watch("q18b") === false) || (watch("q18c") === false))) })} /></td></tr>
            <tr><td>B</td><td> Estudar a vida e obra de Chopin (pianista e compositor).</td><td><input type="checkbox" name="q18b" {...register("q18b", { disabled: (watch("q18c") === true), required: (((watch("q18a") === false) || (watch("q18c") === false))) })} /></td></tr>
            <tr><td>C</td><td> Nenhuma das opções.</td><td><input type="checkbox" name="q18c" {...register("q18c", { disabled: ((watch("q18a") === true) || (watch("q18b") === true)), required: (((watch("q18a") === false) || (watch("q18b") === false))) })} /></td></tr></tbody> <br></br>
          <thead><tr><th>19</th><th>{errors.q19a && errors.q19b && errors.q19c && <Alert titulo="Questão 19 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td> Resolver equações matemáticas.</td><td><input type="checkbox" name="q19a" {...register("q19a", { disabled: (watch("q19c") === true), required: (((watch("q19b") === false) || (watch("q19c") === false))) })} /></td></tr>
            <tr><td>B</td><td> Montar quebra-cabeças mecânicos.</td><td><input type="checkbox" name="q19b" {...register("q19b", { disabled: (watch("q19c") === true), required: (((watch("q19a") === false) || (watch("q19c") === false))) })} /></td></tr>
            <tr><td>C</td><td> Nenhuma das opções.</td><td><input type="checkbox" name="q19c" {...register("q19c", { disabled: ((watch("q19a") === true) || (watch("q19b") === true)), required: (((watch("q19a") === false) || (watch("q19b") === false))) })} /></td></tr></tbody> <br></br>
          <thead><tr><th>20</th><th>{errors.q20a && errors.q20b && errors.q20c && <Alert titulo="Questão 20 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td> Calcular os custos de produção de uma empresa.</td><td><input type="checkbox" name="q20a" {...register("q20a", { disabled: (watch("q20c") === true), required: (((watch("q20b") === false) || (watch("q20c") === false))) })} /></td></tr>
            <tr><td>B</td><td> Pesquisar a cura do câncer.</td><td><input type="checkbox" name="q20b" {...register("q20b", { disabled: (watch("q20c") === true), required: (((watch("q20a") === false) || (watch("q20c") === false))) })} /></td></tr>
            <tr><td>C</td><td> Nenhuma das opções.</td><td><input type="checkbox" name="q20c" {...register("q20c", { disabled: ((watch("q20a") === true) || (watch("q20b") === true)), required: (((watch("q20a") === false) || (watch("q20b") === false))) })} /></td></tr></tbody> <br></br>
          <thead><tr><th>21</th><th>{errors.q21a && errors.q21b && errors.q21c && <Alert titulo="Questão 21 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td> Fazer os cálculos para apuração das eleições.</td><td><input type="checkbox" name="q21a" {...register("q21a", { disabled: (watch("q21c") === true), required: (((watch("q21b") === false) || (watch("q21c") === false))) })} /></td></tr>
            <tr><td>B</td><td> Fazer as estatísticas da distribuição de renda. </td><td><input type="checkbox" name="q21b" {...register("q21b", { disabled: (watch("q21c") === true), required: (((watch("q21a") === false) || (watch("q21c") === false))) })} /></td></tr>
            <tr><td>C</td><td> Nenhuma das opções.</td><td><input type="checkbox" name="q21c" {...register("q21c", { disabled: ((watch("q21a") === true) || (watch("q21b") === true)), required: (((watch("q21a") === false) || (watch("q21b") === false))) })} /></td></tr></tbody> <br></br>
          <thead><tr><th>22</th><th>{errors.q22a && errors.q22b && errors.q22c && <Alert titulo="Questão 22 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td> Fazer a contabilidade de uma empresa.</td><td><input type="checkbox" name="q22a" {...register("q22a", { disabled: (watch("q22c") === true), required: (((watch("q22b") === false) || (watch("q22c") === false))) })} /></td></tr>
            <tr><td>B</td><td> Vender consórcios ou seguros.</td><td><input type="checkbox" name="q22b" {...register("q22b", { disabled: (watch("q22c") === true), required: (((watch("q22a") === false) || (watch("q22c") === false))) })} /></td></tr>
            <tr><td>C</td><td> Nenhuma das opções.</td><td><input type="checkbox" name="q22c" {...register("q22c", { disabled: ((watch("q22a") === true) || (watch("q22b") === true)), required: (((watch("q22a") === false) || (watch("q22b") === false))) })} /></td></tr></tbody> <br></br>
          <thead><tr><th>23</th><th>{errors.q23a && errors.q23b && errors.q23c && <Alert titulo="Questão 23 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td> Ser Tesoureiro de um Banco</td><td><input type="checkbox" name="q23a" {...register("q23a", { disabled: (watch("q23c") === true), required: (((watch("q23b") === false) || (watch("q23c") === false))) })} /></td></tr>
            <tr><td>B</td><td> Trabalhar no escritório de uma empresa.</td><td><input type="checkbox" name="q23b" {...register("q23b", { disabled: (watch("q23c") === true), required: (((watch("q23a") === false) || (watch("q23c") === false))) })} /></td></tr>
            <tr><td>C</td><td> Nenhuma das opções.</td><td><input type="checkbox" name="q23c" {...register("q23c", { disabled: ((watch("q23a") === true) || (watch("q23b") === true)), required: (((watch("q23a") === false) || (watch("q23b") === false))) })} /></td></tr></tbody> <br></br>
          <thead><tr><th>24</th><th>{errors.q24a && errors.q24b && errors.q24c && <Alert titulo="Questão 24 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td> Calcular o aumento do custo de vida.</td><td><input type="checkbox" name="q24a" {...register("q24a", { disabled: (watch("q24c") === true), required: (((watch("q24b") === false) || (watch("q24c") === false))) })} /></td></tr>
            <tr><td>B</td><td> Estudar as condições sociais de uma comunidade.</td><td><input type="checkbox" name="q24b" {...register("q24b", { disabled: (watch("q24c") === true), required: (((watch("q24a") === false) || (watch("q24c") === false))) })} /></td></tr>
            <tr><td>C</td><td> Nenhuma das opções.</td><td><input type="checkbox" name="q24c" {...register("q24c", { disabled: ((watch("q24a") === true) || (watch("q24b") === true)), required: (((watch("q24a") === false) || (watch("q24b") === false))) })} /></td></tr></tbody> <br></br>
          <thead><tr><th>25</th><th>{errors.q25a && errors.q25b && errors.q25c && <Alert titulo="Questão 25 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td> Escrever um livro de matemática.</td><td><input type="checkbox" name="q25a" {...register("q25a", { disabled: (watch("q25c") === true), required: (((watch("q25b") === false) || (watch("q25c") === false))) })} /></td></tr>
            <tr><td>B</td><td> Escrever o roteiro de uma novela.</td><td><input type="checkbox" name="q25b" {...register("q25b", { disabled: (watch("q25c") === true), required: (((watch("q25a") === false) || (watch("q25c") === false))) })} /></td></tr>
            <tr><td>C</td><td> Nenhuma das opções.</td><td><input type="checkbox" name="q25c" {...register("q25c", { disabled: ((watch("q25a") === true) || (watch("q25b") === true)), required: (((watch("q25a") === false) || (watch("q25b") === false))) })} /></td></tr></tbody> <br></br>
          <thead><tr><th>26</th><th>{errors.q26a && errors.q26b && errors.q26c && <Alert titulo="Questão 26 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td> Trabalhar com uma calculadora.</td><td><input type="checkbox" name="q26a" {...register("q26a", { disabled: (watch("q26c") === true), required: (((watch("q26b") === false) || (watch("q26c") === false))) })} /></td></tr>
            <tr><td>B</td><td> Fazer projetos residenciais.</td><td><input type="checkbox" name="q26b" {...register("q26b", { disabled: (watch("q26c") === true), required: (((watch("q26a") === false) || (watch("q26c") === false))) })} /></td></tr>
            <tr><td>C</td><td> Nenhuma das opções.</td><td><input type="checkbox" name="q26c" {...register("q26c", { disabled: ((watch("q26a") === true) || (watch("q26b") === true)), required: (((watch("q26a") === false) || (watch("q26b") === false))) })} /></td></tr></tbody> <br></br>
          <thead><tr><th>27</th><th>{errors.q27a && errors.q27b && errors.q27c && <Alert titulo="Questão 27 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td> Fazer os cálculos das despesas de uma empresa.</td><td><input type="checkbox" name="q27a" {...register("q27a", { disabled: (watch("q27c") === true), required: (((watch("q27b") === false) || (watch("q27c") === false))) })} /></td></tr>
            <tr><td>B</td><td> Ser um crítico musical.</td><td><input type="checkbox" name="q27b" {...register("q27b", { disabled: (watch("q27c") === true), required: (((watch("q27a") === false) || (watch("q27c") === false))) })} /></td></tr>
            <tr><td>C</td><td> Nenhuma das opções.</td><td><input type="checkbox" name="q27c" {...register("q27c", { disabled: ((watch("q27a") === true) || (watch("q27b") === true)), required: (((watch("q27a") === false) || (watch("q27b") === false))) })} /></td></tr></tbody> <br></br>
          <thead><tr><th>28</th><th>{errors.q28a && errors.q28b && errors.q28c && <Alert titulo="Questão 28 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td>Estudar estratégias de vendas das grandes marcas.</td><td><input type="checkbox" name="q28a" {...register("q28a", { disabled: (watch("q28c") === true), required: (((watch("q28b") === false) || (watch("q28c") === false))) })} /></td></tr>
            <tr><td>B</td><td> Desenvolver a fórmula de um medicamento.</td><td><input type="checkbox" name="q28b" {...register("q28b", { disabled: (watch("q28c") === true), required: (((watch("q28a") === false) || (watch("q28c") === false))) })} /></td></tr>
            <tr><td>C</td><td> Nenhuma das opções.</td><td><input type="checkbox" name="q28c" {...register("q28c", { disabled: ((watch("q28a") === true) || (watch("q28b") === true)), required: (((watch("q28a") === false) || (watch("q28b") === false))) })} /></td></tr></tbody> <br></br>
          <thead><tr><th>29</th><th>{errors.q29a && errors.q29b && errors.q29c && <Alert titulo="Questão 29 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td> Assistir a uma sessão de Juri.</td><td><input type="checkbox" name="q29a" {...register("q29a", { disabled: (watch("q29c") === true), required: (((watch("q29b") === false) || (watch("q29c") === false))) })} /></td></tr>
            <tr><td>B</td><td> Assistir a uma cirurgia.</td><td><input type="checkbox" name="q29b" {...register("q29b", { disabled: (watch("q29c") === true), required: (((watch("q29a") === false) || (watch("q29c") === false))) })} /></td></tr>
            <tr><td>C</td><td> Nenhuma das opções.</td><td><input type="checkbox" name="q29c" {...register("q29c", { disabled: ((watch("q29a") === true) || (watch("q29b") === true)), required: (((watch("q29a") === false) || (watch("q29b") === false))) })} /></td></tr></tbody> <br></br>
          <thead><tr><th>30</th><th>{errors.q30a && errors.q30b && errors.q30c && <Alert titulo="Questão 30 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td> Estudar novos métodos de propaganda.</td><td><input type="checkbox" name="q30a" {...register("q30a", { disabled: (watch("q30c") === true), required: (((watch("q30b") === false) || (watch("q30c") === false))) })} /></td></tr>
            <tr><td>B</td><td> Calcular as estatísticas da imigração no Brasil.</td><td><input type="checkbox" name="q30b" {...register("q30b", { disabled: (watch("q30c") === true), required: (((watch("q30a") === false) || (watch("q30c") === false))) })} /></td></tr>
            <tr><td>C</td><td> Nenhuma das opções.</td><td><input type="checkbox" name="q30c" {...register("q30c", { disabled: ((watch("q30a") === true) || (watch("q30b") === true)), required: (((watch("q30a") === false) || (watch("q30b") === false))) })} /></td></tr></tbody> <br></br>
          <thead><tr><th>31</th><th>{errors.q31a && errors.q31b && errors.q31c && <Alert titulo="Questão 31 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td> Fazer um discurso de apoio a uma idéia.</td><td><input type="checkbox" name="q31a" {...register("q31a", { disabled: (watch("q31c") === true), required: (((watch("q31b") === false) || (watch("q31c") === false))) })} /></td></tr>
            <tr><td>B</td><td> Defender os direitos de um operário.</td><td><input type="checkbox" name="q31b" {...register("q31b", { disabled: (watch("q31c") === true), required: (((watch("q31a") === false) || (watch("q31c") === false))) })} /></td></tr>
            <tr><td>C</td><td> Nenhuma das opções.</td><td><input type="checkbox" name="q31c" {...register("q31c", { disabled: ((watch("q31a") === true) || (watch("q31b") === true)), required: (((watch("q31a") === false) || (watch("q31b") === false))) })} /></td></tr></tbody> <br></br>
          <thead><tr><th>32</th><th>{errors.q32a && errors.q32b && errors.q32c && <Alert titulo="Questão 32 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td> Conhecer os métodos de trabalho de uma agência publicitária.</td><td><input type="checkbox" name="q32a" {...register("q32a", { disabled: (watch("q32c") === true), required: (((watch("q32b") === false) || (watch("q32c") === false))) })} /></td></tr>
            <tr><td>B</td><td> Organizar o arquivo de clientes desta agência.</td><td><input type="checkbox" name="q32b" {...register("q32b", { disabled: (watch("q32c") === true), required: (((watch("q32a") === false) || (watch("q32c") === false))) })} /></td></tr>
            <tr><td>C</td><td> Nenhuma das opções.</td><td><input type="checkbox" name="q32c" {...register("q32c", { disabled: ((watch("q32a") === true) || (watch("q32b") === true)), required: (((watch("q32a") === false) || (watch("q32b") === false))) })} /></td></tr></tbody> <br></br>
          <thead><tr><th>33</th><th>{errors.q33a && errors.q33b && errors.q33c && <Alert titulo="Questão 33 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td> Participar de uma campanha publicitária.</td><td><input type="checkbox" name="q33a" {...register("q33a", { disabled: (watch("q33c") === true), required: (((watch("q33b") === false) || (watch("q33c") === false))) })} /></td></tr>
            <tr><td>B</td><td> Ajudar na readaptação de delinquentes.</td><td><input type="checkbox" name="q33b" {...register("q33b", { disabled: (watch("q33c") === true), required: (((watch("q33a") === false) || (watch("q33c") === false))) })} /></td></tr>
            <tr><td>C</td><td> Nenhuma das opções.</td><td><input type="checkbox" name="q33c" {...register("q33c", { disabled: ((watch("q33a") === true) || (watch("q33b") === true)), required: (((watch("q33a") === false) || (watch("q33b") === false))) })} /></td></tr></tbody> <br></br>
          <thead><tr><th>34</th><th>{errors.q34a && errors.q34b && errors.q34c && <Alert titulo="Questão 34 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td> Negociar anúncios para o jornal do colégio.</td><td><input type="checkbox" name="q34a" {...register("q34a", { disabled: (watch("q34c") === true), required: (((watch("q34b") === false) || (watch("q34c") === false))) })} /></td></tr>
            <tr><td>B</td><td> Ser o Redator desse Jornal.</td><td><input type="checkbox" name="q34b" {...register("q34b", { disabled: (watch("q34c") === true), required: (((watch("q34a") === false) || (watch("q34c") === false))) })} /></td></tr>
            <tr><td>C</td><td> Nenhuma das opções.</td><td><input type="checkbox" name="q34c" {...register("q34c", { disabled: ((watch("q34a") === true) || (watch("q34b") === true)), required: (((watch("q34a") === false) || (watch("q34b") === false))) })} /></td></tr></tbody> <br></br>
          <thead><tr><th>35</th><th>{errors.q35a && errors.q35b && errors.q35c && <Alert titulo="Questão 35 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td> Visitar o Palácio da Justiça.</td><td><input type="checkbox" name="q35a" {...register("q35a", { disabled: (watch("q35c") === true), required: (((watch("q35b") === false) || (watch("q35c") === false))) })} /></td></tr>
            <tr><td>B</td><td> Visitar um Museu de Artes.</td><td><input type="checkbox" name="q35b" {...register("q35b", { disabled: (watch("q35c") === true), required: (((watch("q35a") === false) || (watch("q35c") === false))) })} /></td></tr>
            <tr><td>C</td><td> Nenhuma das opções.</td><td><input type="checkbox" name="q35c" {...register("q35c", { disabled: ((watch("q35a") === true) || (watch("q35b") === true)), required: (((watch("q35a") === false) || (watch("q35b") === false))) })} /></td></tr></tbody> <br></br>
          <thead><tr><th>36</th><th>{errors.q36a && errors.q36b && errors.q36c && <Alert titulo="Questão 36 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td> Ser líder de turma.</td><td><input type="checkbox" name="q36a" {...register("q36a", { disabled: (watch("q36c") === true), required: (((watch("q36b") === false) || (watch("q36c") === false))) })} /></td></tr>
            <tr><td>B</td><td> Organizar uma Banda no colégio.</td><td><input type="checkbox" name="q36b" {...register("q36b", { disabled: (watch("q36c") === true), required: (((watch("q36a") === false) || (watch("q36c") === false))) })} /></td></tr>
            <tr><td>C</td><td> Nenhuma das opções.</td><td><input type="checkbox" name="q36c" {...register("q36c", { disabled: ((watch("q36a") === true) || (watch("q36b") === true)), required: (((watch("q36a") === false) || (watch("q36b") === false))) })} /></td></tr></tbody> <br></br>
          <thead><tr><th>37</th><th>{errors.q37a && errors.q37b && errors.q37c && <Alert titulo="Questão 37 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td> Aprender escrituração contábil e fiscal (espécie de declaração de impostos).</td><td><input type="checkbox" name="q37a" {...register("q37a", { disabled: (watch("q37c") === true), required: (((watch("q37b") === false) || (watch("q37c") === false))) })} /></td></tr>
            <tr><td>B</td><td> Conhecer os mecanismos de um míssil.</td><td><input type="checkbox" name="q37b" {...register("q37b", { disabled: (watch("q37c") === true), required: (((watch("q37a") === false) || (watch("q37c") === false))) })} /></td></tr>
            <tr><td>C</td><td> Nenhuma das opções.</td><td><input type="checkbox" name="q37c" {...register("q37c", { disabled: ((watch("q37a") === true) || (watch("q37b") === true)), required: (((watch("q37a") === false) || (watch("q37b") === false))) })} /></td></tr></tbody> <br></br>
          <thead><tr><th>38</th><th>{errors.q38a && errors.q38b && errors.q38c && <Alert titulo="Questão 38 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td> Conhecer o funcionamento dos serviços públicos.</td><td><input type="checkbox" name="q38a" {...register("q38a", { disabled: (watch("q38c") === true), required: (((watch("q38b") === false) || (watch("q38c") === false))) })} /></td></tr>
            <tr><td>B</td><td> Assistir uma aula sobre plantas medicinais.</td><td><input type="checkbox" name="q38b" {...register("q38b", { disabled: (watch("q38c") === true), required: (((watch("q38a") === false) || (watch("q38c") === false))) })} /></td></tr>
            <tr><td>C</td><td> Nenhuma das opções.</td><td><input type="checkbox" name="q38c" {...register("q38c", { disabled: ((watch("q38a") === true) || (watch("q38b") === true)), required: (((watch("q38a") === false) || (watch("q38b") === false))) })} /></td></tr></tbody> <br></br>
          <thead><tr><th>39</th><th>{errors.q39a && errors.q39b && errors.q39c && <Alert titulo="Questão 39 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td> Conferir a entrada e saída de mercadorias de uma empresa.</td><td><input type="checkbox" name="q39a" {...register("q39a", { disabled: (watch("q39c") === true), required: (((watch("q39b") === false) || (watch("q39c") === false))) })} /></td></tr>
            <tr><td>B</td><td> Calcular a margem de lucro de um produto.</td><td><input type="checkbox" name="q39b" {...register("q39b", { disabled: (watch("q39c") === true), required: (((watch("q39a") === false) || (watch("q39c") === false))) })} /></td></tr>
            <tr><td>C</td><td> Nenhuma das opções.</td><td><input type="checkbox" name="q39c" {...register("q39c", { disabled: ((watch("q39a") === true) || (watch("q39b") === true)), required: (((watch("q39a") === false) || (watch("q39b") === false))) })} /></td></tr></tbody> <br></br>
          <thead><tr><th>40</th><th>{errors.q40a && errors.q40b && errors.q40c && <Alert titulo="Questão 40 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td> Trabalhar como Bibliotecário.</td><td><input type="checkbox" name="q40a" {...register("q40a", { disabled: (watch("q40c") === true), required: (((watch("q40b") === false) || (watch("q40c") === false))) })} /></td></tr>
            <tr><td>B</td><td> Ser locutor de rádio.</td><td><input type="checkbox" name="q40b" {...register("q40b", { disabled: (watch("q40c") === true), required: (((watch("q40a") === false) || (watch("q40c") === false))) })} /></td></tr>
            <tr><td>C</td><td> Nenhuma das opções.</td><td><input type="checkbox" name="q40c" {...register("q40c", { disabled: ((watch("q40a") === true) || (watch("q40b") === true)), required: (((watch("q40a") === false) || (watch("q40b") === false))) })} /></td></tr></tbody> <br></br>
          <thead><tr><th>41</th><th>{errors.q41a && errors.q41b && errors.q41c && <Alert titulo="Questão 41 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td> Procurar documentos em um arquivo.</td><td><input type="checkbox" name="q41a" {...register("q41a", { disabled: (watch("q41c") === true), required: (((watch("q41b") === false) || (watch("q41c") === false))) })} /></td></tr>
            <tr><td>B</td><td> Organizar este arquivo em ordem alfabética.</td><td><input type="checkbox" name="q41b" {...register("q41b", { disabled: (watch("q41c") === true), required: (((watch("q41a") === false) || (watch("q41c") === false))) })} /></td></tr>
            <tr><td>C</td><td> Nenhuma das opções.</td><td><input type="checkbox" name="q41c" {...register("q41c", { disabled: ((watch("q41a") === true) || (watch("q41b") === true)), required: (((watch("q41a") === false) || (watch("q41b") === false))) })} /></td></tr></tbody> <br></br>
          <thead><tr><th>42</th><th>{errors.q42a && errors.q42b && errors.q42c && <Alert titulo="Questão 42 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td> Estudar a distribuição de funções em um escritório.</td><td><input type="checkbox" name="q42a" {...register("q42a", { disabled: (watch("q42c") === true), required: (((watch("q42b") === false) || (watch("q42c") === false))) })} /></td></tr>
            <tr><td>B</td><td> Orientar as pessoas na escolha da profissão.</td><td><input type="checkbox" name="q42b" {...register("q42b", { disabled: (watch("q42c") === true), required: (((watch("q42a") === false) || (watch("q42c") === false))) })} /></td></tr>
            <tr><td>C</td><td> Nenhuma das opções.</td><td><input type="checkbox" name="q42c" {...register("q42c", { disabled: ((watch("q42a") === true) || (watch("q42b") === true)), required: (((watch("q42a") === false) || (watch("q42b") === false))) })} /></td></tr></tbody> <br></br>
          <thead><tr><th>43</th><th>{errors.q43a && errors.q43b && errors.q43c && <Alert titulo="Questão 43 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td>  Organizar a lista de convidados de uma cerimônia.</td><td><input type="checkbox" name="q43a" {...register("q43a", { disabled: (watch("q43c") === true), required: (((watch("q43b") === false) || (watch("q43c") === false))) })} /></td></tr>
            <tr><td>B</td><td> Ser o revisor de um livro.</td><td><input type="checkbox" name="q43b" {...register("q43b", { disabled: (watch("q43c") === true), required: (((watch("q43a") === false) || (watch("q43c") === false))) })} /></td></tr>
            <tr><td>C</td><td> Nenhuma das opções.</td><td><input type="checkbox" name="q43c" {...register("q43c", { disabled: ((watch("q43a") === true) || (watch("q43b") === true)), required: (((watch("q43a") === false) || (watch("q43b") === false))) })} /></td></tr></tbody> <br></br>
          <thead><tr><th>44</th><th>{errors.q44a && errors.q44b && errors.q44c && <Alert titulo="Questão 44 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td> Fazer a folha de pagamento de uma empresa.</td><td><input type="checkbox" name="q44a" {...register("q44a", { disabled: (watch("q44c") === true), required: (((watch("q44b") === false) || (watch("q44c") === false))) })} /></td></tr>
            <tr><td>B</td><td> Desenhar esculturas.</td><td><input type="checkbox" name="q44b" {...register("q44b", { disabled: (watch("q44c") === true), required: (((watch("q44a") === false) || (watch("q44c") === false))) })} /></td></tr>
            <tr><td>C</td><td> Nenhuma das opções.</td><td><input type="checkbox" name="q44c" {...register("q44c", { disabled: ((watch("q44a") === true) || (watch("q44b") === true)), required: (((watch("q44a") === false) || (watch("q44b") === false))) })} /></td></tr></tbody> <br></br>
          <thead><tr><th>45</th><th>{errors.q45a && errors.q45b && errors.q45c && <Alert titulo="Questão 45 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td> Digitar relatórios ou planilhas</td><td><input type="checkbox" name="q45a" {...register("q45a", { disabled: (watch("q45c") === true), required: (((watch("q45b") === false) || (watch("q45c") === false))) })} /></td></tr>
            <tr><td>B</td><td> Tocar piano.</td><td><input type="checkbox" name="q45b" {...register("q45b", { disabled: (watch("q45c") === true), required: (((watch("q45a") === false) || (watch("q45c") === false))) })} /></td></tr>
            <tr><td>C</td><td> Nenhuma das opções.</td><td><input type="checkbox" name="q45c" {...register("q45c", { disabled: ((watch("q45a") === true) || (watch("q45b") === true)), required: (((watch("q45a") === false) || (watch("q45b") === false))) })} /></td></tr></tbody> <br></br>
          <thead><tr><th>46</th><th>{errors.q46a && errors.q46b && errors.q46c && <Alert titulo="Questão 46 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td> Visitar um serviço de apoio às famílias de detentos.</td><td><input type="checkbox" name="q46a" {...register("q46a", { disabled: (watch("q46c") === true), required: (((watch("q46b") === false) || (watch("q46c") === false))) })} /></td></tr>
            <tr><td>B</td><td> Visitar um laboratório de física e/ou química.</td><td><input type="checkbox" name="q46b" {...register("q46b", { disabled: (watch("q46c") === true), required: (((watch("q46a") === false) || (watch("q46c") === false))) })} /></td></tr>
            <tr><td>C</td><td> Nenhuma das opções.</td><td><input type="checkbox" name="q46c" {...register("q46c", { disabled: ((watch("q46a") === true) || (watch("q46b") === true)), required: (((watch("q46a") === false) || (watch("q46b") === false))) })} /></td></tr></tbody> <br></br>
          <thead><tr><th>47</th><th>{errors.q47a && errors.q47b && errors.q47c && <Alert titulo="Questão 47 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td> Estudar as causas da delinquência.</td><td><input type="checkbox" name="q47a" {...register("q47a", { disabled: (watch("q47c") === true), required: (((watch("q47b") === false) || (watch("q47c") === false))) })} /></td></tr>
            <tr><td>B</td><td> Estudar o comportamento dos animais.</td><td><input type="checkbox" name="q47b" {...register("q47b", { disabled: (watch("q47c") === true), required: (((watch("q47a") === false) || (watch("q47c") === false))) })} /></td></tr>
            <tr><td>C</td><td> Nenhuma das opções.</td><td><input type="checkbox" name="q47c" {...register("q47c", { disabled: ((watch("q47a") === true) || (watch("q47b") === true)), required: (((watch("q47a") === false) || (watch("q47b") === false))) })} /></td></tr></tbody> <br></br>
          <thead><tr><th>48</th><th>{errors.q48a && errors.q48b && errors.q48c && <Alert titulo="Questão 48 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td> Fazer uma pesquisa sobre a educação dos filhos.</td><td><input type="checkbox" name="q48a" {...register("q48a", { disabled: (watch("q48c") === true), required: (((watch("q48b") === false) || (watch("q48c") === false))) })} /></td></tr>
            <tr><td>B</td><td> Calcular os resultados da pesquisa.</td><td><input type="checkbox" name="q48b" {...register("q48b", { disabled: (watch("q48c") === true), required: (((watch("q48a") === false) || (watch("q48c") === false))) })} /></td></tr>
            <tr><td>C</td><td> Nenhuma das opções.</td><td><input type="checkbox" name="q48c" {...register("q48c", { disabled: ((watch("q48a") === true) || (watch("q48b") === true)), required: (((watch("q48a") === false) || (watch("q48b") === false))) })} /></td></tr></tbody> <br></br>
          <thead><tr><th>49</th><th>{errors.q49a && errors.q49b && errors.q49c && <Alert titulo="Questão 49 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td> Organizar festas com fins beneficentes.</td><td><input type="checkbox" name="q49a" {...register("q49a", { disabled: (watch("q49c") === true), required: (((watch("q49b") === false) || (watch("q49c") === false))) })} /></td></tr>
            <tr><td>B</td><td> Vender os convites para estas festas.</td><td><input type="checkbox" name="q49b" {...register("q49b", { disabled: (watch("q49c") === true), required: (((watch("q49a") === false) || (watch("q49c") === false))) })} /></td></tr>
            <tr><td>C</td><td> Nenhuma das opções.</td><td><input type="checkbox" name="q49c" {...register("q49c", { disabled: ((watch("q49a") === true) || (watch("q49b") === true)), required: (((watch("q49a") === false) || (watch("q49b") === false))) })} /></td></tr></tbody> <br></br>
          <thead><tr><th>50</th><th>{errors.q50a && errors.q50b && errors.q50c && <Alert titulo="Questão 50 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td> Ajudar na solução de problemas dos amigos.</td><td><input type="checkbox" name="q50a" {...register("q50a", { disabled: (watch("q50c") === true), required: (((watch("q50b") === false) || (watch("q50c") === false))) })} /></td></tr>
            <tr><td>B</td><td> Aprender um novo sistema de arquivos.</td><td><input type="checkbox" name="q50b" {...register("q50b", { disabled: (watch("q50c") === true), required: (((watch("q50a") === false) || (watch("q50c") === false))) })} /></td></tr>
            <tr><td>C</td><td> Nenhuma das opções.</td><td><input type="checkbox" name="q50c" {...register("q50c", { disabled: ((watch("q50a") === true) || (watch("q50b") === true)), required: (((watch("q50a") === false) || (watch("q50b") === false))) })} /></td></tr></tbody> <br></br>
          <thead><tr><th>51</th><th>{errors.q51a && errors.q51b && errors.q51c && <Alert titulo="Questão 51 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td> Trabalhar em um Programa de Assistência Social.  </td><td><input type="checkbox" name="q51a" {...register("q51a", { disabled: (watch("q51c") === true), required: (((watch("q51b") === false) || (watch("q51c") === false))) })} /></td></tr>
            <tr><td>B</td><td> Ajudar na adaptação de Imigrantes.</td><td><input type="checkbox" name="q51b" {...register("q51b", { disabled: (watch("q51c") === true), required: (((watch("q51a") === false) || (watch("q51c") === false))) })} /></td></tr>
            <tr><td>C</td><td> Nenhuma das opções.</td><td><input type="checkbox" name="q51c" {...register("q51c", { disabled: ((watch("q51a") === true) || (watch("q51b") === true)), required: (((watch("q51a") === false) || (watch("q51b") === false))) })} /></td></tr></tbody> <br></br>
          <thead><tr><th>52</th><th>{errors.q52a && errors.q52b && errors.q52c && <Alert titulo="Questão 52 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td> Estudar os problemas de adoção de órfãos. </td><td><input type="checkbox" name="q52a" {...register("q52a", { disabled: (watch("q52c") === true), required: (((watch("q52b") === false) || (watch("q52c") === false))) })} /></td></tr>
            <tr><td>B</td><td> Estudar novas técnicas de redação.</td><td><input type="checkbox" name="q52b" {...register("q52b", { disabled: (watch("q52c") === true), required: (((watch("q52a") === false) || (watch("q52c") === false))) })} /></td></tr>
            <tr><td>C</td><td> Nenhuma das opções.</td><td><input type="checkbox" name="q52c" {...register("q52c", { disabled: ((watch("q52a") === true) || (watch("q52b") === true)), required: (((watch("q52a") === false) || (watch("q52b") === false))) })} /></td></tr></tbody> <br></br>
          <thead><tr><th>53</th><th>{errors.q53a && errors.q53b && errors.q53c && <Alert titulo="Questão 53 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td> Ler sobre como cuidar de crianças cegas.</td><td><input type="checkbox" name="q53a" {...register("q53a", { disabled: (watch("q53c") === true), required: (((watch("q53b") === false) || (watch("q53c") === false))) })} /></td></tr>
            <tr><td>B</td><td> Ler sobre artes sacras (religiosas).</td><td><input type="checkbox" name="q53b" {...register("q53b", { disabled: (watch("q53c") === true), required: (((watch("q53a") === false) || (watch("q53c") === false))) })} /></td></tr>
            <tr><td>C</td><td> Nenhuma das opções.</td><td><input type="checkbox" name="q53c" {...register("q53c", { disabled: ((watch("q53a") === true) || (watch("q53b") === true)), required: (((watch("q53a") === false) || (watch("q53b") === false))) })} /></td></tr></tbody> <br></br>
          <thead><tr><th>54</th><th>{errors.q54a && errors.q54b && errors.q54c && <Alert titulo="Questão 54 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td> Assistir uma palestra sobre os problemas sociais.</td><td><input type="checkbox" name="q54a" {...register("q54a", { disabled: (watch("q54c") === true), required: (((watch("q54b") === false) || (watch("q54c") === false))) })} /></td></tr>
            <tr><td>B</td><td> Assistir a um concerto sinfônico.  </td><td><input type="checkbox" name="q54b" {...register("q54b", { disabled: (watch("q54c") === true), required: (((watch("q54a") === false) || (watch("q54c") === false))) })} /></td></tr>
            <tr><td>C</td><td> Nenhuma das opções.</td><td><input type="checkbox" name="q54c" {...register("q54c", { disabled: ((watch("q54a") === true) || (watch("q54b") === true)), required: (((watch("q54a") === false) || (watch("q54b") === false))) })} /></td></tr></tbody> <br></br>
          <thead><tr><th>55</th><th>{errors.q55a && errors.q55b && errors.q55c && <Alert titulo="Questão 55 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td> Conhecer as obras de um escritor famoso.</td><td><input type="checkbox" name="q55a" {...register("q55a", { disabled: (watch("q55c") === true), required: (((watch("q55b") === false) || (watch("q55c") === false))) })} /></td></tr>
            <tr><td>B</td><td> Conhecer os trabalhos de um físico famoso.</td><td><input type="checkbox" name="q55b" {...register("q55b", { disabled: (watch("q55c") === true), required: (((watch("q55a") === false) || (watch("q55c") === false))) })} /></td></tr>
            <tr><td>C</td><td> Nenhuma das opções.</td><td><input type="checkbox" name="q55c" {...register("q55c", { disabled: ((watch("q55a") === true) || (watch("q55b") === true)), required: (((watch("q55a") === false) || (watch("q55b") === false))) })} /></td></tr></tbody> <br></br>
          <thead><tr><th>56</th><th>{errors.q56a && errors.q56b && errors.q56c && <Alert titulo="Questão 56 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td> Ganhar uma bolsa de estudos de Literatura.</td><td><input type="checkbox" name="q56a" {...register("q56a", { disabled: (watch("q56c") === true), required: (((watch("q56b") === false) || (watch("q56c") === false))) })} /></td></tr>
            <tr><td>B</td><td> Ganhar uma bolsa de estudos de Biologia.</td><td><input type="checkbox" name="q56b" {...register("q56b", { disabled: (watch("q56c") === true), required: (((watch("q56a") === false) || (watch("q56c") === false))) })} /></td></tr>
            <tr><td>C</td><td> Nenhuma das opções.</td><td><input type="checkbox" name="q56c" {...register("q56c", { disabled: ((watch("q56a") === true) || (watch("q56b") === true)), required: (((watch("q56a") === false) || (watch("q56b") === false))) })} /></td></tr></tbody> <br></br>
          <thead><tr><th>57</th><th>{errors.q57a && errors.q57b && errors.q57c && <Alert titulo="Questão 57 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td> Pertencer a uma Sociedade Literária.</td><td><input type="checkbox" name="q57a" {...register("q57a", { disabled: (watch("q57c") === true), required: (((watch("q57b") === false) || (watch("q57c") === false))) })} /></td></tr>
            <tr><td>B</td><td> Pertencer a um Clube de Xadrêz.</td><td><input type="checkbox" name="q57b" {...register("q57b", { disabled: (watch("q57c") === true), required: (((watch("q57a") === false) || (watch("q57c") === false))) })} /></td></tr>
            <tr><td>C</td><td> Nenhuma das opções.</td><td><input type="checkbox" name="q57c" {...register("q57c", { disabled: ((watch("q57a") === true) || (watch("q57b") === true)), required: (((watch("q57a") === false) || (watch("q57b") === false))) })} /></td></tr></tbody> <br></br>
          <thead><tr><th>58</th><th>{errors.q58a && errors.q58b && errors.q58c && <Alert titulo="Questão 58 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td> Fazer provas escritas.</td><td><input type="checkbox" name="q58a" {...register("q58a", { disabled: (watch("q58c") === true), required: (((watch("q58b") === false) || (watch("q58c") === false))) })} /></td></tr>
            <tr><td>B</td><td> Fazer provas orais.</td><td><input type="checkbox" name="q58b" {...register("q58b", { disabled: (watch("q58c") === true), required: (((watch("q58a") === false) || (watch("q58c") === false))) })} /></td></tr>
            <tr><td>C</td><td> Nenhuma das opções.</td><td><input type="checkbox" name="q58c" {...register("q58c", { disabled: ((watch("q58a") === true) || (watch("q58b") === true)), required: (((watch("q58a") === false) || (watch("q58b") === false))) })} /></td></tr></tbody> <br></br>
          <thead><tr><th>59</th><th>{errors.q59a && errors.q59b && errors.q59c && <Alert titulo="Questão 59 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td> Escrever artigos para um jornal.</td><td><input type="checkbox" name="q59a" {...register("q59a", { disabled: (watch("q59c") === true), required: (((watch("q59b") === false) || (watch("q59c") === false))) })} /></td></tr>
            <tr><td>B</td><td> Organizar os arquivos de uma empresa.</td><td><input type="checkbox" name="q59b" {...register("q59b", { disabled: (watch("q59c") === true), required: (((watch("q59a") === false) || (watch("q59c") === false))) })} /></td></tr>
            <tr><td>C</td><td> Nenhuma das opções.</td><td><input type="checkbox" name="q59c" {...register("q59c", { disabled: ((watch("q59a") === true) || (watch("q59b") === true)), required: (((watch("q59a") === false) || (watch("q59b") === false))) })} /></td></tr></tbody> <br></br>
          <thead><tr><th>60</th><th>{errors.q60a && errors.q60b && errors.q60c && <Alert titulo="Questão 60 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td> Estudar um novo idioma.</td><td><input type="checkbox" name="q60a" {...register("q60a", { disabled: (watch("q60c") === true), required: (((watch("q60b") === false) || (watch("q60c") === false))) })} /></td></tr>
            <tr><td>B</td><td> Estudar novo método para alfabetizar surdos-mudos.</td><td><input type="checkbox" name="q60b" {...register("q60b", { disabled: (watch("q60c") === true), required: (((watch("q60a") === false) || (watch("q60c") === false))) })} /></td></tr>
            <tr><td>C</td><td> Nenhuma das opções.</td><td><input type="checkbox" name="q60c" {...register("q60c", { disabled: ((watch("q60a") === true) || (watch("q60b") === true)), required: (((watch("q60a") === false) || (watch("q60b") === false))) })} /></td></tr></tbody> <br></br>
          <thead><tr><th>61</th><th>{errors.q61a && errors.q61b && errors.q61c && <Alert titulo="Questão 61 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td> Fazer um curso sobre literatura moderna.</td><td><input type="checkbox" name="q61a" {...register("q61a", { disabled: (watch("q61c") === true), required: (((watch("q61b") === false) || (watch("q61c") === false))) })} /></td></tr>
            <tr><td>B</td><td> Fazer um curso de redação.</td><td><input type="checkbox" name="q61b" {...register("q61b", { disabled: (watch("q61c") === true), required: (((watch("q61a") === false) || (watch("q61c") === false))) })} /></td></tr>
            <tr><td>C</td><td> Nenhuma das opções.</td><td><input type="checkbox" name="q61c" {...register("q61c", { disabled: ((watch("q61a") === true) || (watch("q61b") === true)), required: (((watch("q61a") === false) || (watch("q61b") === false))) })} /></td></tr></tbody> <br></br>
          <thead><tr><th>62</th><th>{errors.q62a && errors.q62b && errors.q62c && <Alert titulo="Questão 62 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td> Ser o Autor de uma peça teatral.</td><td><input type="checkbox" name="q62a" {...register("q62a", { disabled: (watch("q62c") === true), required: (((watch("q62b") === false) || (watch("q62c") === false))) })} /></td></tr>
            <tr><td>B</td><td> Atuar nesta peça teatral.</td><td><input type="checkbox" name="q62b" {...register("q62b", { disabled: (watch("q62c") === true), required: (((watch("q62a") === false) || (watch("q62c") === false))) })} /></td></tr>
            <tr><td>C</td><td> Nenhuma das opções.</td><td><input type="checkbox" name="q62c" {...register("q62c", { disabled: ((watch("q62a") === true) || (watch("q62b") === true)), required: (((watch("q62a") === false) || (watch("q62b") === false))) })} /></td></tr></tbody> <br></br>
          <thead><tr><th>63</th><th>{errors.q63a && errors.q63b && errors.q63c && <Alert titulo="Questão 63 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td> Dar aulas de português.</td><td><input type="checkbox" name="q63a" {...register("q63a", { disabled: (watch("q63c") === true), required: (((watch("q63b") === false) || (watch("q63c") === false))) })} /></td></tr>
            <tr><td>B</td><td> Dar aulas de Música.</td><td><input type="checkbox" name="q63b" {...register("q63b", { disabled: (watch("q63c") === true), required: (((watch("q63a") === false) || (watch("q63c") === false))) })} /></td></tr>
            <tr><td>C</td><td> Nenhuma das opções.</td><td><input type="checkbox" name="q63c" {...register("q63c", { disabled: ((watch("q63a") === true) || (watch("q63b") === true)), required: (((watch("q63a") === false) || (watch("q63b") === false))) })} /></td></tr></tbody> <br></br>
          <thead><tr><th>64</th><th>{errors.q64a && errors.q64b && errors.q64c && <Alert titulo="Questão 64 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td> Visitar uma galeria de arte.</td><td><input type="checkbox" name="q64a" {...register("q64a", { disabled: (watch("q64c") === true), required: (((watch("q64b") === false) || (watch("q64c") === false))) })} /></td></tr>
            <tr><td>B</td><td> Conhecer novos equipamentos de um laboratório.</td><td><input type="checkbox" name="q64b" {...register("q64b", { disabled: (watch("q64c") === true), required: (((watch("q64a") === false) || (watch("q64c") === false))) })} /></td></tr>
            <tr><td>C</td><td> Nenhuma das opções.</td><td><input type="checkbox" name="q64c" {...register("q64c", { disabled: ((watch("q64a") === true) || (watch("q64b") === true)), required: (((watch("q64a") === false) || (watch("q64b") === false))) })} /></td></tr></tbody> <br></br>
          <thead><tr><th>65</th><th>{errors.q65a && errors.q65b && errors.q65c && <Alert titulo="Questão 65 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td> Ler a página de Artes de um jornal.</td><td><input type="checkbox" name="q65a" {...register("q65a", { disabled: (watch("q65c") === true), required: (((watch("q65b") === false) || (watch("q65c") === false))) })} /></td></tr>
            <tr><td>B</td><td> Estudar a importância do leite na amamentação.</td><td><input type="checkbox" name="q65b" {...register("q65b", { disabled: (watch("q65c") === true), required: (((watch("q65a") === false) || (watch("q65c") === false))) })} /></td></tr>
            <tr><td>C</td><td> Nenhuma das opções.</td><td><input type="checkbox" name="q65c" {...register("q65c", { disabled: ((watch("q65a") === true) || (watch("q65b") === true)), required: (((watch("q65a") === false) || (watch("q65b") === false))) })} /></td></tr></tbody> <br></br>
          <thead><tr><th>66</th><th>{errors.q66a && errors.q66b && errors.q66c && <Alert titulo="Questão 66 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td> Colecionar pinturas célebres (famosas).</td><td><input type="checkbox" name="q66a" {...register("q66a", { disabled: (watch("q66c") === true), required: (((watch("q66b") === false) || (watch("q66c") === false))) })} /></td></tr>
            <tr><td>B</td><td> Estudar sobre variações cambiais (oscilações do dólar e bolsa de valores).</td><td><input type="checkbox" name="q66b" {...register("q66b", { disabled: (watch("q66c") === true), required: (((watch("q66a") === false) || (watch("q66c") === false))) })} /></td></tr>
            <tr><td>C</td><td> Nenhuma das opções.</td><td><input type="checkbox" name="q66c" {...register("q66c", { disabled: ((watch("q66a") === true) || (watch("q66b") === true)), required: (((watch("q66a") === false) || (watch("q66b") === false))) })} /></td></tr></tbody> <br></br>
          <thead><tr><th>67</th><th>{errors.q67a && errors.q67b && errors.q67c && <Alert titulo="Questão 67 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td> Desenhar um novo modelo de automóvel.</td><td><input type="checkbox" name="q67a" {...register("q67a", { disabled: (watch("q67c") === true), required: (((watch("q67b") === false) || (watch("q67c") === false))) })} /></td></tr>
            <tr><td>B</td><td> Planejar a propaganda para este automóvel.</td><td><input type="checkbox" name="q67b" {...register("q67b", { disabled: (watch("q67c") === true), required: (((watch("q67a") === false) || (watch("q67c") === false))) })} /></td></tr>
            <tr><td>C</td><td> Nenhuma das opções.</td><td><input type="checkbox" name="q67c" {...register("q67c", { disabled: ((watch("q67a") === true) || (watch("q67b") === true)), required: (((watch("q67a") === false) || (watch("q67b") === false))) })} /></td></tr></tbody> <br></br>
          <thead><tr><th>68</th><th>{errors.q68a && errors.q68b && errors.q68c && <Alert titulo="Questão 68 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td> Decorar um salão para uma festa.</td><td><input type="checkbox" name="q68a" {...register("q68a", { disabled: (watch("q68c") === true), required: (((watch("q68b") === false) || (watch("q68c") === false))) })} /></td></tr>
            <tr><td>B</td><td> Organizar os convites para esta festa.</td><td><input type="checkbox" name="q68b" {...register("q68b", { disabled: (watch("q68c") === true), required: (((watch("q68a") === false) || (watch("q68c") === false))) })} /></td></tr>
            <tr><td>C</td><td> Nenhuma das opções.</td><td><input type="checkbox" name="q68c" {...register("q68c", { disabled: ((watch("q68a") === true) || (watch("q68b") === true)), required: (((watch("q68a") === false) || (watch("q68b") === false))) })} /></td></tr></tbody> <br></br>
          <thead><tr><th>69</th><th>{errors.q69a && errors.q69b && errors.q69c && <Alert titulo="Questão 69 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td> Desenhar novos modelos de roupas e acessórios.</td><td><input type="checkbox" name="q69a" {...register("q69a", { disabled: (watch("q69c") === true), required: (((watch("q69b") === false) || (watch("q69c") === false))) })} /></td></tr>
            <tr><td>B</td><td> Orientar as atividades de um estudante.</td><td><input type="checkbox" name="q69b" {...register("q69b", { disabled: (watch("q69c") === true), required: (((watch("q69a") === false) || (watch("q69c") === false))) })} /></td></tr>
            <tr><td>C</td><td> Nenhuma das opções.</td><td><input type="checkbox" name="q69c" {...register("q69c", { disabled: ((watch("q69a") === true) || (watch("q69b") === true)), required: (((watch("q69a") === false) || (watch("q69b") === false))) })} /></td></tr></tbody> <br></br>
          <thead><tr><th>70</th><th>{errors.q70a && errors.q70b && errors.q70c && <Alert titulo="Questão 70 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td> Criar novos modelos de embalagens.</td><td><input type="checkbox" name="q70a" {...register("q70a", { disabled: (watch("q70c") === true), required: (((watch("q70b") === false) || (watch("q70c") === false))) })} /></td></tr>
            <tr><td>B</td><td> Criar os dizeres nestas embalagens.</td><td><input type="checkbox" name="q70b" {...register("q70b", { disabled: (watch("q70c") === true), required: (((watch("q70a") === false) || (watch("q70c") === false))) })} /></td></tr>
            <tr><td>C</td><td> Nenhuma das opções.</td><td><input type="checkbox" name="q70c" {...register("q70c", { disabled: ((watch("q70a") === true) || (watch("q70b") === true)), required: (((watch("q70a") === false) || (watch("q70b") === false))) })} /></td></tr></tbody> <br></br>
          <thead><tr><th>71</th><th>{errors.q71a && errors.q71b && errors.q71c && <Alert titulo="Questão 71 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td> Criar novos desenhos para tecidos.</td><td><input type="checkbox" name="q71a" {...register("q71a", { disabled: (watch("q71c") === true), required: (((watch("q71b") === false) || (watch("q71c") === false))) })} /></td></tr>
            <tr><td>B</td><td> Fazer desenhos para estórias em quadrinhos.</td><td><input type="checkbox" name="q71b" {...register("q71b", { disabled: (watch("q71c") === true), required: (((watch("q71a") === false) || (watch("q71c") === false))) })} /></td></tr>
            <tr><td>C</td><td> Nenhuma das opções.</td><td><input type="checkbox" name="q71c" {...register("q71c", { disabled: ((watch("q71a") === true) || (watch("q71b") === true)), required: (((watch("q71a") === false) || (watch("q71b") === false))) })} /></td></tr></tbody> <br></br>
          <thead><tr><th>72</th><th>{errors.q72a && errors.q72b && errors.q72c && <Alert titulo="Questão 72 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td> Assistir a uma apresentação de ballet.</td><td><input type="checkbox" name="q72a" {...register("q72a", { disabled: (watch("q72c") === true), required: (((watch("q72b") === false) || (watch("q72c") === false))) })} /></td></tr>
            <tr><td>B</td><td> Assistir a uma audição de música clássica..</td><td><input type="checkbox" name="q72b" {...register("q72b", { disabled: (watch("q72c") === true), required: (((watch("q72a") === false) || (watch("q72c") === false))) })} /></td></tr>
            <tr><td>C</td><td> Nenhuma das opções.</td><td><input type="checkbox" name="q72c" {...register("q72c", { disabled: ((watch("q72a") === true) || (watch("q72b") === true)), required: (((watch("q72a") === false) || (watch("q72b") === false))) })} /></td></tr></tbody> <br></br>
          <thead><tr><th>73</th><th>{errors.q73a && errors.q73b && errors.q73c && <Alert titulo="Questão 73 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td> Fazer parte de uma orquestra.</td><td><input type="checkbox" name="q73a" {...register("q73a", { disabled: (watch("q73c") === true), required: (((watch("q73b") === false) || (watch("q73c") === false))) })} /></td></tr>
            <tr><td>B</td><td> Participar de um grupo de estudo de astronomia.</td><td><input type="checkbox" name="q73b" {...register("q73b", { disabled: (watch("q73c") === true), required: (((watch("q73a") === false) || (watch("q73c") === false))) })} /></td></tr>
            <tr><td>C</td><td> Nenhuma das opções.</td><td><input type="checkbox" name="q73c" {...register("q73c", { disabled: ((watch("q73a") === true) || (watch("q73b") === true)), required: (((watch("q73a") === false) || (watch("q73b") === false))) })} /></td></tr></tbody> <br></br>
          <thead><tr><th>74</th><th>{errors.q74a && errors.q74b && errors.q74c && <Alert titulo="Questão 74 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td> Ler a biografia de um músico famoso.</td><td><input type="checkbox" name="q74a" {...register("q74a", { disabled: (watch("q74c") === true), required: (((watch("q74b") === false) || (watch("q74c") === false))) })} /></td></tr>
            <tr><td>B</td><td> Ler a biografia de eminente Biólogo.</td><td><input type="checkbox" name="q74b" {...register("q74b", { disabled: (watch("q74c") === true), required: (((watch("q74a") === false) || (watch("q74c") === false))) })} /></td></tr>
            <tr><td>C</td><td> Nenhuma das opções.</td><td><input type="checkbox" name="q74c" {...register("q74c", { disabled: ((watch("q74a") === true) || (watch("q74b") === true)), required: (((watch("q74a") === false) || (watch("q74b") === false))) })} /></td></tr></tbody> <br></br>
          <thead><tr><th>75</th><th>{errors.q75a && errors.q75b && errors.q75c && <Alert titulo="Questão 75 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td> Ler a página sobre música em um jornal.</td><td><input type="checkbox" name="q75a" {...register("q75a", { disabled: (watch("q75c") === true), required: (((watch("q75b") === false) || (watch("q75c") === false))) })} /></td></tr>
            <tr><td>B</td><td> Ler a página sobre economia e finanças em um jornal.</td><td><input type="checkbox" name="q75b" {...register("q75b", { disabled: (watch("q75c") === true), required: (((watch("q75a") === false) || (watch("q75c") === false))) })} /></td></tr>
            <tr><td>C</td><td> Nenhuma das opções.</td><td><input type="checkbox" name="q75c" {...register("q75c", { disabled: ((watch("q75a") === true) || (watch("q75b") === true)), required: (((watch("q75a") === false) || (watch("q75b") === false))) })} /></td></tr></tbody> <br></br>
          <thead><tr><th>76</th><th>{errors.q76a && errors.q76b && errors.q76c && <Alert titulo="Questão 76 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td> Assistir a um filme musical.</td><td><input type="checkbox" name="q76a" {...register("q76a", { disabled: (watch("q76c") === true), required: (((watch("q76b") === false) || (watch("q76c") === false))) })} /></td></tr>
            <tr><td>B</td><td> Assistir a um filme  de fundo político.</td><td><input type="checkbox" name="q76b" {...register("q76b", { disabled: (watch("q76c") === true), required: (((watch("q76a") === false) || (watch("q76c") === false))) })} /></td></tr>
            <tr><td>C</td><td> Nenhuma das opções.</td><td><input type="checkbox" name="q76c" {...register("q76c", { disabled: ((watch("q76a") === true) || (watch("q76b") === true)), required: (((watch("q76a") === false) || (watch("q76b") === false))) })} /></td></tr></tbody> <br></br>
          <thead><tr><th>77</th><th>{errors.q77a && errors.q77b && errors.q77c && <Alert titulo="Questão 77 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td> Aprender tocar um instrumento musical.</td><td><input type="checkbox" name="q77a" {...register("q77a", { disabled: (watch("q77c") === true), required: (((watch("q77b") === false) || (watch("q77c") === false))) })} /></td></tr>
            <tr><td>B</td><td> Organizar e classificar os livros numa estante.</td><td><input type="checkbox" name="q77b" {...register("q77b", { disabled: (watch("q77c") === true), required: (((watch("q77a") === false) || (watch("q77c") === false))) })} /></td></tr>
            <tr><td>C</td><td> Nenhuma das opções.</td><td><input type="checkbox" name="q77c" {...register("q77c", { disabled: ((watch("q77a") === true) || (watch("q77b") === true)), required: (((watch("q77a") === false) || (watch("q77b") === false))) })} /></td></tr></tbody> <br></br>
          <thead><tr><th>78</th><th>{errors.q78a && errors.q78b && errors.q78c && <Alert titulo="Questão 78 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td> Organizar e ser regente de uma orquestra.</td><td><input type="checkbox" name="q78a" {...register("q78a", { disabled: (watch("q78c") === true), required: (((watch("q78b") === false) || (watch("q78c") === false))) })} /></td></tr>
            <tr><td>B</td><td> Participar de um debate sobre os problemas da adolescência.</td><td><input type="checkbox" name="q78b" {...register("q78b", { disabled: (watch("q78c") === true), required: (((watch("q78a") === false) || (watch("q78c") === false))) })} /></td></tr>
            <tr><td>C</td><td> Nenhuma das opções.</td><td><input type="checkbox" name="q78c" {...register("q78c", { disabled: ((watch("q78a") === true) || (watch("q78b") === true)), required: (((watch("q78a") === false) || (watch("q78b") === false))) })} /></td></tr></tbody> <br></br>
          <thead><tr><th>79</th><th>{errors.q79a && errors.q79b && errors.q79c && <Alert titulo="Questão 79 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td> Colecionar discos e vídeos musicais.</td><td><input type="checkbox" name="q79a" {...register("q79a", { disabled: (watch("q79c") === true), required: (((watch("q79b") === false) || (watch("q79c") === false))) })} /></td></tr>
            <tr><td>B</td><td> Colecionar livros raros.</td><td><input type="checkbox" name="q79b" {...register("q79b", { disabled: (watch("q79c") === true), required: (((watch("q79a") === false) || (watch("q79c") === false))) })} /></td></tr>
            <tr><td>C</td><td> Nenhuma das opções.</td><td><input type="checkbox" name="q79c" {...register("q79c", { disabled: ((watch("q79a") === true) || (watch("q79b") === true)), required: (((watch("q79a") === false) || (watch("q79b") === false))) })} /></td></tr></tbody> <br></br>
          <thead><tr><th>80</th><th>{errors.q80a && errors.q80b && errors.q80c && <Alert titulo="Questão 80 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td> Compor o tema musical para um anúncio na TV.</td><td><input type="checkbox" name="q80a" {...register("q80a", { disabled: (watch("q80c") === true), required: (((watch("q80b") === false) || (watch("q80c") === false))) })} /></td></tr>
            <tr><td>B</td><td> Fazer os desenhos para este anúncio.</td><td><input type="checkbox" name="q80b" {...register("q80b", { disabled: (watch("q80c") === true), required: (((watch("q80a") === false) || (watch("q80c") === false))) })} /></td></tr>
            <tr><td>C</td><td> Nenhuma das opções.</td><td><input type="checkbox" name="q80c" {...register("q80c", { disabled: ((watch("q80a") === true) || (watch("q80b") === true)), required: (((watch("q80a") === false) || (watch("q80b") === false))) })} /></td></tr></tbody> <br></br>
          <thead><tr><th>81</th><th>{errors.q81a && errors.q81b && errors.q81c && <Alert titulo="Questão 81 não respondida"></Alert>}</th><th></th></tr></thead><tbody><tr><td>A</td><td> Participar de um coral.</td><td><input type="checkbox" name="q81a" {...register("q81a", { disabled: (watch("q81c") === true), required: (((watch("q81b") === false) || (watch("q81c") === false))) })} /></td></tr>
            <tr><td>B</td><td> Participar de uma orquestra sinfônica.</td><td><input type="checkbox" name="q81b" {...register("q81b", { disabled: (watch("q81c") === true), required: (((watch("q81a") === false) || (watch("q81c") === false))) })} /></td></tr>
            <tr><td>C</td><td> Nenhuma das opções.</td><td><input type="checkbox" name="q81c" {...register("q81c", { disabled: ((watch("q81a") === true) || (watch("q81b") === true)), required: (((watch("q81a") === false) || (watch("q81b") === false))) })} /></td></tr></tbody> <br></br>

        </Table>

        <button className="btn-login" type="submit">Enviar</button>

      </form>
      <Fimquest></Fimquest>
    </div>

  );
}